<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
    <app-pageheader [Parent]="this">
        <form-edit-button routerLink="edit">Edit</form-edit-button>
    </app-pageheader>

    <app-tabs>
        <app-tab Title="Configuration">
            <div style="margin:0 !important;" class="columns">
                <div class="column is-6">
                    <article class="panel">
                        <p class="panel-heading">{{Model.Name}}</p>
                        <div class="panel-block">
                            <div>
                                <app-field Label="Name" class="control flex-center">{{Model.Name}}</app-field>
                                <app-field Label="Location" class="control flex-center">{{Model.LocationString}}</app-field>
                                <app-field Label="Sign Mode" class="control flex-center">{{Model.ModeName}}</app-field>
                                <app-field Label="Display Rule Set" class="control flex-center">{{Model.DisplayRuleSetName}}</app-field>
                                <app-field Label="Sleep Time" class="control flex-center">{{Model.TotalSleepTime}}</app-field>
                                <app-field Label="Day Time" class="control flex-center">{{Model.DayTime}}</app-field>
                                <app-field Label="Target Structure" class="control flex-center"></app-field>
                                <app-field Label="Brightness" class="control flex-center">Day: {{Model.DayBrightness}}, Night: {{Model.NightBrightness}}</app-field>
                            </div>
                        </div>
                    </article>
                </div>
                <div class="column is-6">
                    <app-structureviewermaps [StructureId]="Model.ParkingLevelId" [SearchTerm]="Model.Id" StructureViewerForType="SignConfiguration" [Layers]="StructureViewerLayers" [ShowLevelSelect]="false"></app-structureviewermaps>
                </div>
            </div>
            <div style="margin:0 !important;" class="columns">
                <div class="column is-6">
                    <article class="panel">
                        <p class="panel-heading">Hardware Assignment History</p>
                        <div class="panel-block">
                            <table class="table is-striped" style="width:100% !important">
                                <tr><th>Hardware Chip Id</th><th>Assigned Dates</th></tr>
                                <tr *ngFor="let item of Model.SignAssignments">
                                    <td>{{item.ChipId}}</td>
                                    <td>{{item.DateInstalledString}} - {{item.IsActive ? 'Now' : item.DateUninstalledString}}</td>
                                </tr>
                            </table>
                        </div>
                    </article>
                </div>
            </div>
        </app-tab>
        <app-tab Title="Hardware" *ngIf="Model.Device != null">
            <div style="margin:0 !important;" class="columns">
                <div class="column is-6">
                    <article class="panel">
                        <p class="panel-heading">Sign {{HardwareModel.ChipId}}</p>
                        <div class="panel-block">
                            <div>
                                <app-field Label="Chip Id" class="control flex-center">{{HardwareModel.ChipId}}</app-field>
                                <app-field Label="Sign Type" class="control flex-center">{{HardwareModel.ClassName}}</app-field>
                                <app-field Label="Private Encryption Key" class="control flex-center">{{HardwareModel.PrivateEncryptionKey}}</app-field>
                                <app-field Label="Version" class="control flex-center">{{HardwareModel.VersionNumber}}</app-field>
                                <app-field Label="Status" class="control flex-center">{{HardwareModel.Status}}</app-field>
                                <app-field Label="Most Recent Check-In" class="control flex-center">{{HardwareModel.GlobalLastCheckIn}}</app-field>
                                <app-field Label="Local Communications" class="control flex-center">Channel: {{HardwareModel.Channel}}, Power:{{HardwareModel.TxPower}}</app-field>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </app-tab>
    </app-tabs>
    
</div>