<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded == true">
  <app-pageheader [Parent]="this">
    <app-form-save-button (OnSave)="Save()" [Parent]="this" [Form]="Form"></app-form-save-button>
  </app-pageheader>
  <!-- <app-validationsummary [Form]="Form"></app-validationsummary> -->
  <app-tabs>
    <app-tab Title="Area">
      <div class="columns">
        <div class="column">

          <app-field Label="Area Name">
            <div class="control">
              <input formControlName="Name" class="input is-short" type="text" placeholder="Name" name="Name" />
              <app-validator [For]="this.Form.get('Name')"></app-validator>
            </div>
          </app-field>

          <app-field Label="Payment Grace" [HasAddons]="true" tippy="The grace period after paying in which a vehicle will be allowed to exit without recalculating the fee">
            <div class="control">
              <input formControlName="SessionExitTimeMinutes" class="input is-short" type="number" placeholder="Select Minutes"
                name="SessionExitTimeMinutes" />
              <app-validator [For]="this.Form.get('SessionExitTimeMinutes')"></app-validator>
            </div>
            <div class="control">
              <a class="button is-default">Minutes</a>
            </div>
          </app-field>
          <app-field Label="Entry Grace" [HasAddons]="true" tippy="The grace period after entering the area in which a vehicle will be allowed to exit without calculating a fee.">
            <div class="control">
              <input formControlName="EntryGraceTimeMinutes" class="input is-short" type="number" placeholder="Select Minutes"
                name="EntryGraceTimeMinutes" />
              <app-validator [For]="this.Form.get('EntryGraceTimeMinutes')"></app-validator>
            </div>
            <div class="control">
              <a class="button is-default">Minutes</a>
            </div>
          </app-field>
          <app-field Label="Auto-Expire Sessions" [HasAddons]="true">
            <div class="control">
              <input formControlName="SessionExpiryHours" class="input is-short" type="number" placeholder="Select Hours"
                name="SessionExpiryHours" />
              <app-validator [For]="this.Form.get('SessionExpiryHours')"></app-validator>
            </div>
            <div class="control">
              <a class="button is-default">Hours</a>
            </div>
          </app-field>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Parking Lots</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control" formArrayName="ParkingLots">
                  <table class="table is-striped is-bordered is-fullwidth">
                    <thead>
                      <tr>
                        <th>Parking Lot</th>
                        <th>Spaces</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let p of FormArray(Form, 'ParkingLots').controls; let i = index;">
                        <td><a
                            [routerLink]="['/parking/parking/lots/', p.get('Id')?.value]">{{p.get('Name')?.value}}</a>
                        </td>
                        <td>{{p.get('TotalSpaces')?.value}}</td>
                        <td style="width:1em"><button type="button" (click)="RemoveParkingLot(i)"
                            class="button is-danger is-small ng-star-inserted"><i class="fa fa-trash"></i></button></td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="99">
                          <button class="button is-primary is-outlined is-fullwidth" (click)="AddParkingLot()">Add
                            Parking Lot</button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Access Control</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <table class="table is-striped is-bordered is-fullwidth">
                    <thead>
                      <tr>
                        <th>Priority</th>
                        <th>Access Type</th>
                        <th>Access List</th>
                        <th>Rates</th>
                        <!-- <th>Check Sequence</th> -->
                        <th></th>
                      </tr>
                    </thead>
                    <tbody formArrayName="AccessMethods"
                      *ngFor="let p of FormArray(Form, 'AccessMethods').controls; let i = index;">
                      <tr [formGroupName]="i">
                        <td style="vertical-align: middle;" class="is-fullheight">
                          <div class="columns ">
                            <div class="column is-8" style="position: relative">
                              <a *ngIf="i!=0" class="up-button" (click)="MoveUp(i)" title="Move Up">
                                  <i class="fa fa-chevron-up" aria-hidden="true"></i>
                              </a>
                              <a *ngIf="i!=FormArray(Form,'AccessMethods').controls.length - 1" class="down-button"  (click)="MoveDown(i)" title="Move Down"> 
                                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                              </a>
                            </div>
                            <div class="column">
                              {{i+1}}
                            </div>
                          </div>
                        </td>
                        <td>{{GetAccessMethodText(p.get('ClassName')?.value)}}</td>
                        <td>{{p.get('PlateAccessListName')?.value}}</td>
                        <td>{{p.get('RateSetName')?.value}}</td>
                        <!-- <td>{{p.get('Sequence')?.value}}</td> -->
                        <td style="width:1em;  vertical-align: middle;">
                          <div class="level ">

                            <button type="button" (click)="EditAccessMethod(p)"
                              class="button is-link is-small ng-star-inserted level-item mr-1">
                              <i class="fa fa-chevron-right"></i></button>


                            <button type="button" (click)="RemoveAccessMethod(i)"
                              class="button is-danger is-small ng-star-inserted  level-item "><i
                                class="fa fa-trash"></i></button>
                          </div>

                        </td>
                        <!-- <td style="width:1em">
                          <button type="button" (click)="RemoveAccessMethod(i)"
                            class="button is-danger is-small ng-star-inserted"><i class="fa fa-trash"></i></button></td> -->
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="99">
                          <button class="button is-primary is-outlined is-fullwidth" (click)="AddAccessMethod()">Add
                            Access Method</button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column  is-narrow">
          <div style="width: 600px; height:600px">
            <app-map-input formControlName="GeoPolygonPoints" [BackgroundPolygonControls]="AreaBackgroundPolygons"
              [BackgroundMarkerControls]="AreaBackgroundMarkers" MapWidth="100%" MapHeight="600"></app-map-input>
          </div>
        </div>
      </div>


    </app-tab>
    <app-tab Title="Entries &amp; Exits">

      <div class="columns">
        <div class="column is-6">
          <app-tree formArrayName="ParkingLots">
            <app-treenode *ngFor="let lot of FormArray(Form, 'ParkingLots').controls; let lotIndex = index;"
              [formGroupName]="lotIndex" [Text]="lot.get('Name')?.value" [Image]="GenerateIconName(lot)"
              [Expanded]="true" (OnClicked)="SetMapTarget(null, null, lot)" [Selected]="lot == HighlightTarget"
              [CanDelete]="false">
              <div formArrayName="Levels">
                <app-treenode *ngFor="let level of FormArray(lot, 'Levels').controls; let levelIndex = index;"
                  [formGroupName]="levelIndex" [Text]="level.get('Name')?.value" [Image]="GenerateIconName(level)"
                  [Expanded]="true" (OnClicked)="SetMapTarget(null, null, level)" [Selected]="level == HighlightTarget"
                  [CanDelete]="false">
                  <div formArrayName="Gates">
                    <app-treenode *ngFor="let g of FormArray(level, 'Gates').controls; let gateIndex = index;"
                      [formGroupName]="gateIndex" [Text]="g.get('Name')?.value" [Image]="GenerateIconName(g)"
                      [Expanded]="true" (OnDoubleClicked)="EditGate(g)" (OnClicked)="SetMapTarget(null, null, g)"
                      [Selected]="g == HighlightTarget" [CanDelete]="false">
                      <div formArrayName="Lanes">
                        <app-treenode *ngFor="let l of FormArray(g, 'Lanes').controls; let laneIndex = index;"
                          [formGroupName]="laneIndex"
                          [Text]="l.get('Name')?.value + ' (' + l.get('WorkflowName')?.value + ')'"
                          [Image]="GenerateIconName(l)" [Expanded]="true" (OnDoubleClicked)="EditLane(l, g)"
                          (OnClicked)="SetMapTarget(null, l, g)" [Selected]="l == HighlightTarget">
                          <app-treenode *ngIf="l.get('LocalControllerId')?.value != null"
                            [Text]="GetControllerName(l.get('LocalControllerId')?.value)"
                            [Image]="GenerateIconName(GetController(l.get('LocalControllerId')?.value), false)"
                            (OnDoubleClicked)="EditController(l.get('LocalControllerId')?.value)"
                            (OnClicked)="SetMapTargetController(l, g)"
                            [Selected]="l.get('LocalControllerId')?.value == HighlightTarget">
                            <app-treenode Text="Payment Devices" [Expanded]="true" formArrayName="PaymentTerminals">
                              <app-treenode
                                *ngFor="let p of FormArray(l, 'PaymentTerminals').controls; let paymentTerminalIndex = index;"
                                [Text]="p.get('LocalAddress')?.value" [Image]="GenerateIconName(p)" [CanExpand]="false"
                                (OnDoubleClicked)="EditPaymentTerminal(p)"
                                (OnClicked)="SetMapTargetControllerDevice(p, l, g)" [Selected]="p == HighlightTarget" [CanDelete]="true"
                                (OnDelete)="RemovePaymentTerminal(l, paymentTerminalIndex)">
                              </app-treenode>
                              <button class="button is-primary is-small" (click)="AddPaymentTerminal(l)">
                                <span class="icon">
                                  <i class="fa fa-plus"></i>
                                </span>
                                <span>Add Payment Device</span>
                              </button>
                            </app-treenode>
                            <app-treenode Text="Displays" [Expanded]="true" formArrayName="Displays">
                              <app-treenode
                                *ngFor="let d of FormArray(l, 'Displays').controls; let displayIndex = index;"
                                [Text]="d.get('LocalAddress')?.value" [Image]="GenerateIconName(d)" [CanExpand]="false"
                                (OnDoubleClicked)="EditDisplay(d)" (OnClicked)="SetMapTargetControllerDevice(d, l, g)"
                                [Selected]="d == HighlightTarget" [CanDelete]="true"
                                (OnDelete)="RemoveDisplay(l, displayIndex)">
                              </app-treenode>
                              <button class="button is-primary is-small" (click)="AddDisplay(l)">
                                <span class="icon">
                                  <i class="fa fa-plus"></i>
                                </span>
                                <span>Add Display</span>
                              </button>
                            </app-treenode>
                          </app-treenode>
                          <app-treenode Text="Cameras" [Expanded]="false" formArrayName="Cameras">
                            <app-treenode *ngFor="let c of FormArray(l, 'Cameras').controls; let cameraIndex = index;"
                              [Text]="c.get('LocalAddress')?.value" [Image]="GenerateIconName(c)" [CanExpand]="false"
                              (OnDoubleClicked)="EditCamera(c)" (OnClicked)="SetMapTarget(c, l, g)"
                              [Selected]="c == HighlightTarget" [CanDelete]="true"
                              (OnDelete)="RemoveCamera(l, cameraIndex)">
                            </app-treenode>
                            <button class="button is-primary is-small" (click)="AddCamera(g,l)">
                              <span class="icon">
                                <i class="fa fa-plus"></i>
                              </span>
                              <span>Add Camera</span>
                            </button>
                          </app-treenode>
                          <app-treenode Text="Barriers" [Expanded]="false" formArrayName="Barriers">
                            <app-treenode *ngFor="let b of FormArray(l, 'Barriers').controls; let barrierIndex = index;"
                              [Text]="b.get('LocalAddress')?.value" [Image]="GenerateIconName(b)" [CanExpand]="false"
                              (OnDoubleClicked)="EditBarrier(b)" (OnClicked)="SetMapTarget(b, l, g)"
                              [Selected]="b == HighlightTarget" [CanDelete]="true"
                              (OnDelete)="RemoveBarrier(l, barrierIndex)">
                            </app-treenode>
                            <button class="button is-primary is-small" (click)="AddBarrier(l)">
                              <span class="icon">
                                <i class="fa fa-plus"></i>
                              </span>
                              <span>Add Barrier</span>
                            </button>
                          </app-treenode>
                          <app-treenode Text="Traffic Lights" [Expanded]="false" formArrayName="TrafficLights">
                            <app-treenode *ngFor="let t of FormArray(l, 'TrafficLights').controls; let lightIndex = index;"
                            [Text]="t.get('LocalAddress')?.value" [Image]="GenerateIconName(t)" [CanExpand]="false"
                            (OnDoubleClicked)="EditTrafficLight(t)" (OnClicked)="SetMapTarget(t, l, g)"
                            [Selected]="t == HighlightTarget" [CanDelete]="true"
                            (OnDelete)="RemoveTrafficLight(l, lightIndex)">
                            </app-treenode>
                            <button class="button is-primary is-small" (click)="AddTrafficLight(l)">
                              <span class="icon">
                                <i class="fa fa-plus"></i>
                              </span>
                              <span>Add Traffic Light</span>
                            </button>
                          </app-treenode>
                          <app-treenode Text="Cell Routers" [Expanded]="false" formArrayName="CellRouters">
                            <app-treenode *ngFor="let c of FormArray(l, 'CellRouters').controls; let cellRoutersIndex = index"
                            [Text]="c.get('Name')?.value" [CanExpand]="false" [CanDelete]="true" (OnClicked)="SetMapTarget(c,l,g)" 
                            (OnDoubleClicked)="EditCellRouter(c)" [Selected]="c == HighlightTarget" (OnDelete)="RemoveCellRouter(l, cellRoutersIndex)">
                            </app-treenode>
                            <button class="button is-primary is-small" (click)="AddCellRouter(l)">
                              <span class="icon">
                                <i class="fa fa-plus"></i>
                              </span>
                              <span>Add Cell Router</span>
                            </button>
                          </app-treenode>
                        </app-treenode>
                      </div>
                    </app-treenode>
                  </div>
                </app-treenode>
              </div>
            </app-treenode>
          </app-tree>
        </div>
        <div class="column is-6">
          <app-map-input *ngIf="MapTarget != null" MapWidth="100%" MapHeight="600" [Zoom]="18" [formControl]="MapTarget"
            [Mode]="MapTargetMode" [BackgroundPolygonControls]="GateBackgroundPolygons"
            [BackgroundMarkerControls]="GateBackgroundMarkers" (BackgroundMarkerClicked)="MapMarkerClicked($event)">
          </app-map-input>
        </div>
      </div>

    </app-tab>
  </app-tabs>

</div>