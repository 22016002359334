import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { provideTippyConfig, tooltipVariation, popperVariation, TippyDirective } from '@ngneat/helipopper';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { LoginComponent } from './auth/login/login.component';
import { MainmenuComponent } from './shared/navbars/mainmenu/mainmenu.component';
import { LoginStatusComponent } from './auth/login-status/login-status.component';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AceEditorModule } from 'ng2-ace-editor';
import { NgChartsModule } from 'ng2-charts';
import { ColorPickerModule } from 'ngx-color-picker';
import { SimpleModalModule } from 'ngx-simple-modal';
import { LoginModalComponent } from './auth/login-modal/login-modal.component';
import { LoginformComponent } from './auth/loginform/loginform.component';
import { OrgswitchComponent } from './auth/orgswitch/orgswitch.component';
import { DebugmessageComponent } from './components/debugview/debugmessage/debugmessage.component';
import { DebugviewComponent } from './components/debugview/debugview.component';
import { FirmwareselectorComponent } from './components/dialogs/firmwareselector/firmwareselector.component';
import { HardwaremessagestableComponent } from './components/hardwaremessagesviewer/hardwaremessagestable/hardwaremessagestable.component';
import { HardwaremessagesviewerComponent } from './components/hardwaremessagesviewer/hardwaremessagesviewer.component';
import { LevelmapviewerComponent } from './components/levelmapviewer/levelmapviewer.component';
import { LevelviewerComponent } from './components/levelviewer/levelviewer.component';
import { LotmultiviewerComponent } from './components/lotmultiviewer/lotmultiviewer.component';
import { LotviewerComponent } from './components/lotviewer/lotviewer.component';
import { ChangePasswordComponent } from './features/myaccount/changepassword/changepassword.component';
import { MyAccountComponent } from './features/myaccount/myaccount.component';
import { EditplateaccesslistComponent } from './features/parking/accesscontrol/accesslists/edit/editplateaccesslist.component';
import { EditplateaccesslistplateComponent } from './features/parking/accesscontrol/accesslists/edit/plates/edit/editplateaccesslistplate.component';
import { PlateaccesslistsComponent } from './features/parking/accesscontrol/accesslists/plateaccesslists.component';
import { ControlledareasComponent } from './features/parking/accesscontrol/controlledareas/controlledareas.component';
import { ControlledAreaComponent } from './features/parking/accesscontrol/controlledareas/details/controlledarea.component';
import { AddcontrolledareaterminalComponent } from './features/parking/accesscontrol/controlledareas/edit/addcontrolledareaterminal/addcontrolledareaterminal.component';
import { EditcontrolledareaComponent } from './features/parking/accesscontrol/controlledareas/edit/editcontrolledarea.component';
import { EditcontrolledareaaccessmethodComponent } from './features/parking/accesscontrol/controlledareas/edit/editcontrolledareaaccessmethod/editcontrolledareaaccessmethod.component';
import { EditcontrolledareabarrierComponent } from './features/parking/accesscontrol/controlledareas/edit/editcontrolledareabarrier/editcontrolledareabarrier.component';
import { EditcontrolledareacameraComponent } from './features/parking/accesscontrol/controlledareas/edit/editcontrolledareacamera/editcontrolledareacamera.component';
import { EditcontrolledareacellrouterComponent } from './features/parking/accesscontrol/controlledareas/edit/editcontrolledareacellrouter/editcontrolledareacellrouter.component';
import { EditcontrolledareadisplayComponent } from './features/parking/accesscontrol/controlledareas/edit/editcontrolledareadisplay/editcontrolledareadisplay.component';
import { EditcontrolledareagateComponent } from './features/parking/accesscontrol/controlledareas/edit/editcontrolledareagate/editcontrolledareagate.component';
import { EditcontrolledarealaneComponent } from './features/parking/accesscontrol/controlledareas/edit/editcontrolledarealane/editcontrolledarealane.component';
import { EditcontrolledareapaymentterminalComponent } from './features/parking/accesscontrol/controlledareas/edit/editcontrolledareapaymentterminal/editcontrolledareapaymentterminal.component';
import { EditcontrolledareaterminalComponent } from './features/parking/accesscontrol/controlledareas/edit/editcontrolledareaterminal/editcontrolledareaterminal.component';
import { RevenueOverviewComponent } from './features/revenue/revenueoverview.component';
import { EditterminalconfigurationComponent } from './features/parking/accesscontrol/terminalconfigurations/edit/editterminalconfiguration.component';
import { EditterminaleventsComponent } from './features/parking/accesscontrol/terminalconfigurations/edit/events/editterminalevents.component';
import { EditterminaljsonComponent } from './features/parking/accesscontrol/terminalconfigurations/edit/json/editterminaljson.component';
import { EditterminaltransitionComponent } from './features/parking/accesscontrol/terminalconfigurations/edit/transitions/editterminaltransition.component';
import { TerminalconfigurationsComponent } from './features/parking/accesscontrol/terminalconfigurations/terminalconfigurations.component';
import { TerminalComponent } from './features/parking/accesscontrol/terminals/details/terminal.component';
import { EditterminalComponent } from './features/parking/accesscontrol/terminals/edit/editterminal.component';
import { TerminalsComponent } from './features/parking/accesscontrol/terminals/terminals.component';
//import { GatewayComponent } from './features/parking/guidance/gateways/details/gateway.component';
//import { EditGatewayComponent } from './features/parking/guidance/gateways/edit/editgateway.component';
//import { GatewaysComponent } from './features/parking/guidance/gateways/gateways.component';
import { LprsearchComponent } from './features/parking/guidance/lprsearch/lprsearch.component';
import { CarcounterlistComponent } from './features/parking/guidance/sensors/carcounters/carcounterlist.component';
import { EditcarcounterComponent } from './features/parking/guidance/sensors/carcounters/edit/editcarcounter.component';
import { EditIRSensorComponent } from './features/parking/guidance/sensors/irsensors/edit/editIRsensor.component';
import { IRSensorsListComponent } from './features/parking/guidance/sensors/irsensors/IRsensorslist.component';
import { EditlegacysignComponent } from './features/parking/guidance/signs/legacysigns/edit/editlegacysign.component';
import { LegacysignsComponent } from './features/parking/guidance/signs/legacysigns/legacysigns.component';
import { OperatorConsoleComponent } from './features/operatorconsole/operatorconsole.component';
import { CustomgroupsComponent } from './features/parking/parking/customgroups/customgroups.component';
import { CustomgroupComponent } from './features/parking/parking/customgroups/details/customgroup.component';
import { EditcustomgroupComponent } from './features/parking/parking/customgroups/edit/editcustomgroup.component';
import { EditcustomgroupstructuresComponent } from './features/parking/parking/customgroups/editcustomgroupstructures/editcustomgroupstructures.component';
import { ParkinglevelComponent } from './features/parking/parking/parkinglevels/details/parkinglevel.component';
import { EditparkinglevelDesignComponent } from './features/parking/parking/parkinglevels/edit/designer/editparkingleveldesign.component';
import { EditparkinglevelComponent } from './features/parking/parking/parkinglevels/edit/editparkinglevel.component';
import { LevelmapeditorComponent } from './features/parking/parking/parkinglevels/edit/designer/levelmapeditor/levelmapeditor.component';
import { EditParkinglevelCountsComponent } from './features/parking/parking/parkinglevels/modals/parkinglevelcounts/editparkinglevelcounts.component';
import { ParkinglevelselectorComponent } from './features/parking/parking/parkinglevels/modals/parkinglevelselector/parkinglevelselector.component';
import { ParkingLotComponent } from './features/parking/parking/parkinglots/details/parkinglot.component';
import { EditparkinglotComponent } from './features/parking/parking/parkinglots/edit/editparkinglot.component';
import { ParkinglotselectorComponent } from './features/parking/parking/parkinglots/modals/parkinglotselector/parkinglotselector.component';
import { ParkinglotsComponent } from './features/parking/parking/parkinglots/parkinglots.component';
import { EditsessionComponent } from './features/parking/parking/parkingsessions/edit/editsession.component';
import { AddsessionmanualpaymentComponent } from './features/parking/parking/parkingsessions/modals/addsessionmanualpayment/addsessionmanualpayment.component';
import { AddsessionmanualpaymentmodalComponent } from './features/parking/parking/parkingsessions/modals/addsessionmanualpayment/addsessionmanualpaymentmodal.component';
import { EditsessionratesetComponent } from './features/parking/parking/parkingsessions/modals/editsessionrateset/editsessionrateset.component';
import { EditsessionratesetmodalComponent } from './features/parking/parking/parkingsessions/modals/editsessionrateset/editsessionratesetmodal.component';
import { SessionpaymentrefundComponent } from './features/parking/parking/parkingsessions/modals/sessionpaymentrefund/sessionpaymentrefund.component';
import { SessionsComponent } from './features/parking/parking/parkingsessions/sessions.component';
import { SpacetypeComponent } from './features/parking/parking/spacetypes/details/spacetype.component';
import { EditspacetypeComponent } from './features/parking/parking/spacetypes/edit/editspacetype.component';
import { SpacetypesComponent } from './features/parking/parking/spacetypes/spacetypes.component';
import { EditthresholdsetComponent } from './features/parking/parking/thresholdsets/edit/editthresholdset.component';
import { ThresholdsetsComponent } from './features/parking/parking/thresholdsets/thresholdsets.component';
import { ParkinghomeComponent } from './features/parking/parkinghome.component';
import { ParkingPermitComponent } from './features/permits/permits/details/parkingpermit.component';
import { EditParkingPermitComponent } from './features/permits/permits/edit/editparkingpermit.component';
import { ParkingPermitsComponent } from './features/permits/permits/parkingpermits.component';
import { PermittypeComponent } from './features/permits/permittypes/details/permittype.component';
import { EditpermittypeComponent } from './features/permits/permittypes/edit/editpermittype.component';
import { PermittypesComponent } from './features/permits/permittypes/permittypes.component';
import { EditpriceruleComponent } from './features/settings/rates/pricerules/edit/editpricerule.component';
import { PricerulesComponent } from './features/settings/rates/pricerules/pricerules.component';
import { EditratesetComponent } from './features/settings/rates/ratesets/edit/editrateset.component';
import { RatesetsComponent } from './features/settings/rates/ratesets/ratesets.component';
import { ReportslistComponent } from './features/reporting/reportslist.component';
import { RunreportComponent } from './features/reporting/run/runreport.component';
import { AlerttriggersComponent } from './features/settings/alerts/triggers/alerttriggers.component';
import { EditalerttriggerComponent } from './features/settings/alerts/triggers/edit/editalerttrigger.component';
import { EditalerttriggerrecipientroleComponent } from './features/settings/alerts/triggers/modals/editalerttriggerrecipientrole/editalerttriggerrecipientrole.component';
import { EditalerttriggerrecipientuserComponent } from './features/settings/alerts/triggers/modals/editalerttriggerrecipientuser/editalerttriggerrecipientuser.component';
import { EditEmailComponent } from './features/settings/emailtemplates/edit/editemail.component';
import { EmailsComponent } from './features/settings/emailtemplates/emails.component';
import { PlatematchcharactersComponent } from './features/settings/parking/platematchcharacters/platematchcharacters.component';
import { SettingsparkinghomeComponent } from './features/settings/parking/settingsparkinghome/settingsparkinghome.component';
import { VehicledetectionsettingsComponent } from './features/settings/parking/vehicledetectionsettings/vehicledetectionsettings.component';
import { SettingshomeComponent } from './features/settings/settingshome.component';
import { UserHomeComponent } from './features/users/usershome.component';
import { RoleComponent } from './features/users/roles/details/role.component';
import { EditRoleComponent } from './features/users/roles/edit/editrole.component';
import { RolesComponent } from './features/users/roles/roles.component';
import { UserComponent } from './features/users/users/details/user.component';
import { EdituserComponent } from './features/users/users/edit/edituser.component';
import { AddUserComponent } from './features/users/users/modals/add-user/add-user.component';
import { EdituseradminpermissionComponent } from './features/users/users/modals/edituseradminpermission/edituseradminpermission.component';
import { EdituserpermissionComponent } from './features/users/users/modals/edituserpermission/edituserpermission.component';
import { EdituserroleComponent } from './features/users/users/modals/edituserrole/edituserrole.component';
import { UsermagicmoneyComponent } from './features/users/users/modals/usermagicmoney/usermagicmoney.component';
import { UsersComponent } from './features/users/users/users.component';
import { HomeComponent } from './home/home.component';
import { DevicecheckinchartComponent } from './shared/charts/devicecheckinchart/devicecheckinchart.component';
import { OccupancygaugeComponent } from './shared/charts/occupancygauge/occupancygauge.component';
import { OccupancyserieschartComponent } from './shared/charts/occupancyserieschart/occupancyserieschart.component';
import { ParkingsessionrevenuethisweekbarComponent } from './shared/charts/parkingsessionrevenuethisweekbar/parkingsessionrevenuethisweekbar.component';
import { ParkingSessionRevenueSixMonthBarComponent } from './shared/charts/parkingsessionrevenuesixmonthbar/parkingsessionrevenuesixmonthbar.component';
import { ParkingsessionrevenuetodaylineComponent } from './shared/charts/parkingsessionrevenuetodayline/parkingsessionrevenuetodayline.component';
import { DatalistviewComponent } from './shared/datalistview/datalistview.component';
import { AdminFilterComponent } from './shared/datalistview/filters/admin-filter/admin-filter.component';
import { AdminRoleFilterComponent } from './shared/datalistview/filters/admin-role-filter/admin-role-filter.component';
import { DateFilterComponent } from './shared/datalistview/filters/date-filter/date-filter.component';
import { EnabledFilterComponent } from './shared/datalistview/filters/enabled-filter/enabled-filter.component';
import { FilterSetComponent } from './shared/datalistview/filters/filter-set/filter-set.component';
import { PropertyFilterComponent } from './shared/datalistview/filters/property-filter/property-filter.component';
import { RatesFilterComponent } from './shared/datalistview/filters/rates-filter/rates-filter.component';
import { SearchButtonComponent } from './shared/datalistview/filters/search-button/search-button.component';
import { SearchFilterComponent } from './shared/datalistview/filters/search-filter/search-filter.component';
import { SubsetFilterComponent } from './shared/datalistview/filters/subset-filter/subset-filter.component';
import { ActionbuttonComponent } from './shared/forms/buttons/actionbutton/actionbutton.component';
import { AddButton } from './shared/forms/buttons/add-button/add-button.component';
import { FormDeleteButtonComponent } from './shared/forms/buttons/form-delete-button/form-delete-button.component';
import { FormSaveButtonComponent } from './shared/forms/buttons/form-save-button/form-save-button.component';
import { ModalSaveButtonComponent } from './shared/forms/buttons/modal-save-button/modal-save-button.component';
import { FieldComponent } from './shared/forms/field/field.component';
import { BusinessSelectorComponent } from './shared/forms/inputs/businessselector/businessselector.component';
import { CodeeditorComponent } from './shared/forms/inputs/codeeditor/codeeditor.component';
import { ColorpickerComponent } from './shared/forms/inputs/colorpicker/colorpicker.component';
import { CurrencyInputComponent } from './shared/forms/inputs/currency-input/currency-input.component';
import { MapInputComponent } from './shared/forms/inputs/map-input/map-input.component';
import { MedianewComponent } from './shared/forms/inputs/mediaselector/medianew/medianew.component';
import { MediaSelectorComponent } from './shared/forms/inputs/mediaselector/mediaselector.component';
import { MediaUploadComponent } from './shared/forms/inputs/mediaupload/mediaupload.component';
import { SensorSelectorComponent } from './shared/forms/inputs/sensor-selector/sensor-selector.component';
import { UserselectorComponent } from './shared/forms/inputs/userselector/userselector.component';
import { MediaViewerComponent } from './shared/forms/media/mediaviewer/mediaviewer.component';
import { DashboardboxComponent } from './shared/forms/panels/dashboardbox/dashboardbox.component';
import { TabComponent } from './shared/forms/tabs/tab/tab.component';
import { TabsComponent } from './shared/forms/tabs/tabs.component';
import { TreeComponent } from './shared/forms/tree/tree.component';
import { TreenodeComponent } from './shared/forms/tree/treenode/treenode.component';
import { TreeNodePanelBlock } from './shared/forms/tree/treenodepanelblock/treenodepanelblock.component';
import { TreenoderecursiveComponent } from './shared/forms/tree/treenoderecursive/treenoderecursive.component';
import { TreenodeselectComponent } from './shared/forms/tree/treenodeselect/treenodeselect.component';
import { TreeNodeSettingsComponent } from './shared/forms/tree/treenodesettings/treenodesettings.component';
import { UnvalidatedInputComponent } from './shared/forms/validators/unvalidated-input/unvalidated-input.component';
import { ValidatedIfInputComponent } from './shared/forms/validators/validated-if-input/validated-if-input.component';
import { ValidatedInputComponent } from './shared/forms/validators/validated-input/validated-input.component';
import { ValidationsummaryComponent } from './shared/forms/validators/validationsummary/validationsummary.component';
import { ValidatorComponent } from './shared/forms/validators/validator/validator.component';
import { PendingChangesGuard } from './shared/guards/pendingchangesguard';
import { FullpagelayoutComponent } from './shared/layout/fullpagelayout/fullpagelayout.component';
import { LeftnavlayoutComponent } from './shared/layout/leftnavlayout/leftnavlayout.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { LoadingcubeComponent } from './shared/loader/loadingcube/loadingcube.component';
import { LoadingspinnerComponent } from './shared/loader/loadingspinner/loadingspinner.component';
import { DeleteCheckComponent } from './shared/modals/delete-check/delete-check.component';
import { UnsavedchangesdialogComponent } from './shared/modals/unsavedchangesdialog/unsavedchangesdialog.component';
import { SidemenuComponent } from './shared/navbars/sidemenu/sidemenu.component';
import { PageheaderComponent } from './shared/pageheader/pageheader.component';
import { FormEditButtonComponent } from './shared/forms/buttons/form-edit-button/form-edit-button.component';
import { CalendarComponent } from './features/settings/calendar/calendar.component';
import { CalendarDayTypeComponent } from './features/settings/calendar/daytypes/calendardaytype/calendardaytype.component';
import { CalendarDayTypesComponent } from './features/settings/calendar/daytypes/calendardaytypes.component';
import { EditCalendarDayType } from './features/settings/calendar/daytypes/editcalendardaytype/editcalendardaytype.component';
import { EditclusterdesignComponent } from './features/settings/clusterdesigns/edit/editclusterdesign.component';
import { ClusterdesignerComponent } from './features/settings/clusterdesigns/edit/clusterdesigner/clusterdesigner.component';
import { HeatmapComponent } from './shared/heatmap/heatmap/heatmap.component';
import { ClusterDesignsComponent } from './features/settings/clusterdesigns/clusterdesigns.component';
import { MediaModalComponent } from './shared/modals/media-modal/media-modal.component';
import { MediaThumbnailComponent } from './shared/forms/media/media-thumbnail/media-thumbnail.component';
import { EditGatewayConfigurationComponent } from './features/parking/parking/parkinglevels/modals/editgatewayconfiguration/editgatewayconfiguration.component';
import { GatewaySelectorComponent } from './shared/forms/inputs/gateway-selector/gateway-selector.component';
import { ToggleSwitchComponent } from './shared/forms/inputs/toggleswitch/toggleswitch.component';
import { PermitshomeComponent } from './features/permits/permitshome.component';
import { ModelSelectorComponent} from './shared/modals/ModelSelector/modelselector.component';
import { StructureSelectorComponent } from './shared/modals/StructureSelector/structureselector.component';
import { DateRangeDisplayComponent } from './shared/forms/inputs/daterangedisplay/daterangedisplay.component';
import { MultiUserSelectorComponent } from './shared/forms/inputs/multiuserselector/multiuserselector.component';
import { SingleUserSelectorComponent } from './shared/forms/inputs/singleuserselector/singleuserselector.component';
import { EmailTagListComponent } from './shared/forms/inputs/emailtaglist/emailtaglist.component';
import { AddVehicleComponent } from './features/users/users/modals/add-vehicle/add-vehicle.component';
import { PermitsFilterComponent } from './shared/datalistview/filters/permits-filter/permits-filter.component';
import { PermitUsageSeriesChartComponent } from './shared/charts/permitusageserieschart/permitusageserieschart.component';
import { PercentageChartComponent } from './shared/charts/percentagechart/percentagechart.component';
import { UsersOverTimeChartComponent } from './shared/charts/usersovertimechart/usersovertimechart.component';
import { SelectorTaglistComponent } from './shared/forms/inputs/selector-taglist/selectortaglist.component';
import { PermitTypesFilterComponent } from './shared/datalistview/filters/permittypes-filter/permittypes-filter.component';
import { EditSignConfigurationComponent } from './features/parking/parking/parkinglevels/modals/editsignconfiguration/editsignconfiguration.component';
import { SignSelectorComponent } from './shared/forms/inputs/sign-selector/sign-selector.component';
import { HardwarehomeComponent } from './features/hardware/hardwarehome/hardwarehome.component';
import { SignsComponent } from './features/hardware/devices/signs/signs.component';
import { GatewaysComponent } from './features/hardware/devices/gateways/gateways.component';
import { SignComponent } from './features/hardware/devices/signs/details/sign.component';
import { EditsignComponent } from './features/hardware/devices/signs/edit/editsign.component';
import { SigndisplayrulesetsComponent } from './features/hardware/configuration/signdisplayrulesets/signdisplayrulesets.component';
import { SigndisplayrulesetComponent } from './features/hardware/configuration/signdisplayrulesets/details/signdisplayruleset.component';
import { EditsigndisplayrulesetComponent } from './features/hardware/configuration/signdisplayrulesets/edit/editsigndisplayruleset.component';
import { EditlegacysigndisplayruleComponent } from './features/hardware/configuration/signdisplayrulesets/editlegacysigndisplayrule/editlegacysigndisplayrule.component';
import { ModalBaseComponent } from './shared/modals/modal-base/modal-base.component';
import { StructureViewerMapsComponent } from './shared/structureviewermaps/structureviewermaps.component';
import { SignsService } from './Services/signsservice';
import { EditcontrolledareatrafficlightComponent } from './features/parking/accesscontrol/controlledareas/edit/editcontrolledareatrafficlight/editcontrolledareatrafficlight.component';
import { DiscountTypesComponent } from './features/parking/pricemodifiers/discountcodes/discounttypes/discounttypes.component';
import { DiscountTypeComponent } from './features/parking/pricemodifiers/discountcodes/discounttypes/details/discounttype.component';
import { EditDiscountTypeComponent } from './features/parking/pricemodifiers/discountcodes/discounttypes/edit/editdiscounttype.component';
import { EditDiscountCodeComponent } from './features/parking/pricemodifiers/discountcodes/edit/editdiscountcode.component';
import { DiscountFilterComponent } from './shared/datalistview/filters/discount-filter/discount-filter.component';
import { DiscountTypeUsageSeriesChartComponent } from './shared/charts/discounttypeusageserieschart/discounttypeusageserieschart.component';
import { ValidationprofilesComponent } from './features/parking/pricemodifiers/validationprofiles/validationprofiles.component';
import { EditvalidationprofileComponent } from './features/parking/pricemodifiers/validationprofiles/edit/editvalidationprofile.component';
import { ValidationprofileComponent } from './features/parking/pricemodifiers/validationprofiles/details/validationprofile.component';
import { EditValidationUserComponent } from './features/parking/pricemodifiers/validationprofiles/validationprofileusers/edit/editvalidationuser.component';
import { ValidationProfileUserComponent } from './features/parking/pricemodifiers/validationprofiles/validationprofileusers/details/validationprofileuser.component';
import { ValidationProfileUsageSeriesChart } from './shared/charts/validationprofileusageserieschart/validationprofileusageserieschart.component';
import { ValidationUserUsageSeriesChart } from './shared/charts/validationuserusageserieschart/validationuserusageserieschart.component';
import { EditValidationCodeComponent } from './features/parking/pricemodifiers/validationprofiles/validationprofileusers/details/editcodes/editvalidationcode.component';
import { MultiSelectDropdownComponent } from './shared/forms/inputs/multiselect-dropdown/multiselect-dropdown.component';
import { ValetHomeComponent } from './features/valet/valethome.component';
import { ValetSessionsComponent } from './features/valet/valetsessions/valetsessions.component';
import { ValetTypesFilterComponent } from './shared/datalistview/filters/valettypes-filter/valettypes-filter.component';
import { ValetTypesComponent } from './features/valet/types/valettypes.component';
import { ValetTypeComponent } from './features/valet/types/details/valettype.component';
import { EditValetTypeComponent } from './features/valet/types/edit/editvalettype.component';
import { ValetSessionComponent } from './features/valet/valetsessions/details/valetsession.component';
import { EditValetSessionComponent } from './features/valet/valetsessions/edit/editvaletsession.component';
import { FaIconSelector } from './shared/forms/inputs/fa-iconselector/fa-iconselector.component';
import { ValetTypeUsageSeriesChart } from './shared/charts/valettypeusageserieschart/valettypeusageserieschart.component';
import { ValetSessionUsageSeriesChart } from './shared/charts/valetsessionusageserieschart/valetsessionusageserieschart.component';
import { AddValetSessionPaymentModalComponent } from './features/valet/valetsessions/details/addpaymentmodal/addvaletsessionpaymentmodal.component';
import { RefundValetSessionModalComponent } from './features/valet/valetsessions/details/recordrefundmodal/refundvaletsessionpaymentmodal.component';
import { ValetActionButtonsComponent } from './shared/forms/buttons/valetactionbuttons/valetactionbuttons.component';
import { ValetSessionParkComponent } from './shared/modals/valetsessionpark/valetsessionpark.component';
import { ImageCarouselComponent } from './shared/image-carousel/image-carousel.component';
import { ActionLogComponent } from './shared/modals/actionlog/actionlog.component';
import { ActionEventInputHandlerComponent } from './shared/modals/actioneventinputhandler/actioneventinputhandler.component';
import { SearchSelectorComponent } from './shared/forms/inputs/search-selector/search-selector.component';
import { ValetAddonsComponent } from './features/valet/addons/valetaddons.component';
import { ValetAddonComponent } from './features/valet/addons/details/valetaddon.component';
import { EditValetAddonComponent } from './features/valet/addons/edit/editvaletaddon.component';
import { ValetAddonsModalComponent } from './shared/modals/valetaddons/valetaddonsmodal.component';
import { GatewayComponent } from './features/hardware/devices/gateways/details/gateway.component';
import { EditGatewayComponent } from './features/hardware/devices/gateways/edit/editgateway.component';
import { GuidanceLightsComponent } from './features/hardware/devices/guidancelights/guidancelights.component';
import { EditGuidanceLightComponent } from './features/hardware/devices/guidancelights/edit/editguidancelight.component';
import { GuidanceLightComponent } from './features/hardware/devices/guidancelights/details/guidancelight.component';
import { SpaceSelectorComponent } from './shared/forms/inputs/space-selector/space-selector.component';
import { CarcountersComponent } from './features/hardware/devices/carcounters/carcounters.component';
import { CarCounterComponent } from './features/hardware/devices/carcounters/details/carcounter.component';
import { EditCarCounterComponent } from './features/hardware/devices/carcounters/edit/editcarcounter.component';
import { CarCounterSelectorComponent } from './shared/forms/inputs/carcounter-selector/carcounter-selector.component';
import { GuidanceLightSelectorComponent } from './shared/forms/inputs/guidancelight-selector/guidancelight-selector.component';
import { TransactionsComponent } from './features/revenue/transactions/transactions.component';
import { TransactionComponent } from './features/revenue/transactions/details/transaction.component';
import { UploadStatementModal } from './features/revenue/reconciliation/statements/uploadstatementmodal/uploadstatementmodal.component';
import { ReconciliationToolComponent } from './features/revenue/reconciliation/reconciliationtool/reconciliationtool.component';
import { ReconciliationDataModalComponent } from './features/revenue/reconciliation/reconciliationtool/datamodal/datamodal.component';
import { DuplicatePaymentRecordsComponent } from './features/revenue/reconciliation/duplicatepaymentrecords/duplicatepaymentrecords.component';
import { StatementsComponent } from './features/revenue/reconciliation/statements/statements.component';
import { StatementComponent } from './features/revenue/reconciliation/statements/details/statement.component';
import { LevelEditorComponent } from './features/parking/parking/parkinglevels/leveleditor/leveleditor.component';
import { SpaceBetweenCapitalizedPipe } from './features/parking/parking/parkinglevels/leveleditor/fabric.js/SpaceBetweenCapitalizedPipe';
import { EditCarCounterConfiguration } from './features/parking/parking/parkinglevels/modals/editcarcounterconfiguration/editcarcounterconfiguration.component';
import { FrogIssuesComponent } from './features/revenue/reconciliation/frogissues/frogissues.component';
import { DocumentTemplatesComponent } from './features/settings/documenttemplates/documenttemplates.component';
import { EditDocumentTemplate } from './features/settings/documenttemplates/edit/editdocumenttemplate.component';
import { DocumentEditorComponent } from './features/settings/documenttemplates/edit/documenteditor/documenteditor.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainmenuComponent,
    LoginStatusComponent,
    ParkinglotsComponent,
    ParkinghomeComponent,
    HomeComponent,
    SidemenuComponent,
    DatalistviewComponent,
    EditparkinglotComponent,
    TabsComponent,
    TabComponent,
    SessionsComponent,
    ControlledareasComponent,
    TerminalsComponent,
    TerminalconfigurationsComponent,
    RatesetsComponent,
    PricerulesComponent,
    EditratesetComponent,
    PageheaderComponent,
    EditcontrolledareaComponent,
    TreeComponent,
    TreenodeComponent,
    EditcontrolledareagateComponent,
    EditcontrolledarealaneComponent,
    EditcontrolledareacameraComponent,
    EditcontrolledareabarrierComponent,
    EditcontrolledareadisplayComponent,
    EditcontrolledareapaymentterminalComponent,
    ActionbuttonComponent,
    EditcontrolledareapaymentterminalComponent,
    EditterminalComponent,
    EditcontrolledareaterminalComponent,
    AddcontrolledareaterminalComponent,
    EditterminalconfigurationComponent,
    CodeeditorComponent,
    EditterminaleventsComponent,
    EditterminaltransitionComponent,
    LoaderComponent,
    UnsavedchangesdialogComponent,
    OrgswitchComponent,
    ParkinglotselectorComponent,
    LoadingspinnerComponent,
    EditcontrolledareaaccessmethodComponent,
    ValidatedInputComponent,
    LoginModalComponent,
    LoginformComponent,
    UnvalidatedInputComponent,
    ValidatedIfInputComponent,
    EditpriceruleComponent,
    ValidatorComponent,
    FieldComponent,
    CurrencyInputComponent,
    FormSaveButtonComponent,
    FormDeleteButtonComponent,
    PlateaccesslistsComponent,
    EditplateaccesslistComponent,
    EditplateaccesslistplateComponent,
    UserselectorComponent,
    MapInputComponent,
    ValidationsummaryComponent,
    LprsearchComponent,
    ReportslistComponent,
    RunreportComponent,
    UsersComponent,
    UserHomeComponent,
    EdituserComponent,
    EdituserroleComponent,
    EdituseradminpermissionComponent,
    EdituserpermissionComponent,
    LoadingcubeComponent,
    CarcounterlistComponent,
    EditcarcounterComponent,
    FirmwareselectorComponent,
    LegacysignsComponent,
    EditlegacysignComponent,
    HardwaremessagesviewerComponent,
    HardwaremessagestableComponent,
    EditsessionComponent,
    EditparkinglevelComponent,
    EditparkinglevelDesignComponent,
    LevelmapeditorComponent,
    ColorpickerComponent,
    ParkinglevelselectorComponent,
    AlerttriggersComponent,
    EditalerttriggerComponent,
    EditalerttriggerrecipientuserComponent,
    EditalerttriggerrecipientroleComponent,
    LevelmapviewerComponent,
    SpacetypesComponent,
    EditspacetypeComponent,
    MediaViewerComponent,
    ThresholdsetsComponent,
    EditthresholdsetComponent,
    PlatematchcharactersComponent,
    SettingsparkinghomeComponent,
    DebugmessageComponent,
    DebugviewComponent,
    LotviewerComponent,
    LotmultiviewerComponent,
    OccupancygaugeComponent,
    DashboardboxComponent,
    ParkingsessionrevenuetodaylineComponent,
    RevenueOverviewComponent,
    ParkingsessionrevenuethisweekbarComponent,
    ParkingSessionRevenueSixMonthBarComponent,
    MediaSelectorComponent,
    MedianewComponent,
    TerminalComponent,
    BusinessSelectorComponent,
    SettingshomeComponent,
    DevicecheckinchartComponent,
    OccupancyserieschartComponent,
    UsermagicmoneyComponent,
    RolesComponent,
    UserComponent,
    SessionpaymentrefundComponent,
    EditsessionratesetmodalComponent,
    EditsessionratesetComponent,
    AddsessionmanualpaymentComponent,
    AddsessionmanualpaymentmodalComponent,
    TreenoderecursiveComponent,
    TreenodeselectComponent,
    EditterminaljsonComponent,
    ParkingLotComponent,
    ControlledAreaComponent,
    DeleteCheckComponent,
    RoleComponent,
    EditRoleComponent,
    EditParkinglevelCountsComponent,
    SpacetypeComponent,
    MediaUploadComponent,
    ParkinglevelComponent,
    LevelviewerComponent,
    AddUserComponent,
    EditcontrolledareacellrouterComponent,
    DateFilterComponent,
    RatesFilterComponent,
    SearchFilterComponent,
    FilterSetComponent,
    SubsetFilterComponent,
    AdminFilterComponent,
    AdminRoleFilterComponent,
    SearchButtonComponent,
    IRSensorsListComponent,
    EditIRSensorComponent,
    SensorSelectorComponent,
    GatewaySelectorComponent,
    OperatorConsoleComponent,
    ModalSaveButtonComponent,
    OperatorConsoleComponent,
    PermittypesComponent,
    PermittypeComponent,
    EditpermittypeComponent,
    ParkingPermitsComponent,
    ParkingPermitComponent,
    EditParkingPermitComponent,
    CustomgroupsComponent,
    CustomgroupComponent,
    EditcustomgroupComponent,
    EditcustomgroupstructuresComponent,
    AddButton,
    PropertyFilterComponent,
    EmailsComponent,
    EditEmailComponent,
    EnabledFilterComponent,
    TreeNodeSettingsComponent,
    TreeNodePanelBlock,
    MyAccountComponent,
    ChangePasswordComponent,
    CalendarDayTypesComponent,
    EditCalendarDayType,
    CalendarDayTypeComponent,
    CalendarComponent,
    //GatewaysComponent,
    //EditGatewayComponent,
    //GatewayComponent,
    VehicledetectionsettingsComponent,
    LeftnavlayoutComponent,
    FullpagelayoutComponent,
    FormEditButtonComponent,
    EditclusterdesignComponent,
    ClusterdesignerComponent,
    HeatmapComponent,
    ClusterDesignsComponent,
    MediaModalComponent,
    MediaThumbnailComponent,
    EditGatewayConfigurationComponent,
    ToggleSwitchComponent,
    PermitshomeComponent,
    ModelSelectorComponent,
    StructureSelectorComponent,
    DateRangeDisplayComponent,
    MultiUserSelectorComponent,
    SingleUserSelectorComponent,
    EmailTagListComponent,
    AddVehicleComponent,
    PermitsFilterComponent,
    PermitUsageSeriesChartComponent,
    PercentageChartComponent,
    UsersOverTimeChartComponent,
    SelectorTaglistComponent,
    PermitTypesFilterComponent,
    ToggleSwitchComponent,
    EditSignConfigurationComponent,
    SignSelectorComponent,
    HardwarehomeComponent,
    SignsComponent,
    GatewaysComponent,
    SignComponent,
    EditsignComponent,
    SigndisplayrulesetsComponent,
    SigndisplayrulesetComponent,
    EditsigndisplayrulesetComponent,
    EditlegacysigndisplayruleComponent,
    ModalBaseComponent,
    PermitTypesFilterComponent,
    StructureViewerMapsComponent,
    EditcontrolledareatrafficlightComponent,
    DiscountTypesComponent,
    DiscountTypeComponent,
    EditDiscountTypeComponent,
    EditDiscountCodeComponent,
    DiscountFilterComponent,
    DiscountTypeUsageSeriesChartComponent,
    ValidationprofilesComponent,
    EditvalidationprofileComponent,
    ValidationprofileComponent,
    EditValidationUserComponent,
    ValidationProfileUserComponent,
    ValidationProfileUsageSeriesChart,
    ValidationUserUsageSeriesChart,
    EditValidationCodeComponent,
    MultiSelectDropdownComponent,
    EditValidationCodeComponent,
    ValetHomeComponent,
    ValetSessionsComponent,
    ValetTypesFilterComponent,
    ValetTypesComponent,
    ValetTypeComponent,
    EditValetTypeComponent,
    ValetSessionComponent,
    EditValetSessionComponent,
    FaIconSelector,
    ValetTypeUsageSeriesChart,
    ValetSessionUsageSeriesChart,
    AddValetSessionPaymentModalComponent,
    RefundValetSessionModalComponent,
    ValetActionButtonsComponent,
    ValetSessionParkComponent,
    ImageCarouselComponent,
    ActionLogComponent,
    SearchSelectorComponent,
    ValetAddonsComponent,
    ValetAddonComponent,
    EditValetAddonComponent,
    ValetAddonsModalComponent,
    ActionEventInputHandlerComponent,
    GatewayComponent,
    EditGatewayComponent,
    GuidanceLightsComponent,
    EditGuidanceLightComponent,
    GuidanceLightComponent,
    SpaceSelectorComponent,
    CarcountersComponent,
    CarCounterComponent,
    EditCarCounterComponent,
    CarCounterSelectorComponent,
    GuidanceLightSelectorComponent,
    ActionEventInputHandlerComponent,
    TransactionsComponent,
    TransactionComponent,
    StatementsComponent,
    ReconciliationToolComponent,
    UploadStatementModal,
    StatementComponent,
    ReconciliationDataModalComponent,
    DuplicatePaymentRecordsComponent,
    FrogIssuesComponent,
    ActionEventInputHandlerComponent,
    GatewayComponent,
    EditGatewayComponent,
    GuidanceLightsComponent,
    EditGuidanceLightComponent,
    GuidanceLightComponent,
    SpaceSelectorComponent,
    CarcountersComponent,
    CarCounterComponent,
    EditCarCounterComponent,
    CarCounterSelectorComponent,
    GuidanceLightSelectorComponent,
    LevelEditorComponent,
    SpaceBetweenCapitalizedPipe,
    EditCarCounterConfiguration,
    DocumentTemplatesComponent,
    EditDocumentTemplate,
    DocumentEditorComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    AceEditorModule,
    ColorPickerModule,
    AutocompleteLibModule,
    NgxSliderModule,
    NgChartsModule,
    SimpleModalModule.forRoot({ container: 'modal-container' }, {
      bodyClass: '',
      draggableClass: '',
      closeOnEscape: true,
      closeOnClickOutside: false,
      wrapperDefaultClasses: 'dialog fade-anim',
      wrapperClass: 'fade-anim in',
      animationDuration: 300,
      autoFocus: true,
      draggable: false
    }
    ),
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    TippyDirective
  ],
  providers: [
    PendingChangesGuard,
    provideTippyConfig({
      defaultVariation: 'tooltip',
      theme: 'translucent',
      delay: 1000,
      variations: {
        tooltip: {
          arrow: true,
          animation: 'scale',
          trigger: 'mouseenter'
        },
        popper: popperVariation,
      }
    }),
    environment.apiTarget
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

