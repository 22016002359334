import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceBase } from './api.service.base';
import { ServiceBusClient} from '@azure/service-bus'
import { OrganizationsService } from './organizations.service';
import { PermissionsService } from './permissions.service';
import { LoginServiceBase } from '../auth/login.service.base';

@Injectable({
  providedIn: 'root'
})
export class OperatorConsoleService {

  public OccupancyChanged = new EventEmitter<any>();
  public OperatorConsoleServiceUpdated = new EventEmitter<any>();

  public CurrentConnectionString : string = "";
  private Client? : ServiceBusClient;

  constructor(private apiService : ApiServiceBase, private organizationsService : OrganizationsService, private loginService : LoginServiceBase, private permissionService : PermissionsService) { 
    organizationsService.AvailableOrganizationsChanged.subscribe(result =>{
      this.ConnectServiceBus(); //connect to the bus if we aren't already connected
    });
    loginService.OrganizationChanged.subscribe(result => {
      this.ConnectServiceBus(); //disconnect from previous bus and connect to new org
    });
    this.ConnectServiceBus();
  }

  private UpdateDevice(itemId : any, messagetype: string, enqueuedTimeUtc: any, body: any){
    console.log("local controller feed received update");
    if (itemId == null || itemId == undefined){
      itemId = body;
    }
    
    this.OperatorConsoleServiceUpdated.emit({ItemId: itemId, MessageType: messagetype, EnqueuedTimeUtc: enqueuedTimeUtc});
  }

  private ConnectServiceBus(){
    this.organizationsService.GetServiceBusListenerConnectionString().then((x: any) => {
      if(this.CurrentConnectionString == x){
        //same connection as before
        return;
      }
      if(x == null || x == ''){
        return; //can't connect with no connecton string
      }
      if(this.Client != null){
        //if we were connected before then close
        this.Client.close();
      }
  
     this.apiService.Post<any>("infrastructure/servicebus/topics/OperatorConsole/" + this.loginService.UserId() + this.permissionService.GetSessionIdentifier(), {}).then(result => {
      this.Client = new ServiceBusClient(x);
      const receiver = this.Client.createReceiver("OperatorConsole", this.loginService.UserId() + this.permissionService.GetSessionIdentifier());
      const SBMessageHandler = async (messageReceived:any) => {
        for (let key in messageReceived.applicationProperties) {
          messageReceived.applicationProperties[key.toLowerCase()] = messageReceived.applicationProperties[key];
      }
        this.UpdateDevice((messageReceived.applicationProperties["deviceid"] || null), messageReceived.applicationProperties["messagetype"] , messageReceived.enqueuedTimeUtc, new TextDecoder().decode(messageReceived.body));
      };
      const SBErrorHandler = async (error:any) => {
        console.log(error);
      };
      receiver.subscribe({
        processMessage: SBMessageHandler,
        processError: SBErrorHandler
      });
     });
    });
  }
}
