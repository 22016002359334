<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded == true">
    <app-pageheader [Parent]="this">
    </app-pageheader>
    <!-- <app-validationsummary [Form]="Form"></app-validationsummary> -->
    <div class="columns">
        <div class="column is-half">
            <article class="panel" *ngIf="this.Model != null">
                <p class="panel-heading">Session <span class="has-bold-text">#{{this.Model.Id}}</span> ({{Model.Status}})</p>
                <div class="panel-block">
                    <span class="label">Plate Number</span>
                    <span class="value">{{Model.PlateNumbers}}</span>
                </div>
                <div class="panel-block" *ngIf="Model.EntryPlateMediaId != null || Model.ExitPlateMediaId != null">
                    <span class="label">Snapshots</span>
                    <span class="value">
                        <app-media-thumbnail *ngIf="this.Model.EntryPlateMediaId != null" [MediaId]="this.Model.EntryPlateMediaId" Title="Entry Plate Read"  ModalImageWidth="480px" ModalImageHeight="300px" ThumbnailHeight="36px" ThumbnailWidth="48px"></app-media-thumbnail>&nbsp;&nbsp;
                        <app-media-thumbnail *ngIf="this.Model.ExitPlateMediaId != null" [MediaId]="this.Model.ExitPlateMediaId" Title="Exit Plate Read" ModalImageWidth="480px" ModalImageHeight="300px" ThumbnailHeight="36px" ThumbnailWidth="48px"></app-media-thumbnail>
                    </span>
                </div>
                <div class="panel-block" *ngIf="Model.ControlledAreaId != null">
                    <span class="label">Area</span>
                    <span class="value">{{Model.ControlledAreaName}}</span>
                </div>
                <a class="panel-block" *ngIf="this.Model.OrganizationUser != null">
                    <span class="label">User</span>
                    <span class="value"><a
                            routerLink="/usermanagement/users/{{Model.OrganizationUserId}}">{{Model.OrganizationUser.FirstName}}
                            {{Model.OrganizationUser.LastName}} ({{Model.OrganizationUser.Email}})</a></span>
                </a>
                <div class="panel-block" *ngIf="Model.ControlledAreaId != null">
                    <span class="label">Entry</span>
                    <span class="value" *ngIf="Model.ControlledAreaEntryDateLocalString != ''">{{Model.EntryLaneName}}, {{Model.ControlledAreaEntryDateLocalString}}</span>
                </div>
                <div class="panel-block" *ngIf="Model.ControlledAreaId != null">
                    <span class="label">Exit</span>
                    <span class="value" *ngIf="Model.ControlledAreaExitDateLocalString != ''">{{Model.ExitLaneName}}, {{Model.ControlledAreaExitDateLocalString}}</span>
                </div>
                <div class="panel-block" *ngIf="Model.ParkingSpaceId != null">
                    <span class="label">Parking Space</span>
                    <span class="value">{{Model.ParkingSpaceName}}</span>
                </div>
                <div class="panel-block" *ngIf="Model.ParkingSpaceId != null">
                    <span class="label">Parked At</span>
                    <span class="value">{{Model.ParkingSpaceEntryDateLocalString}}</span>
                </div>
                <div class="panel-block" *ngIf="Model.ParkingSpaceId != null">
                    <span class="label">Departed at</span>
                    <span class="value">{{Model.ParkingSpaceExitDateLocalString ?? "unknown"}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Duration</span>
                    <span class="value">{{Model.DurationAsString}}</span>
                </div>
                <div class="panel-block">
                    <span class="label">Rate Set</span>
                    <span class="value">
                        {{Model.ParkingRateSetName}}
                        <a class="fa fa-pencil is-primary ml-3" (click)="ChangeRateSet()" tippy="Change the Rate Set for this session"></a>
                    </span>
                </div>
                <div class="panel-block" *ngIf="Model.DiscountTypeId != null">
                    <span class="label">Discount Applied</span>
                    <span class="value">
                        {{Model.DiscountTypeName}}
                        <span *ngIf="Model.ModifierCodeId != null">(Code {{Model.DiscountCode}})</span>
                    </span>
                </div>
                <div class="panel-block">
                    <span class="label">Fee</span>
                    <span class="value">
                        {{this.Model.QuotedAmount | currency:'USD':true:'1.2-2'}}
                        <span *ngIf="Model.ValidationUserId != null">
                            (validated by {{Model.ValidationUser?.Name}})
                        </span>
                    </span>
                </div>
                
                <a class="panel-block" *ngIf="this.Model.ParkingPermitInstance?.ParkingPermit != null">
                    <span class="label">Parking Permit</span>
                    <span class="value"><a routerLink="/permits/parkingpermits/{{Model.ParkingPermitInstance.ParkingPermit.Id}}">{{Model.ParkingPermitInstance.ParkingPermit.ParkingPermitType.Name}} ({{Model.ParkingPermitInstance.ParkingPermit.UserCount}} users, {{Model.ParkingPermitInstance.ParkingPermit.TimesUsed}} uses)</a></span>
                </a>
                <div class="panel-block">
                    <span class="label">Paid</span>
                    <span class="value">
                        {{this.Model.TotalPaid | currency:'USD':true:'1.2-2'}}
                        <a class="fas fa-plus is-primary ml-3" (click)="MakeManualPayment()" tippy="Record a payment against this session"></a>
                    </span>
                </div>
            </article>
        </div>
        <div class="column is-half">
            <app-structureviewermaps [Layers]="StructureViewerLayers" [SearchTerm]="StructureSearchTerm" 
            [StructureId]="StructureId" [StructureViewerForType]="StructureViewerForType"></app-structureviewermaps>
            
        </div>
    </div> 
            <div class="columns">
                <div class="column is-12">
                    <article class="panel">
                        <p class="panel-heading">
                            Session Payments
                        </p>
                        <a class="panel-block">
                            <table class="table is-striped">
                                <thead>
                                    <tr>
                                        <th *ngFor="let h of Columns">{{h.Header}}</th>
                                        <th class="buttoncolumn"></th>
                                        <th class="buttoncolumn"></th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="this.Model.Payments != null && this.Model.Payments.length > 0">
                                    <tr *ngFor="let p of this.Model.Payments">
                                        <td>{{p.AccountTransactionId}}</td>
                                        <td>{{p.Amount}}</td>
                                        <td>{{p.PaidFromLocalString}}</td>
                                        <td>{{p.PaidToLocalString}}</td>
                                        <td>{{p.PaymentType}}</td>
                                        <td>{{p.TransactionId}}</td>
                                        <td>{{p.TransactionReference}}</td>
                                        <td *ngIf="!p.TransactionReference?.includes('Refund') && p.Amount > 0">
                                            <button class="button is-primary is-small" (click)="RecordRefund(p)">
                                                <span>Record Refund</span>
                                            </button>
                                        </td>
                                        <td *ngIf="!p.TransactionReference?.includes('Refund')">
                                            <button class="button is-primary is-small" (click)="Receipt(p)">
                                                <span>Receipt</span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr *ngIf="this.Model.Payments == null || this.Model.Payments.length == 0">
                                        <td><i>No Payments</i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </a>
                    </article>
                </div>
            </div>
        </div>