import { Component, OnInit } from '@angular/core';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { DataListFilterColumn, DatalastviewTextAndConditionalSuffixColumn, Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {
  public ShowPermitFilter = false;
  public ShowRatesFilter = false;
  constructor(private permissionsService : PermissionsService) { }

  public Columns : Array<Datalistviewcolumn> = [
    new DataListFilterColumn("Status", "Status", true, "Status"),
    new Datalistviewcolumn("Plate", "PlateNumbers"),
    new Datalistviewcolumn("Entry Lane", "EntryLaneName"),
    new DataListFilterColumn("Start Date", "StartDateLocalString", true, "StartDate"),
    new Datalistviewcolumn("Exit Gate", "ExitLaneName"),
    new DataListFilterColumn("End Date", "EndDateLocalString", true, "EndDate"),
    new Datalistviewcolumn("Duration", "DurationAsString"),
    new DatalastviewTextAndConditionalSuffixColumn("Rate", "ParkingRateSetName", "<i class=\"fa-regular fa-circle-check\" title='Validated'></i>", "Validated", true),
    new DataListFilterColumn("Paid", "TotalPaid", true, "Paid"),
    new Datalistviewcolumn("Controlled Area", "ControlledAreaName"),
  ];

  async ngOnInit(): Promise<void> {
    this.ShowPermitFilter = await this.permissionsService.CheckAdminPermission("PermitsFeature", 100);
    this.ShowRatesFilter = await this.permissionsService.CheckAdminPermission("RatesFeature", 100);
  }

}
