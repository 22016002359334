import { Component, OnInit } from '@angular/core';
import { DataListFilterColumn, Datalistviewcolumn } from 'src/app/shared/datalistview/columns/datalistviewcolumn';
import { StructureViewerLayer } from 'src/app/shared/structureviewermaps/structureviewerlayers';

@Component({
  selector: 'app-parkinglots',
  templateUrl: './parkinglots.component.html',
  styleUrls: ['./parkinglots.component.scss']
})
export class ParkinglotsComponent implements OnInit {

  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new DataListFilterColumn("Name", "Name", true, "Name"),
    new DataListFilterColumn("Spaces", "TotalSpaces", true, "TotalSpaces"),
    new DataListFilterColumn("Occupied", "OccupiedSpaces", true, "OccupiedSpaces"),
    new Datalistviewcolumn("Type", "TypeDescription")
  ];

  public StructureViewerLayers: StructureViewerLayer[] = [new StructureViewerLayer("ParkingLot", "Parking Lots", true, false)]

  ngOnInit(): void {
  }
}
