<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
    <app-pageheader [Parent]="this">
        <form-edit-button routerLink="edit">Edit</form-edit-button>
    </app-pageheader>

    <app-tabs>
        <app-tab Title="Configuration">
            <div style="margin:0 !important;" class="columns">
                <div class="column is-6">
                    <article class="panel">
                        <p class="panel-heading">Space {{Model.Name}}</p>
                        <div class="panel-block">
                            <div>
                                <app-field Label="Id" class="control flex-center">{{Model.IId}}</app-field>
                                <app-field Label="Name" class="control flex-center">{{Model.Name}}</app-field>
                                <app-field Label="Location" class="control flex-center">{{Model.LocationString}}</app-field>
                                <app-field Label="Occupied" class="control flex-center">{{Model.Occupied ? 'Yes' : 'No'}}</app-field>
                                <app-field Label="Occupancy Started" class="control flex-center">{{Model.OccupiedOnLocalFriendlyString}}</app-field>
                                <app-field *ngIf="Model.SensorChipId != null" Label="Sensor Assignment" class="control flex-center">{{'Sensor ' + Model.SensorChipId}}</app-field>
                                <app-field Label="Guidance Light Configuration" class="control flex-center" *ngIf="Model.GuidanceLightConfigurationId != null">{{Model.GuidanceLightConfigurationId + ': ' + Model.GuidanceLightPortNumber}}</app-field>
                                <app-field Label="Guidance Light" class="control flex-center" *ngIf="Model.GuidanceLightChipId != null">{{Model.GuidanceLightChipId}}</app-field>
                                <app-field Label="Wake Up Time" class="control flex-center">{{Model.WakeUpTime}}</app-field>
                                <app-field Label="Sleep Time" class="control flex-center">{{Model.SleepTime}}</app-field>
                                <app-field Label="Start of Day Time" class="control flex-center">{{Model.StartOfDayTime}}</app-field>
                                <app-field Label="End of Day Time" class="control flex-center">{{Model.EndOfDayTime}}</app-field>
                            </div>
                        </div>
                    </article>
                </div>
                <div class="column is-6">
                    <app-structureviewermaps [StructureId]="Model.Id" StructureViewerForType="ParkingSpace" [Layers]="StructureViewerLayers" [ShowLevelSelect]="false"></app-structureviewermaps>
                </div>
            </div>
            <div style="margin:0 !important;" class="columns">
                <div class="column is-6">
                    <article class="panel">
                        <p class="panel-heading">Hardware Assignment History</p>
                        <div class="panel-block">
                            <table class="table is-striped" style="width:100% !important">
                                <tr><th>Hardware Type</th><th>Chip Id</th><th>Guidance Light Port</th><th>Assigned Dates</th></tr>
                                <tr *ngFor="let item of Model.DeviceAssignments">
                                    <td *ngIf="item.ClassName == 'ParkingSpaceSensorAssignment'">Sensor</td>
                                    <td *ngIf="item.ClassName == 'ParkingSpacePortAssignment'">Guidance Light</td>
                                    <td>{{item.ChipId}}</td>
                                    <td><div *ngIf="item.GuidanceLightConfigurationId != null">{{item.GuidanceLightConfigurationId}}: {{item.PortNumber}}</div></td>
                                    <td>{{item.DateInstalledString}} - {{item.IsActive ? 'Now' : item.DateUninstalledString}}</td>
                                </tr>
                            </table>
                        </div>
                    </article>
                </div>
            </div>
        </app-tab>
        <app-tab Title="Hardware" *ngIf="Model.Device != null">
            <div style="margin:0 !important;" class="columns">
                <div class="column is-6">
                    <article class="panel">
                        <p class="panel-heading">Sensor {{Model.Device.ChipId}}</p>
                        <div class="panel-block">
                            <div>
                                <app-field Label="Chip Id" class="control flex-center">{{Model.Device.ChipId}}</app-field>
                                <app-field Label="Sensor Type" class="control flex-center">{{Model.Device.ClassName}}</app-field>
                                <app-field Label="Private Encryption Key" class="control flex-center">{{Model.Device.PrivateEncryptionKey}}</app-field>
                                <app-field Label="Version" class="control flex-center">{{Model.Device.VersionNumber}}</app-field>
                                <app-field Label="Status" class="control flex-center">{{Model.Device.Status}}</app-field>
                                <app-field Label="Most Recent Check-In" class="control flex-center">{{Model.Device.GlobalLastCheckIn}}</app-field>
                                <app-field Label="Local Communications" class="control flex-center">Channel: {{Model.Device.Channel}}, Power:{{Model.Device.TxPower}}</app-field>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </app-tab>
        <app-tab Title="Hardware Messages">
            <app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="{{'messaging/ParkingSpace/hardwaremessages/'+Model.IId+'/searches'}}"
                [AllowEdit]="true" [EditAsModal]="true" [ModalComponentType]="HardwareMessageType" EditUrl="hardware/messages/:id" [Filters]="Filters">
                <app-filter-set>
                    <div app-search-filter></div>
                    <div app-date-filter [DateSearchProperty]="'ProcessedOn'"></div>
                    <div app-search-button></div>
                </app-filter-set>
            </app-datalistview>
        </app-tab>
    </app-tabs>
</div>
