<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Form]="Form" [Parent]="this" (OnSave)="Save()"></app-form-save-button>
    </app-pageheader>

    <div class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">
                    Details
                </p>
                <div class="panel-block" style="display: block;">
                    <app-field Label="Name">
                        <div class="control">
                            <input formControlName="Name" class="input" type="text" />
                            <app-validator [For]="this.Form.get('Name')"></app-validator>
                        </div>
                    </app-field>
                    <app-field Label="Description">
                        <div class="control">
                            <input formControlName="Description" class="input" type="text" />
                            <app-validator [For]="this.Form.get('Description')"></app-validator>
                        </div>
                    </app-field>
                    <app-field Label="Space Count">
                        <div class="control">
                            <input formControlName="TotalSpaces" class="input is-static" type="text" readonly />
                        </div>
                    </app-field>
                    <app-field Label="Occupancy Count Mode" tippy="The method used to determine occupancy count. 'Default' mode adds up occupancy reported for each level. 'Count In/Out' mode uses car counters/access control to track vehicles entering/leaving and does not use Level occupancy figures.">
                        <div class="control">
                            <div class="select">
                                <select formControlName="AvailabilityCountMode" class="input">
                                    <option value="Default">Default</option>
                                    <option value="VehiclesPresent">Count in/out</option>
                                    <option value="External">External</option>
                                </select>
                            </div>
                        </div>
                    </app-field>
                    <app-field Label="External Count Provider" tippy="The external provider used to set occupancy count." *ngIf="this.Form.get('AvailabilityCountMode')?.value == 'External'">
                        <div class="control">
                            <div class="select">
                                <select formControlName="ExternalCountProvider" class="input">
                                    <option value="" hidden>None Selected</option>
                                    <option *ngFor="let opt of GetExternalCountProviders()" value="{{opt.Name}}">{{opt.Name}}</option>
                                </select>
                            </div>
                        </div>
                    </app-field>
                    <app-field Label="External Count Source" tippy="The external source used to set occupancy count." *ngIf="this.Form.get('AvailabilityCountMode')?.value == 'External' && this.Form.get('ExternalCountProvider')?.value != null">
                        <div class="control">
                            <div class="select">
                                <select formControlName="ExternalCountProviderSourceId" class="input">
                                    <option value="" hidden>None Selected</option>
                                    <option *ngFor="let opt of GetExternalCountSources(this.Form.get('ExternalCountProvider')?.value)" value="{{opt.Id}}">{{opt.Name}}</option>
                                </select>
                            </div>
                        </div>
                    </app-field>
                    <app-field Label="Open/Closed Status" [HasAddons]="true" tippy="Whether or not the lot is open for parking. Can be triggered automatically using Threshold Sets.">
                        <div class="control">
                            <div class="select">
                                <select formControlName="ClosedByUser" class="input">
                                    <option [value]="false">Open</option>
                                    <option [value]="true">Closed</option>
                                </select>
                            </div>
                        </div>
                        <div class="control" *ngIf="Model.ClosedBySystem == true">
                            <span class="button is-danger" >Currently closed due to {{Model.OccupiedPercent}} Occupancy</span>
                        </div>
                    </app-field>
                    <app-field Label="Threshold Set" tippy="Governs the color of the parking lot based on occupancy. Threshold sets can also close a parking lot at certain thresholds and reopen them when occpancy falls.">
                        <div class="control">
                            <div class="select" *ngIf="ThresholdSets != null">
                                <select formControlName="ParkingThresholdSetId" class="input" type="text">
                                    <option value=""></option>
                                    <option *ngFor="let a of ThresholdSets" [ngValue]="a.Id">{{a.Name}}</option>
                                </select>
                            </div>
                            <span *ngIf="ThresholdSets == null">
                                Loading...
                            </span>
                        </div>
                    </app-field>
                    <app-field Label="Auto Occupancy Reset" tippy="If enabled, the occupancy count will be reset at regular intervals. This is used to combat drift in lots where count in/out is used and missed counts can cause increased inaccuracy over time.">
                        <div class="control">
                            <div class="select">
                                <select formControlName="ResetMode" class="input" type="text">
                                    <option value="0">None</option>
                                    <option value="1">Daily</option>
                                    <option value="2">Weekly</option>
                                    <option value="3">Monthly</option>
                                </select>
                            </div>
                        </div>
                    </app-field>
                    <app-field Label="Reset Time" *ngIf="this.Form.get('ResetMode')?.value != 0">
                        <div class="control">
                            <div class="select">
                                <select formControlName="OccupancyResetTime" class="input">
                                    <option *ngFor="let i of resetTimeSlots" [ngValue]="i.v">{{i.k}}</option>
                                </select>
                            </div>
                        </div>
                    </app-field>
                    <app-field Label="Paid Parking"
                        tippy="Governs whether or not the Parking Lot requires payment. This allows the app to not display this as an option in Pay for Parking">
                        <div class="control">
                            <app-toggleswitch formControlName="IsPaidParking"></app-toggleswitch>
                        </div>
                    </app-field>
                    <app-field *ngIf="Form.get('IsPaidParking')?.value == true" Label="Default Rate Set" tippy="Change the default rate set applied to this parking lot">
                        <div class="control">
                            <div class="select" *ngIf="RateSets != null">
                                <select formControlName="ParkingRateSetId" class="input" type="text">
                                    <option value=""></option>
                                    <option *ngFor="let a of RateSets" [ngValue]="a.Id">{{a.Name}}</option>
                                </select>
                            </div>
                            <span *ngIf="RateSets == null">
                                Loading...
                            </span>
                        </div>
                    </app-field>
                    <app-field *ngIf="Form.get('IsPaidParking')?.value == true" Label="Payment Mode" tippy="Defines when the payment is expected to be taken, either before or after the session has begun">
                        <div class="control">
                            <div class="select">
                                <select formControlName="PaymentMode" class="input">
                                    <option value="0" tippy="Pay on Exit">Post Pay</option>
                                    <option value="1" tippy="Pay for selected time">Pre Pay</option>
                                </select>
                            </div>
                        </div>
                    </app-field>
                    <app-field Label="Payment Target"
                        tippy="Governs whether or not users must provide a Space Name when paying for parking or applying a permit. If each space is physically marked with an identifier then Marked Spaces Mode should be used, however some lots have no markings, in which case Unmarked Spaces Mode should be used.">
                        <div class="control">
                            <div class="select">
                                <select formControlName="PaymentRequiresSpaceName" class="input">
                                    <option [ngValue]="true">Marked Spaces - User must select a Space</option>
                                    <option [ngValue]="false">Unmarked Spaces - User selects Lot only </option>
                                </select>
                            </div>
                        </div>
                    </app-field>
                    <app-field Label="Permit Parking">
                        <div class="control">
                            <app-toggleswitch formControlName="IsPermitParking"></app-toggleswitch>
                        </div>
                    </app-field>
                    <app-field Label="Type">
                        <div class="control select">
                            <select formControlName="Type" class="input">
                                <option [ngValue]="0">On-Street Parking</option>
                                <option [ngValue]="100" [disabled]="Form.get('Levels')?.value.length > 1">Single Level</option>
                                <option [ngValue]="200">Multi Level</option>
                            </select>
                        </div>
                    </app-field>
                    <app-field Label="Enable Global Directory">
                        <div class="control">
                            <app-toggleswitch formControlName="EnableGlobalDirectory"></app-toggleswitch>
                        </div>
                    </app-field>
                </div>
            </article>
            <article class="panel">
                <p class="panel-heading">
                    {{((Form.get('Type')?.value >= 100) ? 'Levels' : 'Zones')}}
                </p>
                <a class="panel-block">
                    <span class="value">
                        <div class="control" formArrayName="Levels">
                            <table class="table is-striped is-bordered is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Spaces</th>
                                        <th>Manage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let level of FormArray(Form, 'Levels').controls; let i = index"
                                        [formGroupName]="i">
                                        <td><input type="text" class="input is-shortest" formControlName="LevelNumber">
                                        </td>
                                        <td><input type="text" class="input" formControlName="Name" /></td>
                                        <td><input type="text" class="input is-static" readonly
                                                formControlName="TotalSpaces" /></td>
                                        <td style="vertical-align: inherit;">
                                            <button type="button"
                                                (click)="EditLevel(level)"
                                                class="button is-link is-small ng-star-inserted mr-2">
                                                Details
                                                <i class="ml-2 fa fa-chevron-right"></i>
                                            </button>
                                            <button type="button"
                                                (click)="EditLevelDesign(level)"
                                                class="button is-link is-small ng-star-inserted">
                                                Designer
                                                <i class="ml-2 fa fa-pen-ruler"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="99">
                                            <button class="button is-primary is-outlined is-fullwidth"
                                                (click)="AddLevel()">Add
                                                {{(Form.get('Type')?.value > 0) ? 'Level' : 'Zone'}}</button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </span>
                </a>
            </article>
        </div>
        <div class="column is-6">
            <!-- <app-lotviewer *ngIf="Model != null && Model.Levels.length > 0" [ParkingLotId]="Model.Id" ></app-lotviewer> -->
            <app-map-input formControlName="GeoPolygonPoints" MapWidth="100%" MapHeight="500" [Editable]="(Form.get('Type')?.value > 0)"
                [BackgroundPolygonPoints]="MapItems">
            </app-map-input>

        </div>
    </div>
</div>