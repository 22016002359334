import { AbstractControl, UntypedFormArray } from "@angular/forms";
import { SafeHtml, SafeUrl } from "@angular/platform-browser";
import { Bounds, Geo } from "src/app/util/geo";
import { LevelmapeditorComponent } from "./levelmapeditor.component";
import { v4 as uuidv4 } from 'uuid';

export abstract class MapItemBase {
    constructor(formControl: AbstractControl, polygon: number[][]) {
      this.FormControl = formControl;
      let bounds = Geo.GetPolygonPointBounds(polygon);
      if(bounds.Left < 0 || bounds.Top < 0){
        polygon = [[0.001,.001], [0.001,0.002],[0.002,0.02],[0.002,0.001]];
      }
      this.PolygonPoints = polygon;
  
      let angleControl = this.FormControl.get("DisplayAngle");
      if(angleControl != null){
        this.DisplayAngle = angleControl.value;
      }
    }
    public Editor !: LevelmapeditorComponent;
    public FormControl: AbstractControl;
    public PolygonPoints: number[][];
    public BisectPoints: number[][] = [];
    public PreDragPolygonPoints: number[][] = [];
    public ClassName: string = "";
    public path: string | null = null;
    public entryPath: string = "";
    public exitPath: string = "";
    public DisplayAngle : number = 0;
    public Center: number[] = [0, 0];
    public Top: number = 0;
    public Left: number = 0;
    public Right: number = 0;
    public Bottom: number = 0;
    public Height: number = 0;
    public Width: number = 0;
    public Resizable : boolean = true;
    public Selected: boolean = false;
    public RequireParent : string | null = null;
    public ParentProperty : string | null = null;
    public ChildProperty : string | null = null;
    public ParentArray : string | null = null;
    public ActiveDirection : string | null = null;
  
    public GetStrokeColor(){
      return "#000000";
    }
    public GetFillColor(){
      return "none";
    }
  
    public GetImage(top: number, height: number, left: number) : SafeUrl | null{
      return null;
    }
  
    public UpdatePoints() {
      if (this.Editor == null) {
        return;
      }
      if(this.PolygonPoints.length > 0){
        for (let point of this.PolygonPoints) {
          point[0] = Math.round(point[0] * this.Editor.DesignSurfacePixelsPerKm) / this.Editor.DesignSurfacePixelsPerKm;
          point[1] = Math.round(point[1] * this.Editor.DesignSurfacePixelsPerKm) / this.Editor.DesignSurfacePixelsPerKm;
        }
        this.BisectPoints = Geo.BisectPointsArray(this.PolygonPoints);
        this.Center = Geo.PolygonCenterAsNumbers(this.PolygonPoints);
        this.path = this.GetSvgPath();
        this.Left = Geo.LeftMost(this.PolygonPoints);
        this.Right = Geo.RightMost(this.PolygonPoints);
        this.Width = this.Right - this.Left;
        this.Top = Geo.TopMost(this.PolygonPoints);
        this.Bottom = Geo.BottomMost(this.PolygonPoints);
        this.Height =  this.Bottom - this.Top;
      }
  
      if (this.FormControl != null) {
        this.FormControl.get("PolygonPoints")?.setValue(this.PolygonPoints);
        this.FormControl.get("LocationPoints")?.setValue(this.Center);
        this.FormControl.markAsDirty();
      }
    }
    public GetSvgPath() : string{
      return Geo.DoublesToSvgPath(this.PolygonPoints, this.Editor.ViewScale);
    }

    public GetSvgPathToNextIndex(index: number) : string{

      var x = this.PolygonPoints;
      var index2 = Number(index)+1;
      var t = [x[index], x[index2]];
      return Geo.DoublesToSvgPath(t, this.Editor.ViewScale);
    }

    public abstract Clone(): MapItemBase;
  }
  export class ParkingLevelMapItem extends MapItemBase {
    public override ClassName: string = "ParkingLevel";
    constructor(formControl: AbstractControl, polygon: number[][]) {
      super(formControl, polygon);
    }
    public override Clone(): MapItemBase {
      return this; //only 1 level allowed in the editor so don't clone
    }
  }
  export class ParkingRowMapItem extends MapItemBase {
    public override ClassName: string = "ParkingRow";
    public override RequireParent : string = "ParkingLevel";
    public override ParentProperty : string = "ParkingLevelId";
    constructor(formControl: AbstractControl, polygon: number[][]) {
      super(formControl, polygon);
    }
    public override Clone(): MapItemBase {
      let rows = this.Editor.ModelEditor.FormArray(this.Editor.Form, "Rows");
      let newRow = { Id: uuidv4(), Name: "Row", Spaces: [], PolygonPoints: []};
      let fg = this.Editor?.ModelEditor.AddToFormArray(rows, newRow, "Rows") as AbstractControl;
      let item = new ParkingRowMapItem(fg, JSON.parse(JSON.stringify(this.PolygonPoints)));
      item.PreDragPolygonPoints = this.PreDragPolygonPoints;
      this.Editor?.AddMapItem(item);
      return item;
    }
  }
  export class ParkingSpaceMapItem extends MapItemBase {
    public override RequireParent : string = "ParkingRow";
    public override ParentProperty : string = "ParkingRowId";
    public override ParentArray : string = "Spaces";
    public override ClassName: string = "ParkingSpace";
    public Row !: ParkingRowMapItem;
    public Id : string = "";
    Sanitizer: any;
    constructor(formControl: AbstractControl, row: ParkingRowMapItem, polygon: number[][]) {
      super(formControl, polygon);
      this.Row = row;
      this.Id = formControl.get("Id")?.value;
    }
  
    public override GetStrokeColor(): string {
      let parkingspaceType = this.FormControl.get("ParkingSpaceTypeId")?.value;
      if(parkingspaceType == null){
        return "#000000";
      }
      else{
        let parkingSpaceType =this.Editor.ParkingSpaceTypes.filter(x => x.Id == parkingspaceType);
        if(parkingSpaceType.length > 0){
          return "#" + parkingSpaceType[0].Color;
        }
      }
      return "#000000";
    }
  
    public override GetFillColor(): string {
      let parkingspaceType = this.FormControl.get("ParkingSpaceTypeId")?.value;
      if(parkingspaceType == null || parkingspaceType == ""){
        return "none";
      }
      else{
        let parkingSpaceType =this.Editor.ParkingSpaceTypes.filter(x => x.Id == parkingspaceType);
        if(parkingSpaceType.length > 0){
          return "#" + parkingSpaceType[0].Color;
        }
      }
      return "#000000";
    }
  
    public override GetImage(top: number, height: number, left: number): SafeHtml | null {
      let parkingspaceType = this.FormControl.get("ParkingSpaceTypeId")?.value;
      if(parkingspaceType == null){
        return null
      }
      else{
        let parkingSpaceType =this.Editor.ParkingSpaceTypes.filter(x => x.Id == parkingspaceType);
        if(parkingSpaceType.length > 0 && parkingSpaceType[0].MediaId != null){
          return this.Editor.sanitizer.bypassSecurityTrustHtml("<image href='" + parkingSpaceType[0].HtmlImage + "' x='" + (left+(height/10)) + "'y='" + (top+(height/3)) + "' height='" + height/2.8 + "' width='" + height/2.8 + "'/>");
        }
      }
      return null;
    }
  
    public override Clone(): MapItemBase {
      let row = this.Row.FormControl;
      let newSpace = { Id: uuidv4(), Name: "0", PolygonPoints: this.PolygonPoints };
      //try to increment the space number
      let regex = new RegExp(/(.*?)([0-9]+)/);
      let match = regex.exec(this.FormControl.get("Name")?.value);
  
      let count = this.Editor.SelectedItems.filter(x => x instanceof ParkingSpaceMapItem).length;
      if(count == 0) count = 1;
      if (match && match.length > 1) {
        newSpace.Name = match[1] + (parseInt(match[2]) + count);
      }
  
      let spaces = row.get('Spaces') as UntypedFormArray;
      let fg = this.Editor?.ModelEditor.AddToFormArray(spaces, newSpace, "Rows/Spaces") as AbstractControl;
      let item = new ParkingSpaceMapItem(fg, this.Row, JSON.parse(JSON.stringify(this.PolygonPoints)));
      item.PreDragPolygonPoints = this.PreDragPolygonPoints;
      this.Editor?.AddMapItem(item);
      return item;
    }
  }
  export class GateMapItem extends MapItemBase{
    public override ClassName: string = "Gate";
    public override RequireParent : string = "ParkingLevel";
    constructor(formControl: AbstractControl, polygon: number[][]) {
      super(formControl, polygon);
    }
    public override Clone(): MapItemBase {
      let gates = this.Editor.ModelEditor.FormArray(this.Editor.Form, "Gates");
      let newGate = {Id: uuidv4(), Name: "Gate", Lanes: [], PolygonPoints: []};
      let fg = this.Editor.ModelEditor.AddToFormArray(gates, newGate, "Gates") as AbstractControl;
      let item = new GateMapItem(fg, JSON.parse(JSON.stringify(this.PolygonPoints)));
      item.PreDragPolygonPoints = this.PreDragPolygonPoints;
      this.Editor.AddMapItem(item);
      return item;
    }
  }
  export class LaneMapItem extends MapItemBase{
    public override ClassName: string = "Lane";
    public override RequireParent : string = "Gate";
    public override ParentArray : string = "Lanes";
    constructor(formControl: AbstractControl, polygon: number[][]) {
      super(formControl, polygon);
    }
    public override Clone(): MapItemBase {
      let lanes = this.Editor.ModelEditor.FormArray(this.FormControl, "Lanes");
      let newLane = {Id: uuidv4(),  Name: "", Cameras: [], Barriers: [], Displays: [], PaymentTerminals: [], CellRouters : [], PolygonPoints: []};
      let fg = this.Editor.ModelEditor.AddToFormArray(lanes, newLane, "Gates/Lanes") as AbstractControl;
      let item = new LaneMapItem(fg, JSON.parse(JSON.stringify(this.PolygonPoints)));
      item.PreDragPolygonPoints = this.PreDragPolygonPoints;
      this.Editor.AddMapItem(item);
      return item;
    }
  }
  
  export class ControllerMapItem extends MapItemBase{
    public override ClassName: string = "LocalController";
    public override RequireParent : string = "Lane";
    public override ChildProperty : string = "LocalControllerId";
    public override Resizable: boolean = false;
    constructor(formControl: AbstractControl, location: number[]) {
      super(formControl, Geo.NewPolygonAtCenter(location, 0.002));
    }
    public override Clone(): MapItemBase {
      let controllers = this.Editor.ModelEditor.FormArray(this.FormControl, "Controllers");
      let newController = { Id: uuidv4(), ClassName: "LocalController", };
      let fg = this.Editor.ModelEditor.AddToFormArray(controllers, newController, "Controllers") as AbstractControl;
      let item = new ControllerMapItem(fg, [0.0]);
      item.PreDragPolygonPoints = this.PreDragPolygonPoints;
      return item;    
    }
  }
  export class CameraMapItem extends MapItemBase{
    public override ClassName: string = "Camera";
    public override RequireParent : string | null = "Lane";
    public override ParentProperty : string | null = "LaneId";
    public override ParentArray : string | null = "Cameras";
    public override Resizable: boolean = false;
    public override ActiveDirection: string | null = "Both";
    constructor(formControl: AbstractControl, location: number[]) {
      super(formControl, Geo.NewPolygonAtCenter(location, 0.004));
    }
    public override Clone(): MapItemBase {
      return new  ControllerMapItem(this.FormControl, [0.0]);
    }
  }

  export class BushMapItem extends MapItemBase{
    public override ClassName: string = "Bush";
    public override Resizable: boolean = false;
    constructor(formControl: AbstractControl, location: number[]) {
      super(formControl, Geo.NewPolygonAtCenter(location, 0.004));
    }
    public override Clone(): MapItemBase {
      return new BushMapItem(this.FormControl, [0.0]);
    }
  }

  export class BarrierMapItem extends MapItemBase{
    public override ClassName: string = "Barrier";
    public override RequireParent : string | null = "Lane";
    public override ParentProperty : string | null = "LaneId";
    public override ParentArray : string | null = "Barriers";
    public override Resizable: boolean = false;
    public override ActiveDirection: string | null = "Both";
    constructor(formControl: AbstractControl, location: number[]) {
      super(formControl, Geo.NewPolygonAtCenter(location, 0.001, 0.004));
    }
    public override Clone(): MapItemBase {
      return new  ControllerMapItem(this.FormControl, [0.0]);
    }
  }
  export class TrafficLightMapItem extends MapItemBase {
    public override ClassName: string = "TrafficLight";
    public override RequireParent : string | null = "Lane";
    public override ParentProperty : string | null = "LaneId";
    public override ParentArray : string | null = "TrafficLights";
    public override Resizable: boolean = false;
    public override ActiveDirection: string | null = "Both";
    constructor(formControl: AbstractControl, location: number[]) {
      super(formControl, Geo.NewPolygonAtCenter(location, 0.001, 0.004));
    }
    public override Clone(): MapItemBase {
      return new  ControllerMapItem(this.FormControl, [0.0]);
    }
  }
  export class ShapeMapItem extends MapItemBase {
    public override ClassName: string = "Shape";
    public override RequireParent : string = "ParkingLevel";
    public Row !: ParkingRowMapItem;
    constructor(formControl: AbstractControl, polygon: number[][]) {
      super(formControl, polygon);
    }
    public override Clone(): MapItemBase {
      let mapItems = this.Editor.Form.get("MapItems") as UntypedFormArray;
      let formValue = JSON.parse(JSON.stringify(this.FormControl.value));
      formValue.Id = uuidv4();
      let fg = this.Editor?.ModelEditor.AddToFormArray(mapItems, formValue, "MapItems") as AbstractControl;
      let item = new ShapeMapItem(fg, formValue.PolygonPoints);
      item.PreDragPolygonPoints = this.PreDragPolygonPoints;
      this.Editor?.AddMapItem(item);
      return item;
    }
  }

  export class GatewayMapItem extends MapItemBase {
    public override ClassName: string = "GatewayConfiguration";
    public override RequireParent: string | null = "ParkingLevel";
    public override Resizable: boolean = false;
    constructor(formControl: AbstractControl, location: number[]) {
      super(formControl, Geo.NewPolygonAtCenter(location, 0.002));
    }

    public override Clone(): MapItemBase {
      throw new Error("Method not implemented.");
    }
  }

  export class SignMapItem extends MapItemBase {
    public override ClassName: string = "SignConfiguration";
    public override RequireParent: string | null = "ParkingLevel";
    public override Resizable: boolean = false;
    constructor(formControl: AbstractControl, location: number[]) {
      super(formControl, Geo.NewPolygonAtCenter(location, 0.002));
    }

    public override Clone(): MapItemBase {
      throw new Error("Method not implemented.");
    }
  }

  export class GuidanceLightMapItem extends MapItemBase {
    public override ClassName: string = "GuidanceLightConfiguration";
    public override RequireParent: string | null = "ParkingLevel";
    public override Resizable: boolean = false;
    constructor(formControl: AbstractControl, location: number[]) {
      super(formControl, Geo.NewPolygonAtCenter(location, 0.002));
    }

    public override Clone(): MapItemBase {
      throw new Error("Method not implemented.");
    }
  }

  export class CarCounterMapItem extends MapItemBase {
    public override ClassName: string = "CarCounterConfiguration";
    public override RequireParent: string | null = "ParkingLevel";
    public override Resizable: boolean = false;
    constructor(formControl: AbstractControl, location: number[]) {
      super(formControl, Geo.NewPolygonAtCenter(location, 0.002));
    }

    public override Clone(): MapItemBase {
      throw new Error("Method not implemented.");
    }
  }
  
  
  