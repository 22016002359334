<app-loader [Parent]="this"></app-loader>

<app-pageheader [Parent]="this">
</app-pageheader>

<app-levelviewer #levelviewercomponent *ngIf="SelectedLot != null" [parkingLotId]="SelectedLot['Id']" [AllowSelection]="true" (DeviceClicked)="DeviceClicked($event)"
     [parkingLevelId]="SelectedLevel['Id']" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0;" >
</app-levelviewer>

<div class="columns" style="position:absolute; bottom: 30px; width:100%; left:10px;" *ngIf="SelectedLot != null">
    <div class="column is-4" style="background-color: #727171; height: 8em;" >
        <div class="columns">
            <div class="column is-12">
                <div class="select">
                    <select class="input" style="background-color:#363535; color:white; border:none; text-transform: uppercase; text-align: left;" *ngIf="ParkingLots != null && ParkingLots.length >= 1" (change)="ParkingLotChanged($event)">
                        <option *ngFor="let pl of ParkingLots; let i = index" [value]="pl.Id" [selected]="SelectedLot != null && SelectedLot['Id'] == pl.Id">{{pl.Name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="columns">    
            <div class="column is-12">
                    <div class="container">
                            <div class="select" style="width:60%">
                                <select class="input" style="background-color:#363535; color:white; border:none; text-transform: uppercase;" *ngIf="SelectedLot.Levels != null && SelectedLot.Levels.length >= 1" (change)="ParkingLevelChanged($event)">
                                    <option *ngFor="let pl of SelectedLot.Levels; let i = index" [value]="pl.Id" [selected]="SelectedLevel != null && SelectedLevel['Id'] == pl.Id">{{pl.Name}}</option>
                                </select>
                            </div>
                            <div class="select" style="padding-left:10px;">
                                <select class="input" style="background-color:#363535; color:white; border:none; text-transform: uppercase;" (change)="onSelectChange($event)">
                                    <option disabled selected value="">Actions</option>
                                    <option value="edit">Edit Level Counts</option>
                                    <option value="close">Close Level</option>
                                    <option value="open">Open Level</option>
                                </select>
                            </div>
                            <div style="padding-left:10px;">
                                <div class="mediabutton" (click)="ToggleSound()">
                                    <div style="color:white; margin:10px;"><i class="fa fa-solid" [ngClass]="SoundEnabled ? 'fa-volume-up' : 'fa-volume-off'"></i></div>
                                </div>
                            </div>
                        </div>         
                </div>
        </div>
    </div>
    <div class="column is-4" style="height: 8em; background-color: #363535; border-style: solid 1px white;" >
        <div class="lotTitle" #lotTitle *ngIf="SelectedLot != null">
            <div class="occupancyTitle">OCCUPANCY</div>
            <div class="occupancy">{{SelectedLot.OccupiedSpaces}} / {{SelectedLot.TotalSpaces}}</div>
            <div class="operatorconsoleheat" *ngIf="SelectedLot != null">
                <app-heatmap class="heatmap" TopLabel="New" [width]="250" BottomLabel="4 Hours +" >
                </app-heatmap>
            </div>
        </div>
    </div>
    <div class="column is-4" style="background-color: #727171; height: 8em;" >
        <div class="propertiestab" *ngIf="SelectedLot != null">
            <div class="select objects">
                <button class="input" style="background-color:#363535; color:white; border:none; text-transform: uppercase; padding: 20px; max-width: 100%" (click)="ToggleBox()"> {{SelectedDevice != null ? SelectedDevice.DevicePath : 'Select Device'}}
                </button>
            </div>
        
            <div *ngIf="SelectedDevice != null && SelectedDevice.ClassName == 'Barrier'" style="margin:10px;">
                <button type="button" class="button is-small operatorconsole" (click)="OpenBarrier(SelectedDevice.Id)">Open</button>
                <button type="button" class="button is-small operatorconsole" (click)="CloseBarrier(SelectedDevice.Id)">Close</button>
                <button type="button" class="button is-small operatorconsole" (click)="KeepBarrierOpen(SelectedDevice.Id)">Keep Open</button>
            </div>
        </div>
        <div class="box" *ngIf="SelectedLot != null" [ngStyle]="{ 'display': boxVisible ?  'block' : 'none' }">
            <div class="hid-box" [ngStyle]="{ 'top': boxVisible ?  '0px' : '100%' }">
                <aside class="menu" *ngIf="SelectedLevel != null">
                    <p class="menu-label">
                      {{SelectedLevel.Name}}
                    </p>
                    <ul class="menu-list objects">
                      <li *ngFor="let gate of SelectedLevel.Gates"><a>{{gate.Name}}</a>
                        <ul>
                            <li *ngFor="let lane of gate.Lanes"><a>{{lane.Name}}<span class="select is-small" (change)="ChangeLaneDirection(lane.Id, $event)"><select [disabled]="lane.AllowedDirections != 'Both'"> 
                                <option *ngIf="lane.AllowedDirections == 'Entry' || lane.AllowedDirections == 'Both'" [selected]="lane.CurrentDirection == 'Entry'"> Entry </option>
                                <option *ngIf="lane.AllowedDirections == 'Exit' || lane.AllowedDirections == 'Both'" [selected]="lane.CurrentDirection == 'Exit'"> Exit </option>
                                </select></span></a>
                                <ul>
         
                                            <li *ngFor="let barrier of lane.Barriers" (click)="DeviceClicked(barrier)"><a>{{barrier.LocalAddress}}:{{barrier.LocalPort}} | {{barrier.Type}}</a></li>
        
        
                                            <li *ngFor="let camera of lane.Cameras" (click)="DeviceClicked(camera)"><a>{{camera.LocalAddress}}:{{camera.LocalPort}}</a></li>
        
        
                                            <li *ngFor="let cellrouter of lane.CellRouters" (click)="DeviceClicked(cellrouter)"><a>{{cellrouter.Name}}</a></li>
        
        
                                            <li *ngFor="let display of lane.Displays" (click)="DeviceClicked(display)"><a>{{display.LocalAddress}}:{{display.LocalPort}}</a></li>
        
        
                                            <li *ngFor="let paymentTerminal of lane.PaymentTerminals" (click)="DeviceClicked(paymentTerminal)"><a>{{paymentTerminal.LocalAddress}}:{{paymentTerminal.LocalPort}}</a></li>
        
                                    <li *ngIf="lane.LocalControllerId != null">
                                        <ul *ngFor="let controller of SelectedLevel.Controllers">
                                            <li *ngIf="controller.Id == lane.LocalControllerId" (click)="DeviceClicked(controller)"><a>{{controller.Name}}</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                          </ul>
                        </li>
                    </ul>
                  </aside>
              </div>
    </div>
</div>
</div>

<div class="console" id="console" *ngIf="SelectedLot != null">
    <p *ngFor="let ci of ConsoleLines">&gt; {{ci}}</p>
</div>