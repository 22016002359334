import { Component, Input, OnInit } from '@angular/core';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { ApiServiceBase } from 'src/app/Services/api.service.base';

@Component({
  selector: 'app-occupancyserieschart',
  templateUrl: './occupancyserieschart.component.html',
  styleUrls: ['./occupancyserieschart.component.scss']
})
export class OccupancyserieschartComponent implements OnInit {
    @Input()
    public Title : string = "Occupancy";
    @Input()
    public Period : string = "today";
    @Input()
    public DataType : string = "inflows";
    @Input()
    public ChartType : string = "line";
    @Input()
    public Height : number = 100;
    @Input()
    public StructureId : string = "all";
    @Input()
    public StructureType : string = "";
    public Url : string= "";
  
    public ChartOptions : ChartOptions = {
      elements: {
        line: {
          tension: 0.2
        }
      },
      scales:{
        yAxes:{
          beginAtZero: true,
          ticks: {
            precision : 0
          }
        }
      },
      plugins: {
        legend : { display: false }
      }
    };
    public ChartData : ChartData = {
      datasets: [],
    };
    public ChartColors: string[] = [
      'green'
    ];
  
    constructor(private apiService : ApiServiceBase) { }
  
    ngOnInit(): void {

      this.Url = 'infrastructure/occupancy/series/' + (this.StructureType != "" ? this.StructureType+ "/" : "") + (this.StructureId != "" ? this.StructureId : "all") + '/' + this.Period + '/' + this.DataType
      

      this.apiService.Get<any>(this.Url).then(result => {
        this.ChartData =  {
          datasets: [{
                  data: result.Values,
                  label: 'Occupancy',
                  pointBackgroundColor: ['green'],
                  pointBorderColor: ['green'],
                  backgroundColor: ['green'],
                  borderColor: ['green']
          }],
          labels: result.Labels,
        };
      });
    }
  
  }
  