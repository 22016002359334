import { Component, Input, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'app-editterminalevents',
  templateUrl: './editterminalevents.component.html',
  styleUrls: ['./editterminalevents.component.scss']
})
export class EditterminaleventsComponent  extends SimpleModalComponent<any, any> {
  
  @Input()
  public Model : any = {};
  public EventOptions = [
    { Name : "Reset Session", Value : "ResetSession", Description : "Clears the current session.", AllowArguments : false },
    { Name : "Clear Session Plate", Value : "ClearSessionPlateNumber", Description : "Clears the Plate value from the current session.", AllowArguments : false },
    { Name : "Start Payment", Value : "InitiatePayment", Description : "Attempts to begin a transaction for the amount owing on the current session using an attached payment device.", AllowArguments : false },
    { Name : "Cancel Payment", Value : "CancelPayment", Description : "Sends a cancel command to attached payment devices", AllowArguments : false },
    { Name : "Commit Session", Value : "CommitSession", Description : "Saves the current session and queues it for upload, then clears the current session.", AllowArguments : false },
    { Name : "Find Saved Session By Plate", Value : "LoadExistingSession", Description : "Looks for an existing open session by Plate and loads that session as the current session", AllowArguments : false },
    { Name : "Find Saved Session By Plate Exact", Value : "LoadExistingSessionExact", Description : "Looks for an existing open session with the exact Plate and loads that session as the current session", AllowArguments : false },
    { Name : "Open Barrier", Value : "OpenBarriers", Description : "Opens the barrier arm", AllowArguments : false },
    { Name : "Close Barrier", Value : "CloseBarriers", Description : "Closes the barrier arm", AllowArguments : false },
    { Name : "Keep Barrier Open", Value : "KeepBarriersOpen", Description : "Opens the barrier arm and sets it to stay open until a close command is sent", AllowArguments : false },
    { Name : "Set Entry Date/Time", Value : "SetEntryDate", Description : "Sets the current session to have an Entry Date of 'now'", AllowArguments : false },
    { Name : "Set Exit Date/Time", Value : "SetExitDate", Description : "Sets the current session to have an Exit Date of 'now'", AllowArguments : false },
    { Name : "Check Access", Value : "CheckAccess", Description : "Checks the plate on the current session to see if any Access Rules will allow the plate to enter. This will result in either an 'AccessGranted' or 'AccessDenied' event being raised.", AllowArguments : false },
    { Name : "Recalculate", Value : "Recalculate", Description : "Recalculates the Fee required as payment for the session", AllowArguments : false },
    { Name : "Set Exit Type", Value : "SetExitType", Description : "Records the type of exit for the session, can be used to record tailgates, overrides etc.", AllowArguments : false },
    { Name : "Traffic Light Go", Value: "TrafficLightGo", Description : "Set traffic light to allow traffic", AllowArguments : false },
    { Name : "Traffic Light Stop", Value: "TrafficLightStop", Description : "Set traffic light to stop traffic", AllowArguments : false },
    { Name : "Apply Code", Value:"ApplyCode", Description : "Apply a Price Modifier code to the session", AllowArguments : true },
    { Name : "Take Snapshot", Value:"TakeSnapshot", Description : "Ask the camera to take a snapshot", AllowArguments : false },
  ];
  public ExitTypes = [
    { Name : "No Fee Payable", Value: "NoFeePayable" },
    { Name : "Fee Already Paid", Value: "FeePaidPrior" },
    { Name : "Paid at Exit", Value: "FeePaidAtGate" },
    { Name : "Refused to Pay", Value: "FeeDeclinedAtGate" },
    { Name : "Barrier Override", Value: "GateOverride" },
    { Name : "Tailgated", Value: "Tailgated" },
    { Name : "Barrier Override", Value: "GateOverride" },
    { Name : "No Session Found", Value: "SessionNotFound" },
  ];

  public SelectedEvent : any = null;

  constructor() {
    super();
  }
  
  public Confirm() {
    this.result = this.Model;
    this.close();
  }

  public DeleteEvent(index : number){
    this.Model.EntryEvents.splice(index, 1);
  }
  public AddSelectedEvent(){
    this.Model.EntryEvents.push({ id : uuidv4(), Event : this.SelectedEvent.Value, AllowArguments: this.SelectedEvent.AllowArguments, Arguments: null })
  }

  public MoveUp(index: number) {
    this.Move(index);
  }

  public MoveDown(index: number) {
    this.Move(index + 1)
  }

  public Move(index: number) {
    let entryEvents = this.Model.EntryEvents;
    if (index <= 0) {
      // this is the top element, return
      return;
    }
    if (index >= entryEvents.length) {
      // try to move the last one down
      return;
    }

    let am = entryEvents[index];
    entryEvents.splice(index, 1);
    entryEvents.splice(index - 1, 0, am);
  }
}