<div class="single-chart" style="width:100%; height:100%;">
    <svg viewBox="0 0 36 36" class="circular-chart blue" [ngStyle]="{'height': Height + 'px'}">
      <path class="circle-bg"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path class="circle"
        [attr.stroke-dasharray]="Percent + ',100'"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text x="18" y="20.35" class="percentage">{{Percent}}%</text>
    </svg>
</div>