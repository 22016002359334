<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>

    <div class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">Sign Configuration</p>
                <div class="panel-block">
                    <div class="three-quarters-width">
                        <app-field Label="Name" class="control">
                            <input formControlName="Name" class="input mb-3" type="text" />
                            <app-validator [For]="this.Form.get('Name')"></app-validator>
                        </app-field>
                        <app-field Label="Chip Id" class="control">
                            <input formControlName="ChipId" class="input mb-3" type="number" readonly/>
                            <app-validator [For]="this.Form.get('ChipId')"></app-validator>
                        </app-field>
                        <app-field Label="Mode" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="Mode">
                                    <option value="0">Polling</option>
                                    <option value="1">Local Device</option>
                                    <option value="2">Polling Master</option>
                                    <option value="3">Local Device Master</option>
                                    <option value="4">Slave</option>
                                    <option value="5">Push</option>
                                    <option value="6">Reserved</option>
                                </select>
                            </span>
                            <app-validator [For]="this.Form.get('Mode')"></app-validator>
                        </app-field>
                        <app-field Label="Sleep Time" class="control">
                            <input formControlName="SleepTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('SleepTime')"></app-validator>
                        </app-field>
                        <app-field Label="Wake Up Time" class="control">
                            <input formControlName="WakeUpTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('WakeUpTime')"></app-validator>
                        </app-field>
                        <app-field Label="Start of Day" class="control">
                            <input formControlName="StartOfDayTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('StartOfDayTime')"></app-validator>
                        </app-field>
                        <app-field Label="End of Day" class="control">
                            <input formControlName="EndOfDayTime" class="input mb-3" type="time"/>
                            <app-validator [For]="this.Form.get('EndOfDayTime')"></app-validator>
                        </app-field>
                        <app-field Label="Target Structures" [HasAddons]="true">
                            <div class="control">
                                <div class="field is-grouped is-grouped-multiline">
                                    <div class="tags has-addons" *ngFor="let x of this.Form.get('TargetStructures')?.value; let i = index">
                                      <a class="tag">{{x.LocationString}}</a>
                                    </div>
                                    <div class="tags has-addons">
                                        <a class="tag button is-primary" (click)="AddParkingStructure()">Edit</a>
                                    </div>
                                </div>
                            </div>
                        </app-field>
                    </div>
                </div>
            </article>
            <article class="panel">
                <p class="panel-heading">Local Communications</p>
                <div class="panel-block">
                    <div formGroupName="Device" class="three-quarters-width">
                        <app-field Label="Radio Channel" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="RadioChannel">
                                    <option *ngFor="let i of [0,1,2,3,4,5,6,7]" value="{{i}}">{{i}}</option>
                                </select>
                            </span>
                            <app-validator [For]="this.Form.get('RadioChannel')"></app-validator>
                        </app-field>
                        <app-field Label="Power" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="TxPower">
                                    <option *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]" value="{{i}}">{{i}}</option>
                                </select>
                            </span>
                            <app-validator [For]="this.Form.get('TxPower')"></app-validator>
                        </app-field>
                    </div>
                </div>
            </article>
        </div>
        <div class="column is-6">
            <article class="panel" *ngIf="Model.Device != null">
                <p class="panel-heading">Device Settings</p>
                <div class="panel-block">
                    <div formGroupName="Device" class="three-quarters-width">
                        <app-field Label="Hardware Version" class="control">
                            <input class="input mb-3" formControlName="HardwareVersionVersion" type="number" readonly/>
                        </app-field>
                        <app-field Label="Firmware Version" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="NewFirmwareVersion">
                                    <option *ngFor="let item of FirmwareVersions" [ngValue]="item.Version">{{item.Version}}</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field Label="Extended Configuration" class="control">
                            <input formControlName="ExtendedConfiguration" class="input mb-3" type="text"/>
                            <app-validator [For]="this.Form.get('ExtendedConfiguration')"></app-validator>
                        </app-field>
                        <app-field Label="Custom Configuration" class="control">
                            <input formControlName="CustomConfigurationString" class="input mb-3" type="text"/>
                            <app-validator [For]="this.Form.get('CustomConfigurationString')"></app-validator>
                        </app-field>
                        <app-field Label="Checkin Override (Minutes)" class="control">
                            <input formControlName="MinutesUntilCompulsaryCheckinOverride" class="input mb-3" type="text"/>
                            <app-validator [For]="this.Form.get('MinutesUntilCompulsaryCheckinOverride')"></app-validator>
                        </app-field>
                        <app-field Label="Calibration Override (Minutes)" class="control">
                            <input formControlName="MinutesUntilForcedCalibrationOverride" class="input mb-3" type="text"/>
                            <app-validator [For]="this.Form.get('MinutesUntilForcedCalibrationOverride')"></app-validator>
                        </app-field>
                    </div>
                </div>
            </article>
            <article class="panel">
                <p class="panel-heading">Display Settings</p>
                <div class="panel-block">
                    <div class="three-quarters-width">
                        <app-field Label="Display Rule Set" class="control mb-3">
                            <span *ngIf="DisplayRules != null" class="select is-fullwidth">
                                <select formControlName="DisplayRuleSetId">
                                    <option value=""></option>
                                    <option *ngFor="let item of DisplayRules" [ngValue]="item.Id">{{item.Name}}</option>
                                </select>
                            </span>
                            <span *ngIf="DisplayRules == null">
                                loading...
                            </span>
                        </app-field>
                        <app-field Label="Brightness (Day)" class="control">
                            <input formControlName="DayBrightness" class="input mb-3" type="number" />
                            <app-validator [For]="this.Form.get('DayBrightness')"></app-validator>
                        </app-field>
                        <app-field Label="Brightness (Night)" class="control">
                            <input formControlName="NightBrightness" class="input mb-3" type="number" />
                            <app-validator [For]="this.Form.get('NightBrightness')"></app-validator>
                        </app-field>
                    </div>
                </div>
            </article>
        </div>
    </div>
</div>