import { ThisReceiver } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
  selector: 'app-editterminaltransition',
  templateUrl: './editterminaltransition.component.html',
  styleUrls: ['./editterminaltransition.component.scss']
})
export class EditterminaltransitionComponent extends SimpleModalComponent<any, any> implements OnInit {

  @Input()
  public Model: any = {};
  public DelaySeconds: number = 0;
  public EventOptions = [
    { Name: "After a Delay", Value: "After" },
    { Name: "After Presence set for", Value: "Presence" },
    { Name: "Transition Immediately", Value: "Immediate" },
    { Name: "Plate Read", Value: "SetSessionPlateNumber" },
    { Name: "Access Granted", Value: "AccessGranted" },
    { Name: "Access Denied", Value: "AccessDenied" },
    { Name: "Barrier Closed", Value: "BarrierClosed" },
    { Name: "Barrier Opened", Value: "BarrierOpened" },
    { Name: "Tailgate Detected", Value: "TailgateDetected" },
    { Name: "Existing Session Loaded", Value: "SessionLoaded" },
    { Name: "Existing Session Not Found", Value: "SessionNotFound" },
    { Name: "Payment Not Required", Value: "PaymentNotRequired" },
    { Name: "Payment Transaction Started", Value: "PaymentStarted" },
    { Name: "Payment Transaction Declined", Value: "PaymentFailed" },
    { Name: "Payment Transaction Success", Value: "PaymentSucceeded" },
    { Name: "Public Access Allowed", Value: "PublicAccessAllowed" },
    { Name: "Public Access Denied", Value: "PublicAccessDenied" },
    { Name: "Code Application Success", Value: "ApplyCodeSuccess" },
    { Name: "Code Application Failed", Value: "ApplyCodeFailed" },
    { Name: "Vehicle left before processing", Value: "UnauthorizedSessionLoaded" },
    { Name: "Custom", Value: "Custom" },
  ];

  public SelectedEvents: Array<any> = [];
  public SelectedEvent : any = {};

  appendSection() {
    this.SelectedEvents.push(this.EventOptions[0].Value);
  }
  removeSection(index: number) {
    this.SelectedEvents.forEach((_, eventIndex) => {
      if (eventIndex == index) this.SelectedEvents.splice(index, 1);
    });
  }

  constructor() {
    super();
  }

  public ngOnInit() {

    let events: string = this.Model.Event;
    let eventArray : string[] = events.split("|");

    for(let evt of eventArray){
      if (evt.indexOf('After') == 0) {
        //this is a delay with configurable seconds, strip off the seconds for editing
        let delaySeconds = parseInt(this.Model.Event.substring(5, evt.length - 1));
        this.SelectedEvents.push({Event: 'After', IsCustom: false, DelaySeconds: delaySeconds});
      } else if (evt.indexOf('Presence') == 0){
        let delaySeconds = parseInt(this.Model.Event.substring(8, evt.length - 1));
        this.SelectedEvents.push({Event: 'Presence', IsCustom: false, DelaySeconds: delaySeconds});
      } else{
        //check if it's a custom one
        let exists = this.EventOptions.filter((x: any) => x.Value == evt).length > 0;
        this.SelectedEvents.push({Event: !exists ? 'Custom' : evt, IsCustom: !exists, CustomEvent: !exists ? evt : '' });
      }
    }
  }

  public AddSelectedEvent(){
    this.SelectedEvents.push({Event: this.SelectedEvent.Value, IsCustom: this.SelectedEvent.Value == "Custom", CustomEvent: "" })
  }
  public DeleteEvent(index : number){
    this.SelectedEvents.splice(index, 1);
  }

  public Confirm() {
    var eventsList: string[] = [];

    for(let e of this.SelectedEvents){
      if (e.Event == "After" || e.Event == "Presence") {
        eventsList.push(e.Event + e.DelaySeconds + "s");
      }
      else if (e.Event == "Custom") {
        eventsList.push(e.CustomEvent);
      }
      else{
        eventsList.push(e.Event);
      }
    }
    this.Model.Event = eventsList.join("|");

    this.result = this.Model;
    this.close();
  }
}