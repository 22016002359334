<app-loader [Parent]="this"></app-loader>
<div *ngIf="Model != null">
    <app-pageheader [Parent]="this">
        <form-edit-button routerLink="edit">Edit</form-edit-button>
    </app-pageheader>

    <app-tabs>
        <app-tab Title="Configuration">
            <div style="margin:0 !important;" class="columns">
                <div class="column is-6">
                    <article class="panel">
                        <div class="panel-block">
                            <div>
                                <app-field Label="Name" class="control flex-center">{{Model.Name}}</app-field>
                                <app-field Label="Location" class="control flex-center">{{Model.LocationString}}</app-field>
                            </div>
                        </div>
                    </article>
                    <article class="panel" *ngIf="Model.GuidanceLightAssignments.length > 0">
                        <p class="panel-heading">Hardware Assignment History</p>
                        <div class="panel-block">
                            <table class="table is-striped" style="width:100% !important">
                                <tr><th>Hardware Chip Id</th><th>Assigned Dates</th></tr>
                                <tr *ngFor="let item of Model.GuidanceLightAssignments">
                                    <td>{{item.ChipId}}</td>
                                    <td>{{item.DateInstalledString}} - {{item.IsActive ? 'Now' : item.DateUninstalledString}}</td>
                                </tr>
                            </table>
                        </div>
                    </article>
                </div>
                <div class="column is-6">
                    <app-structureviewermaps [StructureId]="Model.ParkingLevelId" [SearchTerm]="Model.Id" StructureViewerForType="GuidanceLightConfiguration" [Layers]="StructureViewerLayers" [ShowLevelSelect]="false"></app-structureviewermaps>
                </div>
            </div>
        </app-tab>
        <app-tab Title="Hardware" *ngIf="Model.Device != null">
            <div style="margin:0 !important;" class="columns">
                <div class="column is-6">
                    <article class="panel">
                        <p class="panel-heading">Guidance Light {{HardwareModel.ChipId}}</p>
                        <div class="panel-block">
                            <div>
                                <app-field Label="Chip Id" class="control flex-center">{{HardwareModel.ChipId}}</app-field>
                                <app-field Label="Installed On" class="control flex-center">{{HardwareModel.InstalledOnString}}</app-field>
                                <app-field Label="Status" class="control flex-center">{{HardwareModel.Status}}</app-field>
                                <app-field Label="Version" class="control flex-center">{{HardwareModel.VersionNumber}}</app-field>
                                <app-field Label="Most Recent Check-In" class="control flex-center">{{HardwareModel.LastCheckedInOnString}}</app-field>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </app-tab>
    </app-tabs>
</div>