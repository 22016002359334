<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
  <app-pageheader [Parent]="this">
    <app-form-save-button [Form]="Form" [Parent]="this" (click)="Save()"></app-form-save-button>
  </app-pageheader>
  <div class="columns">
    <div class="column is-6">
        <app-field Label="Id">
            <div class="control">
                <input class="input" [value]="this.Form.get('Id')?.value" disabled/>
            </div>
        </app-field>
        <app-field Label="Email Name">
          <div class="control">
            <input formControlName="EmailName" class="input" type="text" name="EmailName" required [readonly]="this.Form.get('TemplateType')?.value == 'Layout'" />
          </div>
        </app-field>
        <app-field Label="Template Type" *ngIf="this.Form.get('TemplateType')?.value != 'Layout'">
          <div class="control">
            <select class="input minimal" (change)="TypeChanged($event)" formControlName="TemplateType" required>
              <option *ngFor="let i of EmailTypes" [value]="i.EventName" [selected]="i.EventName == this.Form.get('TemplateType')?.value ? true : null">{{i.DisplayName}}</option>
          </select>
          </div>
        </app-field>
        <app-field Label="Subject" *ngIf="this.Form.get('TemplateType')?.value != 'Layout'">
            <div class="control">
                <input formControlName="Subject" class="input" [value]="this.Form.get('Subject')?.value"/>
            </div>
        </app-field>
        <app-field Label="Send From" *ngIf="this.Form.get('TemplateType')?.value != 'Layout'">
          <div class="control">
            <input formControlName="FromAddress" class="input" type="text" name="FromAddress" [value]="this.Form.get('FromAddress')?.value">
          </div>
        </app-field>
        <app-field Label="CC Recipients" *ngIf="this.Form.get('TemplateType')?.value != 'Layout'">
          <div class="control">
            <input formControlName="CCRecipient" class="input" type="text" name="CCRecipient" [value]="this.Form.get('CCRecipient')?.value">
          </div>
        </app-field>
        <app-field Label="Enabled" *ngIf="this.Form.get('TemplateType')?.value != 'Layout'">
          <div class="control">
              <input formControlName="Enabled" class="checkbox" type="checkbox" [checked]="this.Form.get('Enabled')?.value" />
          </div>
        </app-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <app-field Label="Email Body">
          <div class="control">
            <app-codeeditor  Mode="html" *ngIf="this.Body != null" [(Model)]="this.Body" (ModelChange)="BodyChanged($event)" #editor>
            </app-codeeditor>
          </div>
        </app-field>
        <app-field Label="Mobile Body">
          <div class="control">
            <app-codeeditor  Mode="html" [(Model)]="this.MobileNotificationBody" (ModelChange)="MobileBodyChanged($event)" #editor>
            </app-codeeditor>
          </div>
        </app-field>
    </div>
  </div>