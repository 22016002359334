import { NgModule } from '@angular/core';
import { environment } from './../environments/environment';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { OrgswitchComponent } from './auth/orgswitch/orgswitch.component';
import { SessionsComponent } from './features/parking/parking/parkingsessions/sessions.component';
import { EditsessionComponent } from './features/parking/parking/parkingsessions/edit/editsession.component';
import { SpacetypesComponent } from './features/parking/parking/spacetypes/spacetypes.component';
import { PendingChangesGuard } from './shared/guards/pendingchangesguard';
import { PermissionCheckGuard } from './shared/guards/permission-check-guard.guard';
import { ControlledareasComponent } from './features/parking/accesscontrol/controlledareas/controlledareas.component';
import { ControlledAreaComponent } from './features/parking/accesscontrol/controlledareas/details/controlledarea.component';
import { EditcontrolledareaComponent } from './features/parking/accesscontrol/controlledareas/edit/editcontrolledarea.component';
import { FrogRoutes } from './FrogRoute';
import { HomeComponent } from './home/home.component';
import { DeleteNewTestOrgComponent } from './auth/deletenewtestorg/deletenewtestorg.component';
import { NewTestOrgComponent } from './auth/newtestorg/newtestorg.component';
import { ChangePasswordComponent } from './features/myaccount/changepassword/changepassword.component';
import { MyAccountComponent } from './features/myaccount/myaccount.component';
import { EditplateaccesslistComponent } from './features/parking/accesscontrol/accesslists/edit/editplateaccesslist.component';
import { PlateaccesslistsComponent } from './features/parking/accesscontrol/accesslists/plateaccesslists.component';
import { RevenueOverviewComponent } from './features/revenue/revenueoverview.component';
import { EditterminalconfigurationComponent } from './features/parking/accesscontrol/terminalconfigurations/edit/editterminalconfiguration.component';
import { TerminalconfigurationsComponent } from './features/parking/accesscontrol/terminalconfigurations/terminalconfigurations.component';
import { TerminalComponent } from './features/parking/accesscontrol/terminals/details/terminal.component';
import { EditterminalComponent } from './features/parking/accesscontrol/terminals/edit/editterminal.component';
import { TerminalsComponent } from './features/parking/accesscontrol/terminals/terminals.component';
//import { EditGatewayComponent } from './features/parking/guidance/gateways/edit/editgateway.component';
//import { GatewaysComponent } from './features/parking/guidance/gateways/gateways.component';
import { LprsearchComponent } from './features/parking/guidance/lprsearch/lprsearch.component';
import { CarcounterlistComponent } from './features/parking/guidance/sensors/carcounters/carcounterlist.component';
import { EditcarcounterComponent } from './features/parking/guidance/sensors/carcounters/edit/editcarcounter.component';
import { EditIRSensorComponent } from './features/parking/guidance/sensors/irsensors/edit/editIRsensor.component';
import { IRSensorsListComponent } from './features/parking/guidance/sensors/irsensors/IRsensorslist.component';
import { EditlegacysignComponent } from './features/parking/guidance/signs/legacysigns/edit/editlegacysign.component';
import { LegacysignsComponent } from './features/parking/guidance/signs/legacysigns/legacysigns.component';
import { OperatorConsoleComponent } from './features/operatorconsole/operatorconsole.component';
import { CustomgroupsComponent } from './features/parking/parking/customgroups/customgroups.component';
import { CustomgroupComponent } from './features/parking/parking/customgroups/details/customgroup.component';
import { EditcustomgroupComponent } from './features/parking/parking/customgroups/edit/editcustomgroup.component';
import { ParkinglevelComponent } from './features/parking/parking/parkinglevels/details/parkinglevel.component';
import { EditparkinglevelDesignComponent } from './features/parking/parking/parkinglevels/edit/designer/editparkingleveldesign.component';
import { EditparkinglevelComponent } from './features/parking/parking/parkinglevels/edit/editparkinglevel.component';
import { ParkingLotComponent } from './features/parking/parking/parkinglots/details/parkinglot.component';
import { EditparkinglotComponent } from './features/parking/parking/parkinglots/edit/editparkinglot.component';
import { ParkinglotsComponent } from './features/parking/parking/parkinglots/parkinglots.component';
import { SpacetypeComponent } from './features/parking/parking/spacetypes/details/spacetype.component';
import { EditspacetypeComponent } from './features/parking/parking/spacetypes/edit/editspacetype.component';
import { EditthresholdsetComponent } from './features/parking/parking/thresholdsets/edit/editthresholdset.component';
import { ThresholdsetsComponent } from './features/parking/parking/thresholdsets/thresholdsets.component';
import { ParkinghomeComponent } from './features/parking/parkinghome.component';
import { ParkingPermitComponent } from './features/permits/permits/details/parkingpermit.component';
import { EditParkingPermitComponent } from './features/permits/permits/edit/editparkingpermit.component';
import { ParkingPermitsComponent } from './features/permits/permits/parkingpermits.component';
import { PermittypeComponent } from './features/permits/permittypes/details/permittype.component';
import { EditpermittypeComponent } from './features/permits/permittypes/edit/editpermittype.component';
import { PermittypesComponent } from './features/permits/permittypes/permittypes.component';
import { EditpriceruleComponent } from './features/settings/rates/pricerules/edit/editpricerule.component';
import { PricerulesComponent } from './features/settings/rates/pricerules/pricerules.component';
import { EditratesetComponent } from './features/settings/rates/ratesets/edit/editrateset.component';
import { RatesetsComponent } from './features/settings/rates/ratesets/ratesets.component';
import { ReportslistComponent } from './features/reporting/reportslist.component';
import { RunreportComponent } from './features/reporting/run/runreport.component';
import { AlerttriggersComponent } from './features/settings/alerts/triggers/alerttriggers.component';
import { EditalerttriggerComponent } from './features/settings/alerts/triggers/edit/editalerttrigger.component';
import { EditEmailComponent } from './features/settings/emailtemplates/edit/editemail.component';
import { EmailsComponent } from './features/settings/emailtemplates/emails.component';
import { PlatematchcharactersComponent } from './features/settings/parking/platematchcharacters/platematchcharacters.component';
import { VehicledetectionsettingsComponent } from './features/settings/parking/vehicledetectionsettings/vehicledetectionsettings.component';
import { SettingshomeComponent } from './features/settings/settingshome.component';
import { UserHomeComponent } from './features/users/usershome.component';
import { RoleComponent } from './features/users/roles/details/role.component';
import { EditRoleComponent } from './features/users/roles/edit/editrole.component';
import { RolesComponent } from './features/users/roles/roles.component';
import { UserComponent } from './features/users/users/details/user.component';
import { EdituserComponent } from './features/users/users/edit/edituser.component';
import { UsersComponent } from './features/users/users/users.component';
import { LeftnavlayoutComponent } from './shared/layout/leftnavlayout/leftnavlayout.component';
import { CalendarDayTypesComponent } from './features/settings/calendar/daytypes/calendardaytypes.component';
import { CalendarDayTypeComponent } from './features/settings/calendar/daytypes/calendardaytype/calendardaytype.component';
import { EditCalendarDayType } from './features/settings/calendar/daytypes/editcalendardaytype/editcalendardaytype.component';
import { CalendarComponent } from './features/settings/calendar/calendar.component';
//import { GatewayComponent } from './features/parking/guidance/gateways/details/gateway.component';
import { EditclusterdesignComponent } from './features/settings/clusterdesigns/edit/editclusterdesign.component';
import { ClusterdesignerComponent } from './features/settings/clusterdesigns/edit/clusterdesigner/clusterdesigner.component';
import { ClusterDesignsComponent } from './features/settings/clusterdesigns/clusterdesigns.component';
import { EditGatewayConfigurationComponent } from './features/parking/parking/parkinglevels/modals/editgatewayconfiguration/editgatewayconfiguration.component';
import { PermitshomeComponent } from './features/permits/permitshome.component';
import { HardwarehomeComponent } from './features/hardware/hardwarehome/hardwarehome.component';
import { SignsComponent } from './features/hardware/devices/signs/signs.component';
import { GatewaysComponent } from './features/hardware/devices/gateways/gateways.component';
import { SignComponent } from './features/hardware/devices/signs/details/sign.component';
import { EditsignComponent } from './features/hardware/devices/signs/edit/editsign.component';
import { SigndisplayrulesetsComponent } from './features/hardware/configuration/signdisplayrulesets/signdisplayrulesets.component';
import { SigndisplayrulesetComponent } from './features/hardware/configuration/signdisplayrulesets/details/signdisplayruleset.component';
import { EditsigndisplayrulesetComponent } from './features/hardware/configuration/signdisplayrulesets/edit/editsigndisplayruleset.component';
import { DiscountTypesComponent } from './features/parking/pricemodifiers/discountcodes/discounttypes/discounttypes.component';
import { DiscountTypeComponent } from './features/parking/pricemodifiers/discountcodes/discounttypes/details/discounttype.component';
import { EditDiscountTypeComponent } from './features/parking/pricemodifiers/discountcodes/discounttypes/edit/editdiscounttype.component';
import { ValidationprofilesComponent } from './features/parking/pricemodifiers/validationprofiles/validationprofiles.component';
import { ValidationprofileComponent } from './features/parking/pricemodifiers/validationprofiles/details/validationprofile.component';
import { EditvalidationprofileComponent } from './features/parking/pricemodifiers/validationprofiles/edit/editvalidationprofile.component';
import { EditValidationUserComponent } from './features/parking/pricemodifiers/validationprofiles/validationprofileusers/edit/editvalidationuser.component';
import { ValidationProfileUserComponent } from './features/parking/pricemodifiers/validationprofiles/validationprofileusers/details/validationprofileuser.component';
import { ValetHomeComponent } from './features/valet/valethome.component';
import { ValetSessionsComponent } from './features/valet/valetsessions/valetsessions.component';
import { ValetTypesComponent } from './features/valet/types/valettypes.component';
import { ValetTypeComponent } from './features/valet/types/details/valettype.component';
import { EditValetTypeComponent } from './features/valet/types/edit/editvalettype.component';
import { ValetSessionComponent } from './features/valet/valetsessions/details/valetsession.component';
import { EditValetSessionComponent } from './features/valet/valetsessions/edit/editvaletsession.component';
import { ValetAddonsComponent } from './features/valet/addons/valetaddons.component';
import { ValetAddonComponent } from './features/valet/addons/details/valetaddon.component';
import { EditValetAddonComponent } from './features/valet/addons/edit/editvaletaddon.component';
import { GatewayComponent } from './features/hardware/devices/gateways/details/gateway.component';
import { EditGatewayComponent } from './features/hardware/devices/gateways/edit/editgateway.component';
import { GuidanceLightsComponent } from './features/hardware/devices/guidancelights/guidancelights.component';
import { EditGuidanceLightComponent } from './features/hardware/devices/guidancelights/edit/editguidancelight.component';
import { GuidanceLightComponent } from './features/hardware/devices/guidancelights/details/guidancelight.component';
import { CarcountersComponent } from './features/hardware/devices/carcounters/carcounters.component';
import { CarCounterComponent } from './features/hardware/devices/carcounters/details/carcounter.component';
import { EditCarCounterComponent } from './features/hardware/devices/carcounters/edit/editcarcounter.component';
import { TransactionsComponent } from './features/revenue/transactions/transactions.component';
import { TransactionComponent } from './features/revenue/transactions/details/transaction.component';
import { ReconciliationToolComponent } from './features/revenue/reconciliation/reconciliationtool/reconciliationtool.component';
import { DuplicatePaymentRecordsComponent } from './features/revenue/reconciliation/duplicatepaymentrecords/duplicatepaymentrecords.component';
import { StatementsComponent } from './features/revenue/reconciliation/statements/statements.component';
import { StatementComponent } from './features/revenue/reconciliation/statements/details/statement.component';
import { DocumentTemplatesComponent } from './features/settings/documenttemplates/documenttemplates.component';
import { EditDocumentTemplate } from './features/settings/documenttemplates/edit/editdocumenttemplate.component';

const routes: FrogRoutes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'switchorganizations/:id', component: OrgswitchComponent },
  {
    path: 'settings', component: LeftnavlayoutComponent,
    children: [
      { path: '', component: SettingshomeComponent },
      // Rates
      {
        path: 'rates', breadcrumbDisabled: true,
        children: [
          // Price Rules
          {
            path: 'pricerules',
            children: [
              { path: '', component: PricerulesComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'RatesFeature' } },
              {
                path: ':id', breadcrumbText: "{Name}", breadcrumbDisabled: true,
                children: [
                  { path: 'edit', breadcrumbText: "Edit", component: EditpriceruleComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { 'permission': 'RatesFeature' } },
                ]
              }
            ]
          },

          // Price Rates
          {
            path: 'ratesets',
            children: [
              { path: '', component: RatesetsComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'RatesFeature' } },
              {
                path: ':id', breadcrumbText: "{Name}", breadcrumbDisabled: true,
                children: [
                  { path: 'edit', breadcrumbText: "Edit", component: EditratesetComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { 'permission': 'RatesFeature' } },
                ]
              }
            ]
          },
        ]
      },
      {
        path: 'notificationtemplates', children: [
          { path: '', breadcrumbText: "{Name|'New'}", component: EmailsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'EmailTemplatesFeature' } },
          {
            path: ':id', breadcrumbText: "{EmailName|'New'}", children: [
              { path: '', breadcrumbText: "{EmailName|'New'}", component: EditEmailComponent, canActivate: [PermissionCheckGuard], data: { permission: 'EmailTemplatesFeature' } },
              { path: 'edit', breadcrumbText: "Edit", component: EditEmailComponent, canActivate: [PermissionCheckGuard], data: { permission: 'EmailTemplatesFeature' } }
            ]
          },
        ]
      },
      // Organization Calendar
      {
        path: 'calendar', children: [
          { path: '', component: CalendarComponent },
          {
            path: 'daytypes', children: [
              { path: '', component: CalendarDayTypesComponent },
              {
                path: ':id', breadcrumbText: "{Name}", children: [
                  { path: '', breadcrumbText: "{Name}", component: CalendarDayTypeComponent, canActivate: [PermissionCheckGuard], data: { permission: 'CalendarFeature' } },
                  { path: 'edit', breadcrumbText: "Edit", component: EditCalendarDayType, canDeactivate: [PendingChangesGuard], entityType: "CalendarDayType", canActivate: [PermissionCheckGuard], data: { permission: 'CalendarFeature' } },
                ]
              },
            ]
          },
        ]
      },
      {
        path: 'alerttriggers',
        children: [
          { path: '', component: AlerttriggersComponent, canActivate: [PermissionCheckGuard], data: { permission: 'AlertTriggersFeature' } },
          {
            path: ':id', breadcrumbText: "{EventName|'New'}", data: { EditPageOnly: true },
            children: [
              { path: 'edit', breadcrumbText: "Edit", component: EditalerttriggerComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { permission: 'AlertTriggersFeature' } },
            ]
          }
        ]
      },
      {
        path: 'notificationtemplates',
        children: [
          { path: '', component: EmailsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'EmailTemplatesFeature' } },
          {
            path: ':id', data: { EditPageOnly: true },
            children: [
              { path: 'edit', component: EditEmailComponent, canActivate: [PermissionCheckGuard], data: { permission: 'EmailTemplatesFeature' } }
            ]
          }
        ]
      },
      {
        path: 'documenttemplates',
        children: [
          { path: '', component: DocumentTemplatesComponent, canActivate: [PermissionCheckGuard], data: { permission: 'DocumentTemplatesFeature' } },
          {
            path: ':id', data: { EditPageOnly: true },
            children: [
              { path: 'edit', component: EditDocumentTemplate, canActivate: [PermissionCheckGuard], data: { permission: 'DocumentTemplatesFeature' } }
            ]
          }
        ]
      },
      {
        path: 'lpr',
        children: [
          { path: 'vehicledetectionsettings', component: VehicledetectionsettingsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ParkingFeature', requiredLevel: 500 } },
          { path: 'charactermatrix', component: PlatematchcharactersComponent, canDeactivate: [PendingChangesGuard], data: { permission: 'AccessControlLPRFeature', requiredLevel: 500 } },
        ]
      },
      {
        path: 'clusterdesigns',
        children: [
          { path: '', component: ClusterDesignsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'MobileFeature' } },
          {
            path: ':id', breadcrumbText: "{Name}", breadcrumbDisabled: true,
            children: [
              // { path: '', component: ClusterdesignerComponent, canActivate: [PermissionCheckGuard], data: { permission: 'MobileFeature' }, },
              { path: 'edit', breadcrumbText: "Edit", component: EditclusterdesignComponent, canActivate: [PermissionCheckGuard], data: { permission: 'MobileFeature' } }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'account', component: LeftnavlayoutComponent,
    children: [
      { path: '', component: MyAccountComponent },
      { path: 'changepassword', component: ChangePasswordComponent }
    ]
  },
  {
    path: 'valet', component: LeftnavlayoutComponent,
    children: [
      { path: '', component: ValetHomeComponent },
      { path: 'types',
        children: [
        { path: '', component: ValetTypesComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ValetFeature' }},
        {
          path: ':id', breadcrumbText: "{Name|'New'}",
          children: [
            { path: '', component: ValetTypeComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ValetFeature' } },
            { path: 'edit', breadcrumbText: "Edit", component: EditValetTypeComponent, canDeactivate: [PendingChangesGuard], entityType: "ValetType", canActivate: [PermissionCheckGuard], data: { permission: 'ValetFeature' } },
          ]
        }
        ]
      },
      { path: 'sessions',
        children: [
        { path: '', component: ValetSessionsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ValetFeature' }},
        {
          path: ':id', breadcrumbText: "{Id|'New'}",
          children: [
            { path: '', component: ValetSessionComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ValetFeature' } },
            { path: 'edit', breadcrumbText: "Edit", component: EditValetSessionComponent, canDeactivate: [PendingChangesGuard], entityType: "ValetSession", canActivate: [PermissionCheckGuard], data: { permission: 'ValetFeature' } },
          ]
        }
        ]
      },
      { path: 'addons',
        children: [
        { path: '', component: ValetAddonsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ValetFeature' }},
        {
          path: ':id', breadcrumbText: "{Id|'New'}",
          children: [
            { path: '', component: ValetAddonComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ValetFeature' } },
            { path: 'edit', breadcrumbText: "Edit", component: EditValetAddonComponent, canDeactivate: [PendingChangesGuard], entityType: "ValetAddon", canActivate: [PermissionCheckGuard], data: { permission: 'ValetFeature' } },
          ]
        }
        ]
      }
    ]
  },
  {
    path: 'parking', component: LeftnavlayoutComponent,
    children: [
      { path: '', component: ParkinghomeComponent },
      {
        // Access Control
        path: 'accesscontrol', breadcrumbDisabled: true,
        children: [
          // Controlled Area
          {
            path: 'controlledareas',
            children: [
              { path: '', component: ControlledareasComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ControlledAreasFeature' } },
              {
                path: ':id', breadcrumbText: "{Name}",
                children: [
                  { path: '', component: ControlledAreaComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ControlledAreasFeature' }, },
                  { path: 'edit', breadcrumbText: "Edit", component: EditcontrolledareaComponent, canDeactivate: [PendingChangesGuard], entityType: "ControlledArea", canActivate: [PermissionCheckGuard], data: { permission: 'ControlledAreasFeature' } },
                ]
              },
            ]
          },
          // Terminals
          {
            path: 'terminals',
            children: [
              { path: '', component: TerminalsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'TerminalsFeature' } },
              {
                path: ':id', breadcrumbText: "{Name}",
                children: [
                  { path: '', breadcrumbText: "{Name}", component: TerminalComponent, canActivate: [PermissionCheckGuard], data: { permission: 'TerminalsFeature' } },
                  { path: 'edit', breadcrumbText: "Edit", component: EditterminalComponent, canDeactivate: [PendingChangesGuard], entityType: "LocalController", canActivate: [PermissionCheckGuard], data: { permission: 'TerminalsFeature' } },
                ]
              },
            ]
          },

          // Terminal Configurations
          {
            path: 'terminalconfigurations',
            children: [
              { path: '', component: TerminalconfigurationsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'TerminalsFeature' } },
              {
                path: ':id', breadcrumbText: "{Name}",
                children: [
                  { path: '', breadcrumbText: "{Name}", component: TerminalconfigurationsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'TerminalsFeature' } },
                  { path: 'edit', breadcrumbText: "Edit", component: EditterminalconfigurationComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { permission: 'TerminalsFeature' } },
                ]
              },
            ]
          },

          // Access Lists
          {
            path: 'accesslists',
            children: [
              { path: '', component: PlateaccesslistsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'PlateAccessFeature' } },
              {
                path: ':listid', breadcrumbText: "{PlateAccessListName|Name|'Plates'}",
                children: [
                  { path: '', breadcrumbText: "{PlateAccessListName|Name|'New'}", component: EditplateaccesslistComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { permission: 'PlateAccessFeature' }, },
                ]
              },
            ]
          },

          // Custom groups
          {
            path: 'customgroups',
            children: [
              { path: '', component: CustomgroupsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'CustomGroupsFeature' } },
              {
                path: ':id', breadcrumbText: "{Name}",
                children: [
                  { path: '', breadcrumbText: "{Name}", component: CustomgroupComponent, canActivate: [PermissionCheckGuard], data: { permission: 'CustomGroupsFeature' } },
                  { path: 'edit', breadcrumbText: "Edit", component: EditcustomgroupComponent, canDeactivate: [PendingChangesGuard], entityType: "CustomGroup", canActivate: [PermissionCheckGuard], data: { permission: 'CustomGroupsFeature' } },
                ]
              },
            ]
          },
        ]
      },
      // Guidance
      {
        path: 'guidance', breadcrumbDisabled: true,
        children: [
          // LPR Search
          { path: 'lprsearch', component: LprsearchComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'LPRSearchFeature' } },
          //sensors

          {
            path: 'sensors',
            children: [
              { path: '', breadcrumbText: "{ChipId}", component: IRSensorsListComponent, canActivate: [PermissionCheckGuard], data: { permission: 'LegacySensorsFeature' } },
              {
                path: ':id', breadcrumbText: "{ChipId}", breadcrumbDisabled: true,
                children: [
                  { path: 'edit', breadcrumbText: "Edit", component: EditIRSensorComponent, canDeactivate: [PendingChangesGuard], entityType: "IRSensor", canActivate: [PermissionCheckGuard], data: { permission: 'LegacySensorsFeature' } },
                ]
              }
            ]
          },

          // Car Counter
          {
            path: 'carcounters',
            children: [
              { path: '', breadcrumbText: "{ChipId}", component: CarcounterlistComponent, canActivate: [PermissionCheckGuard], data: { permission: 'LegacyCarCountersFeature' } },
              {
                path: ':id', breadcrumbText: "{ChipId}", breadcrumbDisabled: true,
                children: [
                  { path: 'edit', breadcrumbText: "Edit", component: EditcarcounterComponent, canDeactivate: [PendingChangesGuard], entityType: "CarCounter", canActivate: [PermissionCheckGuard], data: { permission: 'LegacyCarCountersFeature' } },
                ]
              }
            ]
          },

          // Legacy Signs
          {
            path: 'signs',
            children: [
              { path: '', component: LegacysignsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'SignsFeature' } },
              {
                path: ':id', breadcrumbText: "{ChipId}", breadcrumbDisabled: true,
                children: [
                  { path: 'edit', breadcrumbText: "Edit", component: EditlegacysignComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { permission: 'SignsFeature' } },
                ]
              }
            ]
          }
        ]
      },
      // Parking 
      {
        path: 'parking', breadcrumbDisabled: true,
        children: [
          // Parking lots

          {
            path: 'lots',
            children: [
              { path: '', component: ParkinglotsComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'ParkingFeature' } },
              {
                path: ':lotid', breadcrumbText: "{ParkingLotName|Name}",
                children: [
                  { path: '', breadcrumbText: "{Name}", component: ParkingLotComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ParkingFeature' }, },
                  { path: 'edit', breadcrumbText: "Edit", component: EditparkinglotComponent, canDeactivate: [PendingChangesGuard], entityType: "ParkingLot", canActivate: [PermissionCheckGuard], data: { permission: 'ParkingFeature' } },
                  {
                    path: 'levels',
                    children: [
                      {
                        path: ':id', breadcrumbText: "{Name}",
                        children: [
                          { path: '', breadcrumbText: "{Name}", component: ParkinglevelComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { 'permission': 'ParkingFeature' }, },
                          { path: 'design', breadcrumbDisabled: true, breadcrumbText: "Design",
                            children: [
                              { path: "edit", breadcrumbDisabled: true, breadcrumbText: "Edit", component: EditparkinglevelDesignComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { 'permission': 'ParkingFeature' } }
                            ]
                          },
                          { path: 'edit', breadcrumbText: "Edit", component: EditparkinglevelComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { 'permission': 'ParkingFeature' } },
                        ]
                      },
                    ]
                  }
                ]
              },
            ]
          },

          // Sessions
          {
            path: 'sessions',
            children: [
              { path: '', component: SessionsComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'ParkingFeature' } },
              { path: ':id', breadcrumbText: "{Id}", component: EditsessionComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'ParkingFeature' } },
            ]
          },

          // Threshold
          {
            path: 'thresholdsets',
            children: [
              { path: '', breadcrumbText: "{Name}", component: ThresholdsetsComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'ParkingFeature' } },
              {
                path: ':id', breadcrumbText: "{Name}", breadcrumbDisabled: true,
                children: [
                  { path: 'edit', breadcrumbText: "Edit", component: EditthresholdsetComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { 'permission': 'ParkingFeature' } },
                ]
              }
            ]
          },

          // Space Types
          {
            path: 'spacetypes',
            children: [
              { path: '', component: SpacetypesComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'ParkingFeature' } },
              {
                path: ':id', breadcrumbText: "{Name}", breadcrumbDisabled: true,
                children: [
                  //{ path: '', breadcrumbText: "{Name}", component: SpacetypeComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'ParkingFeature' } },
                  { path: 'edit', breadcrumbText: "Edit", component: EditspacetypeComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { 'permission': 'ParkingFeature' } },
                ]
              },
            ]
          },
        ]
      },
      // Coupons/Discounts
      {
        path: 'discounts', breadcrumbDisabled: true,
        children: [
          {
            path: 'codes',
            children: [
              { path: '', component: DiscountTypesComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'DiscountCodesFeature' } },
              {
                path: ':id', breadcrumbText: "{Name}",
                children: [
                  { path: '', component: DiscountTypeComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'DiscountCodesFeature' } },
                  { path: 'edit', breadcrumbText: "Edit", component: EditDiscountTypeComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'DiscountCodesFeature' } },
                ]
              },
            ]
          }
        ]
      },
      {
        path: 'validation', breadcrumbDisabled: true,
        children: [
          {
            path: 'profiles',
            children: [
              { path: '', component: ValidationprofilesComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'ValidationFeature' } },
              {
                path:':listid', breadcrumbText: "{ValidationProfileName|Name}",
                children: [
                  { path: '', component: ValidationprofileComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'ValidationFeature'} },
                  { path: 'edit', breadcrumbText: "Edit", component: EditvalidationprofileComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'ValidationFeature' } },
                  {
                    path: 'users',
                    children: [
                      {
                        path: ':id', breadcrumbText: "{Name|'New'}",
                        children: [
                          { path: '', breadcrumbText: "{Id}", component: ValidationProfileUserComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'ValidationFeature' } },
                          { path: 'edit', breadcrumbText: "Edit", entityType: "Validation User", component: EditValidationUserComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { 'permission': 'ValidationFeature' } },
                        ]
                      },
                    ]
                  },
                ]
              }
            ]
          }
        ]
      },
    ],
  },
  {
    path: 'permits', component: LeftnavlayoutComponent,
    children: [
      {
        path: 'permittypes',
        children: [
          { path: '', breadcrumbText: "{Id}", component: PermittypesComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'PermitsFeature' } },
          {
            path: ':id', breadcrumbText: "{Name|Id}",
            children: [
              { path: '', breadcrumbText: "{Id}", component: PermittypeComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'PermitsFeature' } },
              { path: 'edit', breadcrumbText: "Edit", entityType: "Permit Type", component: EditpermittypeComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { 'permission': 'PermitsFeature' } },
            ]
          }
        ]
      },
      {
        path: 'parkingpermits',
        children: [
          { path: '', component: ParkingPermitsComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'PermitsFeature' } },
          {
            path: ':id', breadcrumbText: "{Name|Id|'New Permit'}",
            children: [
              { path: '', breadcrumbText: "{Id}", component: ParkingPermitComponent, canActivate: [PermissionCheckGuard], data: { 'permission': 'PermitsFeature' } },
              { path: 'edit', breadcrumbText: "Edit", entityType: "Permit", component: EditParkingPermitComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { 'permission': 'PermitsFeature' } },
            ]
          }
        ]
      },
      { path: '', component: PermitshomeComponent },
    ]
  },
  {
    path: 'operatorconsole', breadcrumbDisabled: true,
    children: [
      { path: '', component: OperatorConsoleComponent, canActivate: [PermissionCheckGuard], data: { permission: 'OperatorFeature' } },
      { path: ':id', component: OperatorConsoleComponent, canActivate: [PermissionCheckGuard], data: { permission: 'OperatorFeature' } },
    ]
  },
  {
    path: 'reports',
    children: [
      { path: '', component: ReportslistComponent },
      { path: 'run/:id', component: RunreportComponent },
    ]
  },
  {
    path: 'usermanagement', component: LeftnavlayoutComponent,
    children: [
      { path: '', component: UserHomeComponent },
      {
        path: 'users', children: [
          { path: '', component: UsersComponent },
          {
            path: ':id', breadcrumbText: "{Email|'New'}",
            children: [
              { path: '', breadcrumbText: "{Email|'New'}", component: UserComponent, data: { permission: 'UserManagementFeature' }, },
              { path: 'edit', breadcrumbText: "Edit", component: EdituserComponent, canDeactivate: [PendingChangesGuard], data: { permission: 'UserManagementFeature' } }
            ]
          },
        ]
      },
      {
        path: 'roles', children: [
          { path: '', component: RolesComponent },
          {
            path: ':id', breadcrumbText: "{Name}",
            children: [
              { path: '', breadcrumbText: "{Name}", component: RoleComponent, canActivate: [PermissionCheckGuard], data: { permission: 'UserManagementFeature' } },
              { path: 'edit', breadcrumbText: "Edit", component: EditRoleComponent, canDeactivate: [PendingChangesGuard], entityType: "Role", canActivate: [PermissionCheckGuard], data: { permission: 'UserManagementFeature' } },
            ]
          }
        ]
      },
    ]
  },
  {
    path: 'hardware', component: LeftnavlayoutComponent,
    children: [
      { path: '', component: HardwarehomeComponent },
      {
        path: 'devices', breadcrumbDisabled: true, children: [
          {
            path: 'signs', children: [
              { path: '', component: SignsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'SignsFeature' } },
              {
                path: ':id', breadcrumbText: "{Id}", children: [
                  { path: '', breadcrumbText: "{Id}", component: SignComponent, canActivate: [PermissionCheckGuard], data: { permission: 'SignsFeature' } },
                  { path: 'edit', breadcrumbText: "Edit", component: EditsignComponent, canDeactivate: [PendingChangesGuard], entityType: "SignBase", canActivate: [PermissionCheckGuard], data: { permission: 'SignsFeature' } }
                ]
              }
            ]
          },
          {
            path: 'gateways',
            children: [
              { path: '', component: GatewaysComponent, canActivate: [PermissionCheckGuard], data: { permission: 'GatewaysFeature' } },
              {
                path: ':id', breadcrumbText: "{Id}", data: { EditPageOnly: false },
                children: [
                  { path: '', breadcrumbText: "{Id}", component: GatewayComponent, canActivate: [PermissionCheckGuard], data: { permission: 'GatewaysFeature' }, },
                  { path: 'edit', breadcrumbText: "Edit", component: EditGatewayComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { permission: 'GatewaysFeature' } },
                ]
              }
            ]
           },
           {
            path: 'guidancelights',
            children: [
              { path: '', component: GuidanceLightsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'GuidanceLightsFeature' } },
              {
                path: ':id', breadcrumbText: "{Id}", data: { EditPageOnly: false },
                children: [
                  { path: '', breadcrumbText: "{Id}", component: GuidanceLightComponent, canActivate: [PermissionCheckGuard], data: { permission: 'GuidanceLightsFeature' }, },
                  { path: 'edit', breadcrumbText: "Edit", component: EditGuidanceLightComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { permission: 'GuidanceLightsFeature' } },
                ]
              }
            ]
           },
           {
            path: 'carcounters',
            children: [
              { path: '', component: CarcountersComponent, canActivate: [PermissionCheckGuard], data: { permission: 'CarCountersFeature' } },
              {
                path: ':id', breadcrumbText: "{Id}", data: { EditPageOnly: false },
                children: [
                  { path: '', breadcrumbText: "{Id}", component: CarCounterComponent, canActivate: [PermissionCheckGuard], data: { permission: 'CarCountersFeature' }, },
                  { path: 'edit', breadcrumbText: "Edit", component: EditCarCounterComponent, canDeactivate: [PendingChangesGuard], canActivate: [PermissionCheckGuard], data: { permission: 'CarCountersFeature' } },
                ]
              }
            ]
           },
        ]
      },
      {
        path: 'configuration', breadcrumbDisabled: true, children: [
          {
            path: 'signdisplayrulesets', children: [
              { path: '', component: SigndisplayrulesetsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'SignsFeature' } },
              {
                path: ':id', breadcrumbText: "{Id}", children: [
                  { path: '', breadcrumbText: "{Id}", component: SigndisplayrulesetComponent, canActivate: [PermissionCheckGuard], data: { permission: 'SignsFeature' } },
                  { path: 'edit', breadcrumbText: "Edit", component: EditsigndisplayrulesetComponent, canDeactivate: [PendingChangesGuard], entityType: "SignDisplayRuleSet", canActivate: [PermissionCheckGuard], data: { permission: 'SignsFeature' } }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'revenue', component: LeftnavlayoutComponent, breadcrumbText: "Revenue",
    children: [
      { path: '', component: RevenueOverviewComponent, canActivate: [PermissionCheckGuard], data: { permission: 'RevenueFeature'}},
      { path: 'transactions', 
        children: [
          { path: '', breadcrumbDisabled: true, component: TransactionsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ManageTransactionsFeature'}},
          { path: ':id', breadcrumbText: "View", breadcrumbDisabled: true, component: TransactionComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ManageTransactionsFeature'}}
        ]
      },
      { path: 'reconciliation', breadcrumbDisabled: true,
        children: [
          { path: 'statements', 
            children: [
              { path: '', component: StatementsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ReconciliationToolFeature'} },
              { path: ':id', breadcrumbText: "View Statement" , component: StatementComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ReconciliationToolFeature'}} ,
            ]
          },
          { path: 'reconcile', component: ReconciliationToolComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ReconciliationToolFeature', requiredLevel: 500},},
          { path: 'duplicates', component: DuplicatePaymentRecordsComponent, canActivate: [PermissionCheckGuard], data: { permission: 'ReconciliationToolFeature', requiredLevel: 500},},
        ]
      },
    ]
  },
  environment.production ? { path: 'newtestorg', redirectTo: "/" } : { path: 'newtestorg', component: NewTestOrgComponent },
  environment.production ? { path: 'deletenewtestorg', redirectTo: "/" } : { path: 'deletenewtestorg', component: DeleteNewTestOrgComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
