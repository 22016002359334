import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ContentChild, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { catchError, empty, ObservableInput, throwError } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Deviceicons } from 'src/app/util/deviceicons';
import { SimpleModalService } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { LocalStorageService } from 'src/app/Services/local-storage.service';
import { AlertsService } from 'src/app/Services/alerts.service';
import { Busyable } from '../editors/busyable';
import { Datalistviewcolumn } from './columns/datalistviewcolumn';
import { Datalistrequestfilter } from './request/datalistrequestfilter';
import { FilterSetComponent } from './filters/filter-set/filter-set.component';
import { StructureViewerLayer } from '../structureviewermaps/structureviewerlayers';
import { StructureViewerMapsComponent } from '../structureviewermaps/structureviewermaps.component';


@Component({
  selector: 'app-datalistview',
  templateUrl: './datalistview.component.html',
  styleUrls: ['./datalistview.component.scss']
})
export class DatalistviewComponent extends Busyable implements OnInit, AfterContentInit {
  @Input()
  public Columns: Array<Datalistviewcolumn> = [];

  @Input()
  public AllowEdit: boolean = false;

  @Input()
  public StructureViewerLayers: StructureViewerLayer[] = []
  @Input()
  public EditUrl: string = "";
  @Input()
  public EditUrlPerItem: boolean = false;
  @Input()
  public HighlightOnSelect: boolean = true;
  @Input()
  public AllowCustomAction: boolean = false;
  @Input()
  public CustomAction: Function | undefined;
  @Input()
  public CustomActionText: string = "View";
  @Input() 
  public CustomActionIcon: string = "plus";
  @Output()
  public CustomActionEvent = new EventEmitter<any>();
  @Input()
  public CustomActionConditionalField: string = "";
  @Input()
  public CustomActionConditionalValue: any = "";
  @Input()
  public CustomActionConditionalComparator: string = "==";
  @Input()
  public AllowDropdownActions: boolean = false;
  @Input()
  public UseActionsOnEachItem: boolean = false;
  @Output()
  public HandleDropdownAction : EventEmitter<any> = new EventEmitter(); 
  @Input()
  public DropdownActions: { text: string, action: Function, shouldshow: Function }[] | undefined;
  @Input()
  public DeleteAction: Function | undefined;
  @Input()
  public ApiUrl: string = "";
  @Input()
  public ShowMap: boolean = false;
  @Input()
  public HideSearch: boolean = false;
  @Input()
  public ShowIssues: boolean = false;
  @Input()
  public ShowColumnHeaders: boolean = true;
  @Input()
  public AllowSelect: boolean = false;
  @Input()
  public PageSize: number | null = null;
  @Input()
  public ShowPaging: boolean = true;
  @Input()
  public AllowSelectBoxes : boolean = false;
  @Input()
  public AllowDelete : boolean = false;
  @Output()
  public SelectBoxChanged = new EventEmitter<any>();
  @Input()
  public SelectedItems: any[] = [];
  @Input()
  public DisableIfSelected: boolean = false;
  @Input()
  public set Filters(val: Array<Datalistrequestfilter>) {
    this.Filter.Filters = val;
  }
  @Input()
  public PreLoad: boolean = true;
  @Input()
  public HighLightRowColumn: string = "";
  @Input()
  public HighLightCondition: string[] = [];
  @Output()
  public AddingNew = new EventEmitter<any>();
  @Output()
  public Editing = new EventEmitter<any>();

  public MapCenter: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  @ViewChild(GoogleMap)
  public Map!: GoogleMap;

  @ViewChild("StructureViewer")
  public StructureViewer!: StructureViewerMapsComponent;

  @Input() 
  public StructureViewerForType !: string;

  @ContentChild(FilterSetComponent)
  public FilterSet?: FilterSetComponent;

  @Input()
  public UseQueryParamStates : boolean = false;
  @Input()
  public QueryParamPrefix : string = "";
  @Input()
  public DelayedSearchFields : string[] = [];


  public Filter = {
    PageNumber: 1,
    PageSize: 15,
    SearchTerm: "",
    Subset: "",
    Filters: [] as Array<Datalistrequestfilter>,
    OrderByProperty: "",
    Ascending: false
  };

  public Data: any = { PageNumber: 1, PageSize: 0, Results: null };
  public MapPolygons: any = [];
  public MapPoints: any[] = [];

  public Pages: Array<Array<number>> = [];
  public PageCount: number = 0;
  public MapOptions: google.maps.MapOptions = {
    center: { lat: 40, lng: -20 },
    zoom: 4,
  };
  public SelectedRow: any = null;
  public AddHasOptions: boolean = false;
  public showAddDropdown: boolean = false;
  @Output()
  public ItemSelected = new EventEmitter<any>();
  @Output()
  public RowSelected = new EventEmitter<any>();
  private localStorageKey = "Data_View_Filter";
  
  constructor(
    private apiService: ApiServiceBase, private router: Router, public sanitizer: DomSanitizer, private modalService: SimpleModalService,
    private route: ActivatedRoute, private localStorageService: LocalStorageService, 
    public alertsService: AlertsService, public cdr : ChangeDetectorRef) {
    super();
    this.localStorageKey = this.router.url + '-' + this.localStorageKey;
  }

  ngOnInit(): void {
    if (this.PageSize != null) {
      this.Filter.PageSize = this.PageSize;
    }
  }

  public ApplySort(column: any){
    if(!column.EnableFilter){
      return;
    }

    if (this.Filter.OrderByProperty == undefined && this.FilterSet != undefined) {
      this.Filter = this.FilterSet.getFilterValues();
    }

    if(this.Filter.OrderByProperty != null && column.FilterColumn == this.Filter.OrderByProperty){

      if(!this.Filter.Ascending){
        this.Filter.Ascending = true;
      }
      else{
        this.Filter.Ascending = false;
      }
    }
    else{
      this.Filter.OrderByProperty = column.FilterColumn;
      this.Filter.OrderByProperty = column.FilterColumn;
      this.Filter.Ascending = false;
      column.FilterValue = "Descending";
    }
    this.Search();
  }

public IsFilterOn(column : any, value: boolean) : boolean {
  if(this.Filter != null){
    if(this.Filter.OrderByProperty != ""){
      if(this.Filter.Ascending == value && this.Filter.OrderByProperty == column.FilterColumn){
        return true;
      }
      return false;
    }
  }
  return false;
}

  public formatDate(date: Date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }

  public padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }


  ngAfterContentInit(): void {
    if (this.FilterSet != undefined) {
      if(this.UseQueryParamStates){
        var statePageNumber = Number(this.GetParamValue("pageNumber"));
      
        if (statePageNumber != null && statePageNumber > 0) {
          this.FilterSet.Filter.PageNumber = statePageNumber;
        }        
        
        var statePageSize = Number(this.GetParamValue("pageSize"));
        if(statePageSize != null && statePageSize > 0) {
          this.PageSize = statePageSize;
        }

        var selected = this.GetParamValue("selected");
        if(selected != null) {
          this.RowClicked({Id: selected});
        }
      }

      this.FilterSet.Filter.PageSize = this.PageSize ?? 15;
      
      this.FilterSet.applySearch.subscribe((filterValues) => {
        this.Filter = filterValues;
        this.Filter.PageNumber = 1;

        if(this.UseQueryParamStates){
          
          var params =  { ...this.GetParams("pageSize") };
          if(this.Filter.SearchTerm != "" && this.Filter.SearchTerm != null) {
            params[this.QueryParamPrefix + "searchTerm"] = this.Filter.SearchTerm;
          }

          var dateA = true;
          this.Filter.Filters.forEach((filter: Datalistrequestfilter) => {
            if(filter.FilterName == "dates") {
              params[this.QueryParamPrefix + filter.FilterName + (dateA ? "s" : "e")] = encodeURIComponent(filter.Property + "," + filter.Comparator + "," + filter.Value);
              dateA = !dateA;
            } else {
              params[this.QueryParamPrefix + filter.FilterName] = encodeURIComponent(filter.Property + "," + filter.Comparator + "," + filter.Value);
            }
          });
  
          this.router.navigate([], {queryParams: params});
        }

        this.Refresh();
      });
    }


    if (this.PreLoad) {
      this.Loading();
      if (this.FilterSet != undefined) {
        this.Filter = this.FilterSet.getFilterValues(this.UseQueryParamStates, { ...this.route.snapshot.queryParams }, this.QueryParamPrefix, this.DelayedSearchFields);
      }
      this.Refresh();
    }
  }

  ngAfterViewInit(): void {
    if(this.StructureViewer){
      this.StructureViewer.SelectedValueChanged.subscribe(Id => {
        this.SelectedItem(Id);
      })
    }
  }

  public GetActionsForItem(row: any): any[] {
    const response: any[] = [];
    if (row.Actions != null) {
        row.Actions.forEach((action: any) => {
            response.push({ text: action.Name, action: (addon: any) => this.HandleDropdownAction.emit({AllowedAction: action, Addon: addon}) });
        });
    }
    return response;
  }
  
  public async Search() {
    this.Filter.PageNumber = 1;
    this.UpdateQueryParam("pageNumber", 1);
    this.UpdateQueryParam("searchTerm", this.FilterSet?.SearchTermFilter?.SearchTerm);
    this.localStorageService.Save(this.localStorageKey, this.Filter);
    this.Refresh();
  }

  public async ClearSearch() {
    this.Filter.SearchTerm = '';
    this.Filter.PageNumber = 1;
    this.Filter.Subset = '';
    if(this.UseQueryParamStates){
      this.router.navigate([], {queryParams: this.GetParams("pageSize")});
    }
    this.localStorageService.Clear(this.localStorageKey);
    this.Refresh();
  }

  public async Refresh(isPaging : boolean = false) {
    if((this.StructureViewer != null || this.StructureViewer != undefined) && !isPaging){
      this.StructureViewer.SetSearchTerm(this.Filter.SearchTerm);
      this.StructureViewer.SearchForItems();
    }
    this.localStorageService.Save(this.localStorageKey, this.Filter);
    this.Loading();
    this.apiService.Post(this.ApiUrl, this.Filter).then(result => {
      if (result != null) {
        this.Data = result;

        if (this.EditUrlPerItem) {
          this.Data.Results.forEach((element: any) => {
            element.EditUrl = this.alertsService.MapEntityRoute(element.ClassName);
          });
        }

        this.RecountPages();
        if (this.ShowMap) {
          // this.PopulateMapData();
        }
      }
      this.StopLoading();
    });
  }


  private RecountPages() {
    if (this.ShowPaging == false) {
      return; //not showing paging so don't calculate pages
    }
    //if Math.Floor(150/50) == 150/50, which is true because 3 == 3, we dont need to add another page (3 Pages of 50 results)
    //otherwise we add another page - e.g Floor(152/50) != (152/30) because 3 != 3.04, 
    //so we add another page to account for the remaining results (2) 
    //(3 Pages of 50, and 1 page of 2)
    let flooredPages = Math.floor(this.Data.TotalRecords / this.Data.PageSize);
    let totalPages = flooredPages === (this.Data.TotalRecords / this.Data.PageSize) ? flooredPages : flooredPages + 1;

    this.PageCount = totalPages;
    this.Pages = [];

    let maxDisplay = 10;
    if (totalPages <= maxDisplay) { //less than max so show all
      let p1: Array<number> = [];
      for (let i = 1; i <= totalPages; i++) {
        p1.push(i);
      }
      this.Pages.push(p1);
    }
    else {

      let midpoint = this.Data.PageNumber;
      let count = Math.floor(maxDisplay / 2) - 1;
      if (midpoint <= count) midpoint = count + 1;
      if (midpoint - count > 2) this.Pages.push([1]);
      if (midpoint + count > totalPages) midpoint = totalPages - (count - 1);
      let midArray = [];
      if (midpoint - count == 2) midArray.push(1);
      for (let i = midpoint - count; i < midpoint + count; i++) {
        midArray.push(i);
      }
      this.Pages.push(midArray);
      if (midArray[midArray.length - 1] < totalPages)
        this.Pages.push([totalPages]);
    }
    if (this.Pages.length == 0) {
      this.Pages.push([1]);
    }
  }

  public PageSizeChanged() {
    this.UpdateQueryParam("pageSize", this.FilterSet?.Filter.PageSize);
    this.Refresh(true);
  }

  public NextPage() {
    if (this.Filter.PageNumber < this.PageCount) {
      this.Filter.PageNumber += 1;
      this.UpdateQueryParam("pageNumber", this.Filter.PageNumber);
      this.Refresh(true);
    }
  }
  public PrevPage() {
    if (this.Filter.PageNumber > 1) {
      this.Filter.PageNumber -= 1;
      this.UpdateQueryParam("pageNumber", this.Filter.PageNumber);
      this.Refresh(true);
    }
  }
  public GotoPage(page: number) {
    if (page != this.Filter.PageNumber) {
      this.Filter.PageNumber = page;
      this.UpdateQueryParam("pageNumber", this.Filter.PageNumber);
      this.Refresh(true);
    }
  }

  public RowClicked(row: any) {
    this.SelectedRow = row.Id;
    this.RowSelected.emit(row);
    if (this.UseQueryParamStates) {
      this.UpdateQueryParam("selected", row.Id);
    }
    if (this.ShowMap) {
      this.StructureViewer.SelectStructureMapItem(row.Id, true);
    }
  }

  public EditItem(item: any) {
    if (this.AllowEdit == false) {
      return;
    }
    if (this.EditUrlPerItem && item.EditUrl != null) {
      let idIndexS = item.editUrl.indexOf(":");
      let idIndexE = item.EditUrl.indexOf("/", idIndexS);
      let urlPart1 = item.EditUrl.substring(0, idIndexS);
      let urlPart2 = "";
      if(idIndexE > 0) {
        urlPart2 = item.EditUrl.substring(idIndexE);
      }
      this.router.navigate([urlPart1 + item.Id + urlPart2]);
    }
    if (this.EditUrl != null && this.EditUrl != '') {
      if (this.EditUrl.indexOf(":")) { //clean up the edit url if it has the :id in it
        let EditUrl1 = this.EditUrl.substring(0, this.EditUrl.indexOf(":"));
        let EditUrl2 = "";
        if(this.EditUrl.indexOf("/", this.EditUrl.indexOf(":"))>0){
          EditUrl2 = this.EditUrl.substring(this.EditUrl.indexOf("/", this.EditUrl.indexOf(":")));
        }
        this.router.navigate([EditUrl1 + item.Id + EditUrl2]);
      }
    }
    this.Editing.emit(item.Id);
  }
  
  public SelectItem(item: any) {
    this.ItemSelected.emit(item);
  }

  public CustomActionOnAction(item: any) {
    if (this.CustomAction != null) {
      this.CustomAction(item);
    }
    this.CustomActionEvent.emit(item);
  }

  public ShowCustomAction(item: any): boolean {
    if(this.AllowCustomAction){
      if(this.CustomActionConditionalField != "" && this.CustomActionConditionalValue != "") {
        switch(this.CustomActionConditionalComparator.toLowerCase()){
          case "==":
            return item[this.CustomActionConditionalField] == this.CustomActionConditionalValue;
          case "!=":
            return item[this.CustomActionConditionalField] != this.CustomActionConditionalValue;
          case ">=":
            return item[this.CustomActionConditionalField] >= this.CustomActionConditionalValue;
          case ">":
            return item[this.CustomActionConditionalField] > this.CustomActionConditionalValue;
          case "<":
            return item[this.CustomActionConditionalField] < this.CustomActionConditionalValue;
          case "<=":
            return item[this.CustomActionConditionalField] <= this.CustomActionConditionalValue;
          case "contains":
            if(item[this.CustomActionConditionalField] == null) {
              return false;
            }
            return item[this.CustomActionConditionalField].toLowerCase().includes(this.CustomActionConditionalValue.toLowerCase());
          case "not contains":
          case "!contains":
            if(item[this.CustomActionConditionalField] == null) {
              return true;
            }
            return !item[this.CustomActionConditionalField].toLowerCase().includes(this.CustomActionConditionalValue.toLowerCase());
          default:
            return false;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  public DropdownActionCalled(item: any, index: number){
    if (this.DropdownActions != null && this.DropdownActions.length > 0){
      var act = this.DropdownActions[index];
      act.action(item);
    }
  }

  public DropdownActionOnItemCalled(item: any, action : any){
    action.action(item, action);
  }

  public ShowAction(item: any, index: number): boolean {
    if (this.DropdownActions != null && this.DropdownActions.length > 0) {
      var act = this.DropdownActions[index];
      if (act.shouldshow == null) {
        return true;
      } else {
        return act.shouldshow(item);
      }
    }
    return false;
  }

  public DeleteOnAction(item: any) {
    if (this.DeleteAction == null) {
      return;
    }
    this.DeleteAction(item);
  }

  public ActionsOnRow(row: any): boolean {
    if(this.UseActionsOnEachItem){
      if(row.Actions != null && row.Actions.length > 0){
        return true;
      }
      return false;
    }
    return true;
  }

  private HandleApiError(err: any): ObservableInput<any> {
    return throwError(() => err);
  }

  public RowSelectChanged(evt: any, row : any){
    row.Selected = evt.target.checked;
    this.SelectBoxChanged.emit(row);
  }

  public IsInSelectedItems(row : any){
    if(this.SelectedItems != (null || undefined)){
      var item = this.SelectedItems.filter((x: any) => x.Id == row["Id"]);
      if(item.length > 0){
        return true;
      }
    }
    return false;
  }

  public SelectedItem(Id: any){
    var row = this.Data.Results.filter((x: any) => x.Id == Id);
    if(row && row.length > 0){
      this.SelectedRow = row[0];
      this.cdr.detectChanges();
    }
  }

  public OpenModal(item: any): void {
    console.log('Modal opened!' + item);
  }

  public getClass(row: any): string {

    if (this.SelectedRow == row.Id) {
      return '';
    }
    if (this.HighLightRowColumn == '' || this.HighLightCondition.length == 0) {
      return ''
    }

    for (let i = 0; i <= this.HighLightCondition.length; i++) {
      if (row[this.HighLightRowColumn] == this.HighLightCondition[i]) {
        return 'row-highlight';
      }
    }

    return '';
  }

  private UpdateQueryParam(param: string, value: any) {
    if(this.UseQueryParamStates) {
      param = this.QueryParamPrefix + param;
      var newQueryParams = { ...this.route.snapshot.queryParams };
      newQueryParams[param] = encodeURIComponent(value);

      this.router.navigate([], {queryParams: newQueryParams});
    }
  }

  private GetParams(keys: string): { [key: string]: any } {
    var allkeys = keys.split(",");
    var dic: { [key: string]: any } = {};
    allkeys.forEach((key) => {
      var val = this.GetParamValue(key.trim());
      if(val != null) dic[this.QueryParamPrefix + key.trim()] = val;
    });

    return dic;
  }

  private GetParamValue(keyVal: string) : string | null {
    var param = this.route.snapshot.queryParamMap.get(this.QueryParamPrefix + keyVal);
    return param;
  }
}
