import { AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MapInfoWindow, MapMarker, MapPolygon } from '@angular/google-maps';
import { LotmultiviewerComponent } from 'src/app/components/lotmultiviewer/lotmultiviewer.component';
import { ApiService } from 'src/app/Services/api.service';
import { FeaturesService } from 'src/app/Services/features.service';
import { OccupancyService } from 'src/app/Services/occupancy.service';
import { Busyable } from 'src/app/shared/editors/busyable';

@Component({
  selector: 'app-parkinghome',
  templateUrl: './parkinghome.component.html',
  styleUrls: ['./parkinghome.component.scss']
})


export class ParkinghomeComponent extends Busyable implements OnInit, AfterViewInit {

  public alerts: any = [];
  public Lots: any = [];

  public Summary : any | null = null;

  public TotalSpaces: number = 0;
  public OccupiedSpaces: number = 0;
  public Percent: number = 0;
  public rightColumnheight: number = 0;

  @ViewChild("leftColumn")
  public leftColumn!: ElementRef;

  public occupancyService: OccupancyService;
  public apiService: ApiService;

  OccupancySubscription: any;
  public cdr: ChangeDetectorRef;
  public showAlerts: boolean = false;


  constructor(private featuresService: FeaturesService, occupancyService: OccupancyService, cdr: ChangeDetectorRef, apiservice: ApiService) { 
    super();
    console.log("ParkingHome Constructor");
    this.occupancyService = occupancyService;
    this.cdr = cdr;
    this.apiService = apiservice;

    this.OccupancySubscription = occupancyService.OccupancyChanged.subscribe((val: any) => {
      this.RefreshOccupancy();
    });

  }

  ngOnInit(): void {
    this.Loading();
    this.RefreshOccupancy();

    this.OccupancySubscription = this.occupancyService.OccupancyChanged.subscribe(result =>{
      this.showAlerts = true;
      for(let lot of this.Lots){
        this.occupancyService.GetOccupancy(lot.Id).then((x: any) => {
          lot.TotalSpaces = x.TotalSpaces;
          lot.OccupiedSpaces = (x.TotalSpaces as number) - (x.AvailableSpaces as number);
        });
        this.UpdateColumnHeight();
      }
    });

    // this.alerts.push({icon: "car", iconcolor: "red", text: "You are in trouble"});
    // this.alerts.push({icon: "thumbs-down", iconcolor: "red", text: "Oh no, something broke"});
    // this.alerts.push({icon: "gear", iconcolor: "orange", text: "We are having problems"});
    // this.alerts.push({icon: "wifi", iconcolor: "red", text: "Connection issues"});
    // this.alerts.push({icon: "users", iconcolor: "dark-yellow", text: "Users have broken something"});
  }

  private RefreshOccupancy(){
    this.occupancyService.GetOccupancy(null).then((occupancy: any) => {
      if(occupancy != null){
        this.TotalSpaces = occupancy.TotalSpaces;
        this.OccupiedSpaces = (occupancy.TotalSpaces as number) - (occupancy.AvailableSpaces as number);
        this.Percent = (this.OccupiedSpaces*100 / this.TotalSpaces*100)/100;
        this.Percent = parseFloat(this.Percent.toFixed(0));
        this.UpdateColumnHeight();
      }
      this.StopLoading();
      this.showAlerts = true;
    });
  }

  public UpdateColumnHeight(){
    if (this.leftColumn) {
      this.rightColumnheight = this.leftColumn.nativeElement.offsetHeight;
    }
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.apiService.Get<any>("infrastructure/parkinglots/geometry").then((results: any) => {
      for(let result of results){
          this.Lots.push(result);
          this.UpdateColumnHeight();
      }
    });
  }
}
