import { Component, Injector, OnInit } from '@angular/core';
import { ModalService } from 'src/app/Services/modal.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute } from '@angular/router';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { LoginService } from 'src/app/auth/login.service';
import { ToastrService } from 'ngx-toastr';
import { FormArray, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { EdituserroleComponent } from '../modals/edituserrole/edituserrole.component';
import { EdituseradminpermissionComponent } from '../modals/edituseradminpermission/edituseradminpermission.component';
import { FeaturesService } from 'src/app/Services/features.service';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { UsermagicmoneyComponent } from '../modals/usermagicmoney/usermagicmoney.component';
import { Element } from '@angular/compiler';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { EdituserpermissionComponent } from '../modals/edituserpermission/edituserpermission.component';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.scss']
})
export class EdituserComponent extends ModelEditor implements OnInit {
  public features: any[] = [];
  public allfeatures: any[] = [];
  public boolExpandAll: boolean = false;
  public expandSelected: boolean = false;
  public allroles: any[] = [];
  public grantedroles: any[] = [];
  public endUserRoles: any[] = [];
  public adminRoles: any[] = [];
  public grantedRolePermissions: any[] = [];
  public endUserfeatures: any[] = [];
  public SelectedFeatureName = "";
  public SelectedFeatureDefinition = "";
  public SelectedFeatureRoutes: any[] = [];
  public SelectedFeaturePrerequisites: any[] = [];
  constructor(private modalService: SimpleModalService, private apiService: ApiServiceBase, public featuresService: FeaturesService, private permissionService: PermissionsService, private injector: Injector) {
    super("organization/users", injector);
  }

  public ClickItem(item: any) {
    this.SelectedFeatureDefinition = item.Description;
    this.SelectedFeatureName = item.Name;
    this.SelectedFeatureRoutes = item.AdminMenuItems;
    this.SelectedFeaturePrerequisites = item.PrerequisiteFeatures;
  }

  public override DefaultModel(): any {
    return {
      FirstName: "",
      LastName: "",
      Email: "",
      AccountBalance: 0,
      MagicMoneyAccountBalance: 0,
      IsAdmin: false,
      AdminPermissions: [],
      Features: [],
      EndUserPermissions: []
    };
  }

  public ProcessExplicitFeatures() {
    this.endUserfeatures = [];
    this.features.forEach((element: any) => {
      this.ProcessExplicitFeaturesFeatureLevel(element);
    });
  }

  public ProcessExplicitFeaturesFeatureLevel(element: any) {
    if (element.GrantableEndUserPermissionLevels) {
      this.endUserfeatures.push({ "ClassName": element.ClassName, "Name": element.Name, "IsSelected": this.IsEndUserPermissionSelected(element), "SelectItems": element.GrantableEndUserPermissionLevels, "SelectItemValue": this.GrantedEndUserPermissionLevel(element), "HasCheckbox": true })
    }
    element.Parent = null;
    element.NodeDisabled = !this.permissionService.CheckAdminPermission(element.ClassName, 100);
    element.Expanded = this.boolExpandAll || (this.expandSelected && element.HasChildItemsSelected);;
    element.IsSelected = this.IsAdminPermissionSelected(element);
    element.SelectItems = element.GrantableAdminPermissionLevels;
    element.SelectItemValue = this.GrantedAdminPermissionLevel(element);
    element.HasCheckbox = true;
    element.Children?.forEach((child: any) => {
      this.ProcessExplicitFeaturesFeatureLevel(child);
    });
    element.HasChildItemsSelected = element.Children.filter((x: any) => x.IsSelected == true || x.Children.filter((y: any) => y.IsSelected).length > 0).length > 0;
    return element;
  }

  public IsNodeDisabled(item: any): Promise<boolean> {
    return this.featuresService.FeatureEnabled(item.ClassName);
  }

  public ExpandAll() {
    this.boolExpandAll = true;
    this.ProcessAllFeatures();
    this.ProcessAllRoleFeatures();
    this.ProcessExplicitFeatures();
  }

  public CollapseAll() {
    this.boolExpandAll = false;
    this.expandSelected = false;
    this.ProcessAllFeatures();
    this.ProcessAllRoleFeatures();
    this.ProcessExplicitFeatures();
  }

  public ExpandSelected() {
    this.boolExpandAll = false;
    this.expandSelected = true;
    this.ProcessAllFeatures();
    this.ProcessAllRoleFeatures();
    this.ProcessExplicitFeatures();
  }

  public ProcessAllFeatures() {
    this.allfeatures.forEach((element: any) => {
      this.ProcessAllFeaturesFeatureLevel(element);
    });
  }

  public ProcessAllFeaturesFeatureLevel(element: any) {
    element.IsSelected = this.IsPermissionGranted(element);
    if (element.IsSelected) {
      element.SelectedBy = this.PermissionGrantedBy(element);
    }
    element.Parent = null;
    element.Expanded = this.boolExpandAll || (this.expandSelected && element.HasChildItemsSelected);;
    element.SelectItems = element.GrantableAdminPermissionLevels;
    element.SelectItemValue = this.PermissionGrantedLevel(element);
    element.HasCheckbox = true;
    element.Children?.forEach((child: any) => {
      this.ProcessAllFeaturesFeatureLevel(child);
    });
    element.HasChildItemsSelected = element.Children.filter((x: any) => x.IsSelected == true || x.Children.filter((y: any) => y.IsSelected).length > 0).length > 0;

    return element;
  }

  public ProcessAllRoleFeatures() {
    this.grantedRolePermissions = [];
    this.endUserRoles = [];
    this.adminRoles = [];
    this.allroles.forEach((element: any) => {
      element.IsSelected = this.IsRoleGranted(element);
      element.HasCheckbox = true;
      element.NodeDisabled = !this.permissionService.CheckRole(element.Name);
      if(element.IsAdminRole) this.adminRoles.push(element);
      else this.endUserRoles.push(element);
      if (element.IsSelected) {
        this.grantedroles.push(element);
        element.RolePermissions.forEach((x: any) => {
          x.Role = element.Name;
          this.grantedRolePermissions.push(x);
        })
      }
    })
  }

  public override AfterModelLoaded(): void {
    this.Loading();
    this.apiService.Get<any>("features/installed/all").then(result => {
      this.features = result;
      var t = JSON.stringify(this.features);
      this.allfeatures = JSON.parse(t);
      this.ProcessExplicitFeatures();
    });

    this.apiService.Get<any>("organization/roles").then(result => {
      this.allroles = result;
      this.ProcessAllRoleFeatures();
      this.ProcessAllFeatures();
    });
    this.StopLoading();
  }

  public IsRoleGranted(item: any): boolean {
    var grantedRoles = this.Form.get("Roles")?.value;
    if (grantedRoles == null) {
      return false;
    }
    var t = grantedRoles.filter((x: any) => x.RoleName != null && x.RoleName == item.Name);
    if (t.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  public UpdateRoleSelectedItem(item: any) {
    var x = this.IsRoleGranted(item);
    if (x) {
      //item exists in list, so remove.
      item.IsSelected = false;
      var g = this.Form.get("Roles")?.value;
      var index = g.findIndex((x: any) => x.RoleName == item.Name);
      this.FormArray(this.Form, "Roles").removeAt(index);
    }
    else {
      //item does not exist in list, so add.
      var Role = {
        RoleName: item.Name,
        RoleId: item.Id,
      }
      item.IsSelected = true;
      this.AddToFormArray(this.FormArray(this.Form, "Roles"), Role, "Roles");
    }
    this.Form.markAsDirty();
    this.ProcessAllRoleFeatures();
    this.ProcessAllFeatures();
  };

  public override BeforeSave(): void {
  }

  public IsAdminPermissionSelected(item: any): boolean {
    var grantedPermissions = this.Form.get("AdminPermissions")?.value;
    if (grantedPermissions == null) {
      return false;
    }
    var t = grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName);
    if (t.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  public IsPermissionGranted(item: any): boolean {
    var grantedPermissions = this.Form.get("AdminPermissions")?.value;
    var grantedRolePermissions = this.grantedRolePermissions;

    if (grantedPermissions == null && grantedRolePermissions == null) {
      return false;
    }
    if (grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName).length > 0) {
      return true;
    }
    else if (grantedRolePermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName).length > 0) {
      return true;
    }
    return false;
  }

  public PermissionGrantedBy(item: any): string {
    var grantedPermissions = this.Form.get("AdminPermissions")?.value;
    var grantedRolePermissions = this.grantedRolePermissions;
    var string = ""

    if (grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName).length > 0) {
      string = " (Granted Permissions"
    }

    if (grantedRolePermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName).length > 0) {
      var rolepermissions = grantedRolePermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName);
      rolepermissions.forEach((x: any) => {
        if (string != "") {
          string = string + " | " + x.Role
        }
        else {
          string = " (" + x.Role
        }
      })
    }
    if (string != "") {
      string = string + ")"
    }
    return string;
  }

  public PermissionGrantedLevel(item: any): any {
    var grantedPermissions = this.Form.get("AdminPermissions")?.value;
    var grantedRolePermissions = this.grantedRolePermissions;
    var permissionlevel = 0;

    if (grantedPermissions == null && grantedRolePermissions == null) {
      return permissionlevel;
    }
    if (grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName).length > 0) {
      permissionlevel = grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName)[0].PermissionLevel;
    }

    if (grantedRolePermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName).length > 0) {
      var secondaryPermission = grantedRolePermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName)[0].PermissionLevel;
      permissionlevel = secondaryPermission > permissionlevel ? secondaryPermission : permissionlevel;
    }

    return permissionlevel;
  }

  public IsEndUserPermissionSelected(item: any): boolean {
    var grantedPermissions = this.Form.get("EndUserPermissions")?.value;
    if (grantedPermissions == null) {
      return false;
    }
    var t = grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName);
    if (t.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  public GrantedAdminPermissionLevel(item: any): number {
    var grantedPermissions = this.Form.get("AdminPermissions")?.value;
    if (grantedPermissions == null) {
      return 0;
    }
    var t = grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName);
    if (t.length > 0) {
      return t[0].PermissionLevel;
    }
    else {
      return 0;
    }
  }

  public GrantedEndUserPermissionLevel(item: any): number {
    var grantedPermissions = this.Form.get("EndUserPermissions")?.value;
    if (grantedPermissions == null) {
      return 0;
    }
    var t = grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName);
    if (t.length > 0) {
      return t[0].PermissionLevel;
    }
    else {
      return 0;
    }
  }

  public UpdateExplicitAdminSelectedItem(item: any) {
    var x = this.IsAdminPermissionSelected(item);
      if (x) 
      {
        item.IsSelected = false;
        //item exists in list, so remove.
        var g = this.Form.get("AdminPermissions")?.value;
        var index = g.findIndex((x: any) => x.Permission == item.ClassName);
        this.FormArray(this.Form, "AdminPermissions").removeAt(index);

        //remove parents
        this.RemoveParentAdminPermission(item);
      }
      else {
        //item does not exist in list, so add.
          var AdminPermission = {
            Permission: item.ClassName,
            PermissionLevel: item.GrantableAdminPermissionLevels == null || item.GrantableAdminPermissionLevels.length == 0
              ? 0 : item.GrantableAdminPermissionLevels[0].Level,
          }
          item.IsSelected = true;
          this.AddToFormArray(this.FormArray(this.Form, "AdminPermissions"), AdminPermission, "AdminPermissions");

          //add parents to list
          this.AddParentAdminPermission(item);
          //Add Explicit Permission Prerequisites
          this.AddPrerequisiteAdminPermissions(item);
      }    
      this.Form.markAsDirty();
      this.ProcessExplicitFeatures();
      this.ProcessAllFeatures();
  };

  public AddParentAdminPermission(item: any) {
    if(item.Parent != null)
      {
        var parent = item.Parent;
        var isParentSelected = this.IsAdminPermissionSelected(parent);
        
        if(!isParentSelected){
        var RolePermission = {
          Permission: parent.ClassName,
          PermissionLevel: parent.GrantableAdminPermissionLevels == null || parent.GrantableAdminPermissionLevels.length == 0
          ? 0 : parent.GrantableAdminPermissionLevels[0].Level,
        }
        
        parent.IsSelected = true;
        this.AddToFormArray(this.FormArray(this.Form, "AdminPermissions"), RolePermission, "AdminPermissions");
      }
        this.AddParentAdminPermission(parent);
        this.AddPrerequisiteAdminPermissions(parent);
      }
  }

  public RemoveParentAdminPermission(item: any) {
    if(item.Parent != null)
    {
      var parent = item.Parent;
      var isParentSelected = this.IsAdminPermissionSelected(parent);

      if (isParentSelected) {
        parent.HasChildItemsSelected = parent.Children.filter((x: any) => x.IsSelected == true).length > 0;
        parent.IsSelected = parent.HasChildItemsSelected;

        if(!parent.IsSelected){
          var g = this.Form.get("AdminPermissions")?.value;
          var index = g.findIndex((x: any) => x.Permission == item.ClassName);
          this.FormArray(this.Form, "AdminPermissions").removeAt(index);
          this.RemoveParentAdminPermission(parent);
        }
      }
    }
  }

  public AddPrerequisiteAdminPermissions(item: any){
    if(item.PrerequisiteFeatures != null && item.PrerequisiteFeatures.length > 0){
      item.PrerequisiteFeatures.forEach((prereq : any) => {
        //add and select prereq
        prereq.ClassName = prereq.Name;
        var isPrereqSelected = this.IsAdminPermissionSelected(prereq);
        
        if(!isPrereqSelected)
        {
          var allFeatures = this.featuresService.GetFeatures();
          var pre = allFeatures.find((x: any) => x.ClassName == prereq.Name);
          var RolePermission = {
            Permission: pre.ClassName,
            PermissionLevel: pre.GrantableAdminPermissionLevels == null || pre.GrantableAdminPermissionLevels.length == 0
            ? 0 : pre.GrantableAdminPermissionLevels[0].Level,
          }

          prereq.IsSelected = true;
          this.AddToFormArray(this.FormArray(this.Form, "AdminPermissions"), RolePermission, "AdminPermissions");
          this.AddParentAdminPermission(pre);
          this.AddPrerequisiteAdminPermissions(pre);
          };
        });
      }
  }

  public UpdateEndUserSelectedItem(item: any) {
    var x = this.IsEndUserPermissionSelected(item);
    if (x) {
      item.IsSelected = false
      //item exists in list, so remove.
      var g = this.Form.get("EndUserPermissions")?.value;
      var index = g.findIndex((x: any) => x.Permission == item.ClassName);
      this.FormArray(this.Form, "EndUserPermissions").removeAt(index);
    }
    else {
      //item does not exist in list, so add.
      var endUserPermission = {
        Permission: item.ClassName,
        PermissionLevel: 0,
      }

      item.IsSelected = true;
      this.AddToFormArray(this.FormArray(this.Form, "EndUserPermissions"), endUserPermission, "EndUserPermissions");
    }
    this.Form.markAsDirty();
  };



  public UpdateAdminItemSelectedValue(item: any) {
    //item exists in list, so remove.
    var g = this.Form.get("AdminPermissions")?.value;
    var indexItem = g.find((x: any) => x.Permission == item.ClassName);
    var index = g.findIndex((x: any) => x.Permission == item.ClassName)
    this.FormArray(this.Form, "AdminPermissions").removeAt(index);
    indexItem.PermissionLevel = item.SelectItemValue;

    var AdminPermission = {
      Permission: indexItem.Permission,
      PermissionLevel: indexItem.PermissionLevel,
    }
    this.AddToFormArray(this.FormArray(this.Form, "AdminPermissions"), AdminPermission, "AdminPermissions");
    this.Form.markAsDirty();
    this.ProcessAllFeatures();
    this.ProcessExplicitFeatures();
  };


  public UpdateEndUserItemSelectedValue(item: any) {
    //item exists in list, so remove.
    var g = this.Form.get("EndUserPermissions")?.value;
    var indexItem = g.find((x: any) => x.Permission == item.ClassName);
    var index = g.findIndex((x: any) => x.Permission == item.ClassName)
    this.FormArray(this.Form, "EndUserPermissions").removeAt(index);
    indexItem.PermissionLevel = item.SelectItemValue;

    var EndUserPermission = {
      Permission: indexItem.Permission,
      PermissionLevel: indexItem.PermissionLevel,
    }
    this.AddToFormArray(this.FormArray(this.Form, "EndUserPermissions"), EndUserPermission, "EndUserPermissions");
    this.Form.markAsDirty();
  };

  public override FormLabels() {
    return {
      FirstName: "First name",
      LastName: "Last name",
    }
  }

  public override Validators(): any {
    return {
      "FirstName": [Validators.required, Validators.minLength(1)],
      "LastName": [Validators.required, Validators.minLength(1)],
      "Email": [Validators.required, Validators.email],
    }
  }

  public AddMagicMoney() {
    this.modalService.addModal(UsermagicmoneyComponent, { User: { OrgUserId: this.Model['Id'] } }, { closeOnEscape: true, closeOnClickOutside: true })
      .subscribe(result => {
        if (result != null) {
          var newMagicMoneyBalance = this.Form.get("MagicMoneyAccountBalance")?.value + result.MagicMoney;
          this.Form.patchValue({ "MagicMoneyAccountBalance": newMagicMoneyBalance })
          this.Form.markAsPristine();
        }
      })
  }

  public AddUserAdminPermission() {
    this.modalService.addModal(EdituseradminpermissionComponent, { Model: { ClassName: "AdminPermissions" } })
      .subscribe((result) => {
        if (result != null) {
          this.FormArray(this.Form, "AdminPermissions").controls.push(result);
        }
      });
  }

  public EditUserAdminPermissionMethod(method: any) {
    let editingMethod = method;
    this.modalService.addModal(EdituseradminpermissionComponent, { Form: method })
      .subscribe((result) => {
        if (result != null) {
          //editingMethod.assign(result);
        }
      });
  }

  public AddUserPermission() {
    this.modalService.addModal(EdituserpermissionComponent, { Model: { ClassName: "EndUserPermissions" } })
      .subscribe((result) => {
        if (result != null) {
          this.FormArray(this.Form, "EndUserPermissions").controls.push(result);
        }
      });
  }

  public EditUserPermissionMethod(method: any) {
    let editingMethod = method;
    this.modalService.addModal(EdituserpermissionComponent, { Form: method })
      .subscribe((result) => {
        if (result != null) {
          //editingMethod.assign(result);
        }
      });
  }
  public RemoveUserPermission(index: number) {
    this.Model.EndUserPermissions.splice(index, 1);
  }

  public SetIsAdmin(evt: any) {
    this.Model.IsAdmin = evt.target.checked;
  }

  public RemoveUserAdminPermission(index: number) {
    this.Model.AdminPermissions.splice(index, 1);
  }
}
