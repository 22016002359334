import { AfterContentInit, AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { Busyable } from 'src/app/shared/editors/busyable';
import { LevelObjectMesh } from 'src/app/components/levelmapviewer/geometry/level-object-mesh';
import { SpaceMesh } from 'src/app/components/levelmapviewer/geometry/space-mesh';
import { LevelviewerComponent } from 'src/app/components/levelviewer/levelviewer.component';
import { ModelEditor } from 'src/app/shared/editors/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { FeaturesService } from 'src/app/Services/features.service';
import { GuidanceFeedService } from 'src/app/Services/guidancefeed.service';
import { LocalControllerFeedService } from 'src/app/Services/localcontrollerfeed.service';
import { OperatorConsoleService } from 'src/app/Services/operatorconsole.service';
import { TabComponent } from 'src/app/shared/forms/tabs/tab/tab.component';
import { TabsComponent } from 'src/app/shared/forms/tabs/tabs.component';
import { EditParkinglevelCountsComponent } from '../parking/parking/parkinglevels/modals/parkinglevelcounts/editparkinglevelcounts.component';
import { Color } from 'src/app/util/color';
import moment from 'moment';
import { OccupancyService } from 'src/app/Services/occupancy.service';

@Component({
  selector: 'app-operatorconsole',
  templateUrl: './operatorconsole.component.html',
  styleUrls: ['./operatorconsole.component.scss']
})
export class OperatorConsoleComponent extends Busyable implements OnInit, AfterContentInit, OnDestroy {
  constructor(private apiService: ApiServiceBase, private featuresService: FeaturesService, private occupancyService: OccupancyService, private operatorConsoleService: OperatorConsoleService, private modalService : SimpleModalService, private toast: ToastrService, private localcontrollerfeedservice : LocalControllerFeedService, private route: Router) { 
    super();
    this.Busyable.Loading();
  }
  
  @ViewChild("levelviewer")
  public levelviewer!: ElementRef;
  @ViewChild("levelviewercomponent")
  public levelviewercomponent!: LevelviewerComponent;

  @ViewChild("console")
  public consoleElement!: ElementRef;
  @ViewChildren("heatmap")
  public HeatmapCanvas !: ElementRef<HTMLCanvasElement>;

  @ViewChild('lotTitle')
  public lotTitle !: ElementRef;

  public ParkingLots : any | undefined;
  public SelectedLot : any | undefined;
  public SelectedLevel : any | undefined;
  public LevelDevices : any[] = [];
  public OccupiedSpaces?: number;
  public deviceTree : any | undefined;
  public SelectedDevice : any | undefined;
  public Busyable: Busyable = new Busyable();
  public ConsoleLines : string[] =[];
  public ShowAlertsDropdown: boolean = false;
  public SoundEnabled : boolean = false;
  public boxVisible: boolean = false;

  private SpaceId : any;
  private LocalControllerFeedSubscription: any;
  
  private OccupancySubscription : any;
  private OperatorConsoleSubscription : any;

  @ViewChild("tabs")
  public TabsControl !: TabsComponent
  @ViewChild("treetab")
  public TreeTab !: TabComponent
  @ViewChild("propertytab")
  public PropertyTab !: TabComponent

  ngOnInit(): void {
    this.Loading();
    this.apiService.Get<any>("infrastructure/parkinglots/withlevels").then(result => {
      console.log("Parking lots recieved");
      this.ParkingLots = result.filter((x : any) => x.Levels != null && x.Levels.length != 0);
      let routeLot = this.route.url.split("/").pop() == "operatorconsole" ? this.ParkingLots[0].Id : this.route.url.split("/").pop() ?? this.ParkingLots[0].Id;
      this.SelectedLevel = this.ParkingLots[0].Levels[0];
      this.SelectParkingLot(routeLot);
    }); 

   
  }

  onSelectChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    switch (selectedValue) {
      case 'edit':
        this.EditParkingLevelCounts();
        break;
      case 'close':
        this.CloseLevel(true);
        break;
      case 'open':
        this.CloseLevel(false);
        break;
      default:
        break;
    }
  }
  

  public ToggleBox(){
    this.boxVisible = !this.boxVisible;
  }

  public WriteToConsole(val :string){
    this.ConsoleLines.push(val);
    while(this.ConsoleLines.length > 9)
      this.ConsoleLines.splice(0,1);
  }

  public SortLevelData(){
    console.log("Sorting Level Data");
    this.SelectedLevel.Rows = this.SelectedLevel.Rows.sort(function(a :any,b: any){
      return a.Name.localeCompare(b.Name);
    })

    this.SelectedLevel.Rows.forEach((x: any) => {
      x.Spaces = x.Spaces.sort((n1:any,n2:any) => n1.Name - n2.Name);
    });

    this.LevelDevices = [];

    this.SelectedLevel.Controllers.forEach((controller:any) => {
      this.LevelDevices.push(controller);
    })
    this.SelectedLevel.Gates.forEach((gate : any) => {
      gate.Lanes.forEach((lane: any) =>{
        var DevicePath = gate.Name + " > " + lane.Name;
        lane.Barriers.forEach((barrier: any) =>{
          barrier.DevicePath = DevicePath + " > " + "Barriers" + " > " + barrier.LocalAddress + ":" + barrier.LocalPort + " | " + barrier.Type;
          this.LevelDevices.push(barrier);
        });
        lane.Cameras.forEach((camera: any) =>{
          camera.DevicePath = DevicePath + " > " + "Cameras" + " > " + camera.LocalAddress + ':' + camera.LocalPort;
          this.LevelDevices.push(camera);
        });
        lane.CellRouters.forEach((cell: any) =>{
          cell.DevicePath = DevicePath + " > " + "Cell Routers" + " > " + cell.Name;
          this.LevelDevices.push(cell);
        });
        lane.Displays.forEach((display: any) =>{
          display.DevicePath = DevicePath + " > " + "Displays" + " > " + display.LocalAddress + ":" + display.LocalPort;
          this.LevelDevices.push(display);
        });
        lane.PaymentTerminals.forEach((pterm: any) =>{
          pterm.DevicePath = DevicePath + " > " + "Payment Terminals"  + " > " + pterm.LocalAddress + ":" + pterm.LocalPort ;
          this.LevelDevices.push(pterm);
        });

        this.SelectedLevel.Controllers.forEach((controller : any) => {
          if(lane.LocalControllerId == controller.Id){
            controller.DevicePath = gate.Name + " > " + lane.Name + " > " + "Local Controllers" + " > " + controller.Name;
          }
        });
      })
    });
    console.log("Sorting Level Data Complete");
  }

  private RefreshOccupancy(){
    if(this.SelectedLot == null){
      this.OccupiedSpaces = 0;
      return;
    }
    this.occupancyService.GetOccupancy(this.SelectedLot['Id']).then((occupancy: any) => {
      if(occupancy != null){
        this.SelectedLot.OccupiedSpaces = (occupancy.OccupiedSpaces as number);
        this.SelectedLot.TotalSpaces = (occupancy.TotalSpaces as number);
      }
    });
  }

  public ParkingLotChanged(evt: any){
    this.route.navigate(["/parking/operations/operatorconsole/" + evt.target.value]);
    this.SelectParkingLot(evt.target.value); 
  }

  public ParkingLevelChanged(evt: any){
    this.SelectParkingLevel(evt.target.value); 
  }

  public SelectParkingLot(lotId : string){
    console.log("Selected Lot Changing: " + lotId);
    if(this.SelectedLot != null && this.SelectedLot.Id == lotId){
      return;
    }
    this.SelectedDevice = null;
    this.Loading();
    var selectedLot = this.ParkingLots.filter((x: any) => x.Id == lotId)[0];
    var selectedLevel = selectedLot.Levels[0];
    this.WriteToConsole("Selected Parking Lot " + selectedLot.Name);
    this.apiService.Get<any>("infrastructure/parkinglots/" + selectedLot.Id + "/levels/" + selectedLevel.Id).then(level => {
      console.log("Selected Level Recieved: " + level.Id);
      this.SelectedLot = selectedLot;
      this.SelectedLevel = level;
      this.SortLevelData();
      this.RefreshOccupancy();
      this.StopLoading();
    });
  }

  public SelectParkingLevel(levelId : string){
    console.log("Selected Level Changing: " + levelId);
    if(this.SelectedLevel != null && this.SelectedLevel.Id == levelId){
      return;
    }
    this.SelectedDevice = null;
    this.Loading();
    this.SelectedLevel = this.SelectedLot.Levels.filter((x: any) => x.Id == levelId)[0];
    this.WriteToConsole("Selected Parking Level " + this.SelectedLevel.Name);
    this.apiService.Get<any>("infrastructure/parkinglots/" + this.SelectedLot.Id + "/levels/" + this.SelectedLevel.Id).then(level => {
      console.log("Selected Level Recieved: " + level.Id);
      this.SelectedLevel = level;
      this.SortLevelData();
      this.RefreshOccupancy();
      this.StopLoading();
    });
  }



  public EditParkingLevelCounts() {
    this.modalService.addModal(EditParkinglevelCountsComponent, { "ModelId": this.SelectedLot.Id })
      .subscribe((result: any) => {
        if (result != null) {
          if (result == true) {
            this.toast.success('Vehicle Present counts updated successfully', 'Saved');
          }
        }
      });
  }

  public CloseLevel(closeLevel: boolean){
    //if closed is false then the lot is open, and Open needs to be called 
    //if closed is true then the lot is closed.
      if(closeLevel){
          this.apiService.Post("infrastructure/parkinglots/" + this.SelectedLot.Id + "/levels/" + this.SelectedLevel.Id + "/close", null).then(result => {
            if (result != null) {
              this.SelectedLevel = result;
              if(this.SelectedLevel.Closed){
                this.toast.success('Parking Level closed successfully', 'CLOSED');
              }
            }
        });
      }
      else if (!closeLevel) {
        this.apiService.Post("infrastructure/parkinglots/" + this.SelectedLot.Id + "/levels/" + this.SelectedLevel.Id + "/open", null).then(result => {
          if (result != null) {
            this.SelectedLevel = result;
            if(!this.SelectedLevel.Closed){
              this.toast.success('Parking Level open successfully', 'OPEN');
            }
          }
        });
      }
  }


  public DeviceClicked(device :any){
    if(device == null){
      this.SelectedDevice = null;
    }
    else{
      console.log(device.Id);
      this.SelectedDevice = this.LevelDevices.filter(x => x.Id == device.Id)[0]; 
      this.boxVisible = false;
    }
  }


  public OpenBarrier(id : string){
    this.apiService.Post("/infrastructure/barriers/" + id + "/open", {});
  }
  public KeepBarrierOpen(id : string){
    this.apiService.Post("/infrastructure/barriers/" + id + "/keepopen", {});
  }
  public CloseBarrier(id : string){
    this.apiService.Post("/infrastructure/barriers/" + id + "/close", {});
  }

  public ChangeLaneDirection(id : string, event : any) {
    this.apiService.Post("/infrastructure/lanes/" + id + "/directions/" + event.target.value, {});
  }

  ngAfterContentInit(): void {
    console.log("Creating Occupancy Subscription");
    this.OccupancySubscription = this.occupancyService.OccupancyChanged.subscribe((val: any) => {
      this.RefreshOccupancy();
    });

    console.log("Creating Operator Console Subscription");
    this.operatorConsoleService.OperatorConsoleServiceUpdated.subscribe(val => {
      if(val != null && 
        this.levelviewercomponent != null && 
        this.levelviewercomponent.levelMesh != null && 
        this.levelviewercomponent.levelMesh.MeshesById != null){
        var itemId = val.ItemId;

        var Device = this.levelviewercomponent.levelMesh.MeshesById[itemId];
        if(Device != null){
          var MessageType = val.MessageType;
          var EnqueuedTimeUtc = val.EnqueuedTimeUtc;

          var date = new Date(EnqueuedTimeUtc);
          var formatteddatestr = moment(date).format('HH:mm');


          if(Device instanceof LevelObjectMesh){
            Device.HandleEvent(Device, MessageType, this.SoundEnabled);
          }    
          if(Device instanceof SpaceMesh){
            Device.HandleEvent(Device, MessageType);
          }    
          this.WriteToConsole(formatteddatestr + ", " + MessageType);
        }
      }
    });
  }

  ToggleSound(){
    this.SoundEnabled = !this.SoundEnabled;
  }

  ngOnDestroy(): void {
    if(this.OccupancySubscription != null){
      this.OccupancySubscription.unsubscribe();
    }
  }
}
