<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Parent]="this">
        <app-form-save-button [Parent]="this" [Form]="Form" class="button is-primary" (OnSave)="Save()">
        </app-form-save-button>
    </app-pageheader>

    <div class="columns">
        <div class="column is-6">
            <article class="panel">
                <p class="panel-heading">Guidance Light Configuration</p>
                <div class="panel-block">
                    <div class="three-quarters-width">
                        <app-field Label="Name" class="control">
                            <input formControlName="Name" class="input mb-3" type="text" />
                            <app-validator [For]="this.Form.get('Name')"></app-validator>
                        </app-field>
                        <app-field Label="Chip Id" class="control">
                            <input formControlName="ChipId" class="input mb-3" type="number" readonly/>
                            <app-validator [For]="this.Form.get('ChipId')"></app-validator>
                        </app-field>
                        <app-field Label="Mode" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="Mode">
                                    <option value="0">Polling</option>
                                    <option value="1">Device</option>
                                    <option value="2">Polling Master</option>
                                    <option value="3">Device Master</option>
                                    <option value="4">Slave</option>
                                </select>
                            </span>
                            <app-validator [For]="this.Form.get('Mode')"></app-validator>
                        </app-field>
                    </div>
                </div>
            </article>
        </div>
        <div class="column is-6">
            <article class="panel" *ngIf="Model.Device != null">
                <p class="panel-heading">Device Settings</p>
                <div class="panel-block">
                    <div formGroupName="Device" class="three-quarters-width">
                        <app-field Label="Hardware Version" class="control">
                            <input class="input mb-3" formControlName="HardwareVersionVersion" type="number" readonly/>
                        </app-field>
                        <app-field Label="Firmware Version" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select formControlName="NewFirmwareVersion">
                                    <option *ngFor="let item of FirmwareVersions" [ngValue]="item.Version">{{item.Version}}</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field Label="Extended Configuration" class="control">
                            <input formControlName="ExtendedConfiguration" class="input mb-3" type="text"/>
                            <app-validator [For]="this.Form.get('ExtendedConfiguration')"></app-validator>
                        </app-field>
                        <app-field Label="TxPower" class="control">
                            <input formControlName="TxPower" class="input mb-3" min="0" max="20" type="number"/>
                            <app-validator [For]="this.Form.get('TxPower')"></app-validator>
                        </app-field>
                    </div>
                </div>
            </article>
            <article class="panel">
                <!-- <p class="panel-heading">Display Settings</p>
                <div class="panel-block">
                    <div class="three-quarters-width">
                        <app-field Label="Display Rule Set" class="control mb-3">
                            <span class="select is-fullwidth">
                                <select>
                                    <option *ngFor="let item of DisplayRules" value="item.Id">{{item.Name}}</option>
                                </select>
                            </span>
                        </app-field>
                        <app-field Label="Brightness (Day)" class="control">
                            <input formControlName="DayBrightness" class="input mb-3" type="number" />
                            <app-validator [For]="this.Form.get('DayBrightness')"></app-validator>
                        </app-field>
                        <app-field Label="Brightness (Night)" class="control">
                            <input formControlName="NightBrightness" class="input mb-3" type="number" />
                            <app-validator [For]="this.Form.get('NightBrightness')"></app-validator>
                        </app-field>
                    </div>
                </div> -->
            </article>
        </div>
    </div>
</div>